import moment from 'dayjs';
import always from 'ramda/es/always';
import complement from 'ramda/es/complement';
import equals from 'ramda/es/equals';
import ifElse from 'ramda/es/ifElse';
import isNil from 'ramda/es/isNil';
import pick from 'ramda/es/pick';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import either from 'ramda/es/either';

export const renderWhen = (pred, fn) => ifElse(pred, fn, always(null));

export const renderWhenNotNil = fn => renderWhen(complement(isNil), fn);

export const renderWhenTrue = fn => renderWhen(equals(true), fn);

export const renderWhenTrueOtherwise = (fn, otherwise) => ifElse(equals(true), fn, otherwise);

export const styleWhenTrue =
  (fn, styleTrue, styleFalse = null) =>
    props =>
      ifElse(equals(true), always(styleTrue), always(styleFalse))(fn(props));

export const formatDate = date => moment(new Date(date).toDateString()).format('MMM DD[,] YYYY');

export const interactionProps = pick([
  'onMouseEnter',
  'onMouseLeave',
  'onTouchStart',
  'onTouchEnd',
  'onTouchCancel',
  'onClick',
  'onKeyDown',
  'onKeyPress',
]);

export const backgroundUrl = propName =>
  pipe(
    prop(propName),
    ifElse(either(equals(false), isNil), always('none'), url => `url(${url})`),
  );

export const identifiers = (componentName, contentType, entryId) => ({
  'data-component-name': componentName,
  'data-content-type': contentType,
  'data-entry-id': entryId,
  id: entryId ? `thl-${entryId}` : null,
});

const BURGER_THRESHOLD = process.env.BURGER_THRESHOLD || 1280;

export const isBelowBurgerResolution = () => {
  return window.innerWidth < BURGER_THRESHOLD;
};
