import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './mobileCarousel.styles';
import { useDragScroll } from '../useDragScroll';
import StoryBlock from '../storyBlock/storyBlock.component';

const MobileCarousel = ({ storyBlocks, storyOrder, isOnScreen }) => {
  const containerRef = useRef(null);
  const [scrolledStory, setScrolledStory] = useState(null);
  const shuffledStoryBlocks = storyOrder.map(id =>
    storyBlocks.find(storyBlock => storyBlock.id === id),
  );
  const storyCount = storyBlocks.length;

  // Set initially scrolled-into-view story
  useEffect(() => {
    setScrolledStory(storyOrder[0]);
  }, [storyCount, storyBlocks, storyOrder]);

  // Reset scrolled story to first story on container resize to prevent buggy behavior
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      setScrolledStory(storyOrder[0]);
    });
    resizeObserver.observe(containerRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, [storyOrder]);

  const handleClickDot = storyId => {
    if (scrolledStory !== storyId) {
      setScrolledStory(storyId);
    }
  };

  // Desktop and mobile carousel drag gesture logic
  const [scrollOffset, setScrollOffset] = useState(null);
  const [dragDirection, setDragDirection] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  // Add drag event listeners for mouse and touch
  useDragScroll(containerRef.current, setDragDirection, setDragging, true);

  // Update scrolled story based on drag direction
  useEffect(() => {
    if (dragDirection) {
      const increment = dragDirection === 'right' ? 1 : -1;
      const nextStory = storyOrder[storyOrder.indexOf(scrolledStory) + increment];
      if (nextStory) {
        setScrolling(true);
        setScrolledStory(nextStory);
      }
    }
    setDragDirection(null);
  }, [dragDirection]);

  const scrollCarousel = async () => {
    const carouselReady = !!storyOrder.length;
    if (containerRef.current && carouselReady) {
      containerRef.current.scrollTo({
        left: scrollOffset,
        behavior: 'smooth',
      });
      return new Promise(resolve => {
        // Establish buffer distance in which user cannot interrupt scroll between stories
        const scrollHandler = () => {
          if (
            containerRef.current &&
            Math.abs(
              Math.abs(Math.round(containerRef.current.scrollLeft)) - Math.abs(scrollOffset),
            ) <=
              window.innerWidth / 6
          ) {
            containerRef.current.removeEventListener('scroll', scrollHandler);
            resolve();
          }
        };
        if (containerRef.current) {
          containerRef.current.addEventListener('scroll', scrollHandler);
        }
      });
    }
    return null;
  };

  // Scroll to location of currently scrolled story when it updates its offset
  useEffect(() => {
    scrollCarousel().then(() => {
      setScrolling(false);
    });
  }, [scrollOffset]);

  return (
    <Fragment>
      <Styled.DotNavigation>
        {storyOrder.map((storyId, i) => (
          <Styled.DotButton
            key={storyId}
            onTouchStart={() => handleClickDot(storyId)}
            onClick={() => handleClickDot(storyId)}
            active={storyId === scrolledStory}
            isOnScreen={isOnScreen}
            dotNumber={i + 1}
            totalDots={storyCount}
            tabIndex={-1}
            aria-label={`Scroll to story ${i + 1}`}
          ></Styled.DotButton>
        ))}
      </Styled.DotNavigation>

      <Styled.ScrollingContainer
        ref={containerRef}
        dragging={dragging}
        activelyScrolling={scrolling}
      >
        {shuffledStoryBlocks.map(storyBlock => (
          <Styled.StoryWrapper key={storyBlock.id} isOnScreen={isOnScreen} dragging={dragging}>
            <StoryBlock
              scrolledStory={scrolledStory}
              setScrollOffset={setScrollOffset}
              handleKeyUp={() => handleClickDot(storyBlock.id)}
              {...storyBlock}
            />
          </Styled.StoryWrapper>
        ))}
      </Styled.ScrollingContainer>
    </Fragment>
  );
};

MobileCarousel.propTypes = {
  storyBlocks: PropTypes.arrayOf(PropTypes.object),
  storyOrder: PropTypes.arrayOf(PropTypes.string),
  isOnScreen: PropTypes.bool,
};

export default MobileCarousel;
