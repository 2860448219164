import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import { translationMessages } from '../../../../locales/i18n';

export const IntlProvider = ({ children, language, location: loc }) => {
  return (
    <ReactIntlProvider {...{ locale: language, loc }} messages={translationMessages[language]}>
      {children}
    </ReactIntlProvider>
  );
};

IntlProvider.propTypes = {
  language: PropTypes.string,
  location: PropTypes.object.isRequired,
  fetchAccessories: PropTypes.func.isRequired,
  children: PropTypes.any,
};
