import styled, { css } from 'styled-components';

import { colors, fonts } from '../../theme/styled';
import { media, sizes } from '../../theme/media';
import { StyledButton, StyledLink } from '../button/button.styles';
import { H2 } from '../../theme/layout';
import { text } from '../../theme/typography';

const heightBreakpoint = '700px';

export const CurtainArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 2rem;
  left: 50%;

  transform: translate(-50%, 50%);

  box-sizing: content-box;
  padding: 0.5rem;
  margin: 0;

  height: 3rem;
  width: 3rem;

  border: none;
  border-radius: 0px;

  font-size: 1.75rem;
  line-height: 3rem;

  text-align: center;
  text-transform: none;

  background: none;
  color: #8e9295;

  cursor: pointer;

  overflow: visible;

  appearance: none;

  transition: color 0.2s ease-in-out, font-size 0.2s ease-in-out;

  ${media.mobile`
    margin-bottom: 0.5em;
  `}

  &:hover {
    color: ${colors.neutral325};
  }

  &:active {
    color: ${colors.white};
    font-size: 1.65rem;
  }
`;

export const CurtainContainer = styled.div`
  padding: 1.75rem 1.25rem min(7vh, 2rem);
  background: ${colors.darkGrey};
  color: ${colors.white};
  text-align: center;
  position: relative;
  font-size: 1rem;
  width: 100%;
  line-height: 1.4;
  z-index: 10000;
  margin-bottom: ${props => (props.isOnScreen ? '100vh' : '0')};
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.9rem;
  overflow: hidden;

  ${props =>
    props.isOnScreen &&
    css`
      box-shadow: 0 1rem 3rem rgb(0, 0, 0);
    `}

  ${media.tablet`
    padding-top: 2rem;
    padding-left: 0;
    padding-right: 0;
    font-size: 1.125rem;
  `};

  @media (min-height: ${heightBreakpoint}) {
    gap: 1.3rem;
  }

  a:not(.thl-button) {
    position: relative;
    z-index: 100;
    &::before {
      background: ${colors.red};
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      transform-origin: 50% 100%;
      transform: scale(1, 0.1);
      transition: 0.3s transform;
      width: 100%;
      z-index: -1;
    }
    &:hover::before {
      transform: scale(1, 1.1);
    }
  }

  strong {
    font-family: ${fonts.titleFont};
    font-weight: bold;
    text-transform: uppercase;
    a {
      margin-right: 2px;
    }
    a::before {
      bottom: -10%;
      transform: scale(1, 0.2);
    }
    a:hover::before {
      transform: scale(1, 1.2);
    }
  }

  ${StyledButton}, ${StyledLink} {
    margin: 0.5em auto 2em;
    font-size: clamp(1.25rem, 3.3vw, 1.375rem);
    padding: 0 28px;

    @media (min-height: ${heightBreakpoint}) and (min-width: ${sizes.mobile}px) {
      margin: 1em auto 3.5em;
    }
  }
`;

export const ImageCredit = styled.div`
  font-size: 0.625rem;

  position: absolute;
  right: 0.5em;
  top: -0.5em;

  transform-origin: bottom right;
  transform: rotate(-90deg);

  color: rgba(255, 255, 255, 60%);

  white-space: nowrap;
`;

const desktopInset = '1.5em';
const mobileScaleFactor = 1.19;
const mobileOffsetFactor = -0.032;

export const CurtainImage = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  height: 75%;
  width: 100%;
  z-index: -1;
  max-width: 1600px;
  transform: translateX(-50%);
  overflow-y: hidden;

  ${media.tabletLarge`
    left: calc(50% - ${desktopInset});
    height: 80%;
    width: calc(100% - calc(${desktopInset} * 2));
    margin: 1em ${desktopInset};
  `};

  img {
    width: 100%;
    height: ${props => (props.imageHeight ? `${props.imageHeight * mobileScaleFactor}px` : '100%')};
    object-fit: cover;
    max-width: none;
    display: block;
    margin-top: ${props =>
      props.imageHeight // eslint-disable-line
        ? props.imageHeight * mobileOffsetFactor + // eslint-disable-line
          (props.imageHeight - props.imageHeight * mobileScaleFactor) / 2 // eslint-disable-next-line
        : 0}px;
    ${media.mobile`
      height: 100%;
      margin-top: 0;
    `};
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }

  &:before {
    background: linear-gradient(
      to bottom,
      rgba(29, 37, 44, 1) 0%,
      rgba(29, 37, 44, 0.34) 17%,
      rgba(29, 37, 44, 0.09) 25%,
      transparent 30%,
      transparent 59%,
      rgba(29, 37, 44, 0.01) 67%,
      rgba(29, 37, 44, 0.06) 70%,
      rgba(29, 37, 44, 0.3) 78%,
      rgba(29, 37, 44, 0.85) 92%,
      rgba(29, 37, 44, 1) 100%
    );
  }

  &:after {
    display: none;
    background: linear-gradient(
      to right,
      rgba(29, 37, 44, 1) 0%,
      transparent 20%,
      transparent 80%,
      rgba(29, 37, 44, 1) 100%
    );

    ${media.tabletLarge`
      display: block;
  `};
  }
`;

export const MendedHeart = styled.div`
  width: 32px;
  flex-grow: 1;

  img {
    display: block;
    width: 100%;
  }
`;

export const CurtainTitle = styled(H2)`
  font-size: clamp(2.25rem, 1.795rem + 2.27vw, 3.5rem);
  color: inherit;
  line-height: 1;
`;

export const CurtainText = styled.div`
  ${text};
  color: inherit;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;

  @media (min-height: ${heightBreakpoint}) {
    gap: 1.3rem;
  }
`;
