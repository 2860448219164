import styled, { css } from 'styled-components';
import prop from 'ramda/es/prop';
import propOr from 'ramda/es/propOr';
import multiply from 'ramda/es/multiply';
import { compose } from 'redux';
import { media } from '../../theme/media';
import { LineDecoration } from '../lineDecoration';

const widthUnit = ({ isPixelWidth }) => {
  if (isPixelWidth) {
    return 'px';
  }

  return 'vw';
};

const height = prop('height');
const tabletHeight = compose(multiply(0.7), height);
const mobileHeight = compose(multiply(0.38), height);

export const Container = styled.div`
  position: relative;
  overflow: visible;
`;

export const Bars = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  ${props => props.pointUp ? css`transform: scaleY(-1)` : ''};
  ${props => props.pointUp ? 'will-change: transform' : ''};
`;

export const OuterBar = styled.div`
  width: 0;
  height: 0;
  border-bottom: ${mobileHeight}px solid ${prop('color')};
  ${(props) => props.right ? 'border-left' : 'border-right'}
    ${propOr(50, 'width')}${widthUnit} solid transparent;
    
  ${media.tablet`
    border-bottom: ${tabletHeight}px solid ${prop('color')};
  `};
  ${media.desktop`
    border-bottom: ${height}px solid ${prop('color')};
  `};
`;

export const InnerBar = styled.div`
  position: relative;
  flex-shrink: 0;
  border-top: ${mobileHeight}px solid ${prop('color')};
  ${(props) => props.right ? 'border-right' : 'border-left'}
    ${propOr(50, 'width')}${widthUnit} solid transparent;
    
  ${media.tablet`
    border-top: ${tabletHeight}px solid ${prop('color')};
  `}
  ${media.desktop`
    border-top: ${height}px solid ${prop('color')};
  `};
`;

export const Column = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export const Filler = styled.div`
  background-color: ${prop('color')};
  flex-grow: 1;
`;

export const Line = styled(LineDecoration)`
  position: absolute;
  left: 50%;
  top: 66.5%;
`;
