import styled, { css } from 'styled-components';
import { colors } from '../../theme/styled';
import { Container as ContainerStyle } from '../../theme/layout';
import { fillAbsolute } from '../../theme/mixins';

const shadowColor = 'rgba(0, 0, 0, 0.08)';
const addUnit = value => value + 'px';

const boxShadow = (...args) => css`
  box-shadow: ${args.map(addUnit).join(' ')} ${shadowColor};
`;

const boxStyle = css`
  position: absolute;
  background-color: ${props => props.backgroundColor || colors.white};
`;

const noShadows = css`
  > * {
    box-shadow: none;
  }
`;

const lineStyle = css`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: ${colors.yellow};
    transform: translateZ(1px);
  }
`;

export const Container = styled(ContainerStyle)`
  position: relative;
  min-height: 100px;
  
  ${props => props.withTopLine && lineStyle};
`;

export const Content = styled.div`
  position: relative;
  min-height: 100px;
`;

export const Top = styled.div`
  ${boxStyle};
  top: 0;
  left: 0;
  bottom: 50%;
  right: 0;
  ${boxShadow(0, -2, 20)};
`;

export const TopOverlay = styled(Top)`
  box-shadow: none;
  bottom: 42px;
`;

export const Left = styled.div`
  ${boxStyle};
  top: 0;
  left: 0;
  bottom: 0;
  right: 50%;
  z-index: -1;
  ${boxShadow(-1, 1, 20, 4)};
`;

export const Right = styled.div`
  ${boxStyle};
  top: 0;
  right: 0;
  bottom: 42px;
  left: 50%;
  z-index: -1;
  ${boxShadow(1, 1, 20, 4)};
`;

export const Bottom = styled.div`
  ${boxStyle};
  height: 60px;
  left: 50%;
  bottom: 0;
  right: ${props => props.hideCorner ? 0 : '42px'};
  ${boxShadow(-5, 1, 20, 4)};
  z-index: -1;
`;

export const BottomOverlay = styled(Top)`
  right: 42px;
  left: 0;
  top: 0;
  bottom : 0;
  box-shadow: none;
`;

export const Rotate = styled.div`
  ${boxStyle};
  transform: rotate(-45deg);
  border-radius: 2px;
  ${boxShadow(0, 3, 20)};
  z-index: -1;
  height: 45px;
  width: 45px;
  bottom: 13px;
  right: 16px;
`;

export const RotateOverlay = styled(Rotate)`
  box-shadow: none;
  z-index: 1;
  height: 60px;
  width: 60px;
  bottom: 12px;
  right: 12px;
`;

export const Background = styled.div`
  ${fillAbsolute}; 
  z-index: 0;
  ${props => props.hideShadow && noShadows};
`;
