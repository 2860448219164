import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './statBlock.styles';
import { IMPACT_COLOR_THEME_LABEL } from '../threeUpImpactStats.styles';
import { getImgProps } from '../../../../utils/cmsData';
import { AnimatedStat } from '../animatedStat';

const StatBlock = ({ icon, description, stat, colorTheme }) => {
  const { src, alt } = icon.src ? icon : getImgProps(icon);
  const endsWithLink = !!description.trim().match(/^.*\[.*\]\(.*\)$/);

  return (
    <Styled.StatBlockContainer>
      <Styled.Icon iconUrl={src} color={colorTheme}>
        <img src={src} alt={alt} />
      </Styled.Icon>
      <AnimatedStat stat={stat} />
      <Styled.Description colorTheme={colorTheme} endsWithLink={endsWithLink}>
        {description}
      </Styled.Description>
    </Styled.StatBlockContainer>
  );
};

StatBlock.propTypes = {
  icon: PropTypes.object.isRequired,
  stat: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(Object.values(IMPACT_COLOR_THEME_LABEL)),
};

StatBlock.defaultProps = {
  icon: null,
  stat: '',
  description: '',
};

export default StatBlock;
