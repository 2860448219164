import React, { useEffect, useState } from 'react';
import { AnchoredScrollTextPlaceholder } from './anchoredScrollText.styles';

const AnchoredScrollText = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);

    const handleResize = () => {
      setIsTransitioning(true);
      setWindowHeight(window.innerHeight);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AnchoredScrollTextPlaceholder
      id="anchored-scroll-text-placeholder"
      windowHeight={windowHeight}
      isTransitioning={isTransitioning}
    />
  );
};

export default AnchoredScrollText;
