/**
 * Sagas for handling article data fetching
 * @module "articlePage.sagas"
 **/

import { put, takeLatest, select } from 'redux-saga/effects';
import { PageActions } from '../page.redux';
import { ArticlePageTypes, ArticlePageActions } from './articlePage.redux';
import { getPage, getPreviewPage } from '../../../services/contentful';
import { selectLocalesLanguage } from '../../locales/locales.selectors';
import { PageLoaderActions } from './../../pageLoader/pageLoader.redux';

/**
 * @method
 * @summary It loads an article with provided slug, and sets proper data to redux store
 * @param slug - article slug to load
 * @param isPreview {bool} - should load preview version of the page
 * @param _opts {object} - additional options for the request
 **/
function* fetchArticleSaga({ slug, isPreview, opts: _opts = {} }) {
  try {
    yield put(PageLoaderActions.openPageLoader());
    yield put(ArticlePageActions.resetArticle());

    const locale = yield select(selectLocalesLanguage);

    const articlePreviewConfig = { 'sys.id': slug };
    const articleConfig = { 'fields.slug': slug };

    const opts = {
      ..._opts,
      locale,
      'content_type': 'article',
      ...(isPreview ? articlePreviewConfig : articleConfig),
    };

    const { items } = isPreview ? yield getPreviewPage(opts) : yield getPage(opts);

    if (items.length === 0) {
      yield put(PageActions.fetchError());
    } else {
      yield put(ArticlePageActions.fetchArticleSuccess({
        contentType: items[0].sys.contentType.sys.id,
        entryId: items[0].sys.id,
        ...items[0],
      }));
    }
  } catch (e) {
    yield put(PageActions.fetchError(e));
  }
  yield put(PageLoaderActions.closePageLoader());
}

export default function* articlePageSaga() {
  yield takeLatest(ArticlePageTypes.FETCH_ARTICLE, fetchArticleSaga);
}
