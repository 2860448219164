import React from 'react';
import PropTypes from 'prop-types';

import usePreviousDistinctValue from '../../utils/usePreviousDistinctValue';

import * as Styles from './counterDigit.styles';

export const CounterDigit = ({ digit }) => {
  const previousDigit = usePreviousDistinctValue(digit);
  const [shouldFlip, setShouldFlip] = React.useState(false);

  React.useEffect(() => {
    if (previousDigit !== digit) {
      setShouldFlip(true);
    }

    return undefined;
  }, [digit]);

  return (
    <Styles.CounterDigitWrapper className={shouldFlip ? 'flip' : ''}>
      <Styles.UpperBehindCounterDigitContainer>
        <Styles.CounterDigitValue>{digit}</Styles.CounterDigitValue>
      </Styles.UpperBehindCounterDigitContainer>
      <Styles.UpperFrontCounterDigitContainer key={`upper-prev-${previousDigit}`}>
        <Styles.CounterDigitValue role="presentation" aria-hidden="true">
          {previousDigit}
        </Styles.CounterDigitValue>
      </Styles.UpperFrontCounterDigitContainer>

      <Styles.LowerBehindCounterDigitContainer key={`lower-prev-${previousDigit}`}>
        <Styles.CounterDigitValue role="presentation" aria-hidden="true">
          {previousDigit}
        </Styles.CounterDigitValue>
      </Styles.LowerBehindCounterDigitContainer>
      <Styles.LowerFrontCounterDigitContainer key={`lower-${digit}`}>
        <Styles.CounterDigitValue role="presentation" aria-hidden="true">
          {digit}
        </Styles.CounterDigitValue>
      </Styles.LowerFrontCounterDigitContainer>
    </Styles.CounterDigitWrapper>
  );
};

CounterDigit.propTypes = {
  digit: PropTypes.string.isRequired,
};
