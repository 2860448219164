import styled from 'styled-components';
import { colors } from '../../theme/styled';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShadowContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const blurRadius = '25px';

export const Shadow = styled.div`
  background: ${colors.grey};
  opacity: 0.7;
  filter: blur(${props => props.blurRadius || blurRadius});
  position: absolute;
  width: calc(100% + ${props => props.blurRadius || blurRadius});
  height: calc(100% + ${props => props.blurRadius || blurRadius});
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: calc(${props => props.blurRadius || blurRadius} / -2);
  z-index: -1;
`;
