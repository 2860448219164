import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InlineText = styled.div`
  display: inline;
`;

const MarkdownGiftValue = ({
  activeValue,
  scale,
  decimals,
}) => {
  return (
    <InlineText>{parseFloat((activeValue * scale).toFixed(decimals))}</InlineText>
  );
};

MarkdownGiftValue.propTypes = {
  activeValue: PropTypes.number.isRequired,
  scale: PropTypes.number,
  decimals: PropTypes.number,
};

MarkdownGiftValue.defaultProps = {
  scale: 1,
  decimals: 0,
};

export default MarkdownGiftValue;
