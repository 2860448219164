import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';

import { Providers } from './Providers';
import { Router } from './Router';
import './Fonts';

import { SharedLayout } from '../../layouts/sharedLayout';
import { NotFoundHandler } from '../../components/notFoundHandler';
import { GlobalStyle } from '../../theme/globalStyle';

import { syncStylesTags } from '../../utils/prerender';
import { isPrerenderBot } from '../../utils/userAgent';
// Store passthrough params
import '../../utils/passthroughParams';

export class THW extends PureComponent {
  componentDidMount() {
    if (isPrerenderBot) {
      syncStylesTags();
    }
  }

  render() {
    return (
      <Providers>
        <GlobalStyle />
        <NotFoundHandler>
          <Helmet defaultTitle="The Humane League" />
          <SharedLayout>
            <Router />
          </SharedLayout>
        </NotFoundHandler>
      </Providers>
    );
  }
}
