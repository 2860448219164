/**
 * This module parses `src/localesData.json` and provides utils for locale related configurations
 *
 * @module
 **/

import { parse } from 'query-string';
import localesDataList from './localesData.json';

export const DEFAULT_LOCALE_ID = 'en-US';
const envLocaleId = process.env.REACT_APP_LOCALE;

/**
 * Returns config for current domain
 * @method
 **/
const getLocaleDataByDomain = () =>
  localesDataList.find(locale => locale.domains.includes(location.hostname));

export const getLocaleData = id => localesDataList.find(locale => locale.id === id);

/**
 * Returns config based on current domain.
 * If `?useLocale=` query parameter is passed, it will be take priority over the detected domain config
 * @method
 **/
export const getRelevantLocaleData = () => {
  const useLocale = getLocaleData(parse(location.search).useLocale);
  if (useLocale) {
    return useLocale;
  }
  if (envLocaleId) {
    return localesDataList.find(locale => locale.id === envLocaleId);
  }
  const domainLocale = getLocaleDataByDomain();
  if (domainLocale) {
    return domainLocale;
  }
  const defaultLocale = getLocaleData(DEFAULT_LOCALE_ID);
  return defaultLocale;
};

/**
 * Returns config for specific country
 * @method
 **/
export const getLocaleDataByCountry = (country = '') =>
  localesDataList.find(locale => locale.country.toUpperCase() === country.toUpperCase());
