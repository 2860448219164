import styled from 'styled-components';
import { FONTS, COLORS } from '../styles/homePageStyles';
import FormattedA from './formattedA.component';

export const LINK_COLOR_LABEL = {
  BLUE: 'blue',
  BLUE_ACCESSIBLE: 'blueAccessible',
  GREEN: 'green',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
};

export const LINK_COLOR = {
  [LINK_COLOR_LABEL.BLUE]: COLORS.BLUE,
  [LINK_COLOR_LABEL.BLUE_ACCESSIBLE]: COLORS.BLUE_ACCESSIBLE,
  [LINK_COLOR_LABEL.GREEN]: COLORS.LIGHT_GREEN,
  [LINK_COLOR_LABEL.RED]: COLORS.BRIGHT_RED,
  [LINK_COLOR_LABEL.TEAL]: COLORS.TEAL,
  [LINK_COLOR_LABEL.YELLOW]: COLORS.YELLOW,
};

export const LINK_TEXT_COLOR_LABEL = {
  WHITE: 'white',
  DARK_GRAY: 'darkGray',
};

const LINK_TEXT_COLOR = {
  [LINK_TEXT_COLOR_LABEL.WHITE]: COLORS.WHITE,
  [LINK_TEXT_COLOR_LABEL.DARK_GRAY]: COLORS.DARK_GRAY,
};

export const StyledLink = styled(FormattedA)`
  --theme-color: ${props =>
    props.theme ? LINK_COLOR[props.theme] : LINK_COLOR[LINK_COLOR_LABEL.GREEN]};
  --font-color: ${props =>
    props.fontColor
      ? LINK_TEXT_COLOR[props.fontColor]
      : LINK_TEXT_COLOR[LINK_TEXT_COLOR_LABEL.WHITE]};

  font-family: ${FONTS.MAIN_FONT};
  color: var(--font-color);
  text-decoration: underline;
  text-decoration-color: var(--theme-color);
  text-decoration-skip-ink: none;
  text-decoration-thickness: 2px;
  text-underline-offset: 2.5px;
  transition: all 0.2s ease-in-out;
  transition-property: background-color, color, border-bottom-color;
  display: inline-block;
  font-weight: 700;
  width: fit-content;
  border-bottom: 3px solid transparent;

  &:hover,
  &:focus {
    background: var(--theme-color);
    color: ${COLORS.DARK_GRAY};
    border-bottom-color: var(--theme-color);
  }
`;
