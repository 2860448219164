import React from 'react';

const LazyHero = React.lazy(() => import('./hero.container'));

const SuspendedHero = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyHero ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedHero;
