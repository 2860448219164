import styled from 'styled-components';
import {
  DIVIDER_COLOR,
  DIVIDER_WIDTH,
  GUTTER_RESPONSIVE,
  RESPONSIVE_BOTTOM_MARGIN,
  Section,
} from '../styles/homePageStyles';
import { media } from '../../../theme/media';

export const STORY_BLOCK_IMAGE_HEIGHT = '218px';
export const PROGRESS_TAG_OVERHANG = '9.78px';

export const ScrollingBlockWrapper = styled(Section)`
  margin-block: 40px 95px;

  ${media.tabletLarge`
    margin-block-start: 50px;
    margin-block-end: ${RESPONSIVE_BOTTOM_MARGIN};
  `}
`;

export const StoryWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: ${DIVIDER_WIDTH} solid ${DIVIDER_COLOR};
  padding-bottom: 35px;
  margin-inline: ${GUTTER_RESPONSIVE};
  width: fit-content;

  :not(:first-child) {
    padding-block: 35px;
  }
`;

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;
