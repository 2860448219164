import React from 'react';

const LazyImageWithBackgroundSection = React.lazy(() =>
  import('./imageWithBackgroundSection.container'),
);

const SuspendedImageWithBackgroundSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyImageWithBackgroundSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedImageWithBackgroundSection;
