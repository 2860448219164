import cookie from 'js-cookie';

export const Status = {
  NONE: 'none',
  INITIALISED: 'initialised',
  ABANDONED: 'abandoned',
  SUBMITTED: 'submitted',
};

/**
 * Tracks progress of single funnel (i.e. donation / activism) and keeps the status in cookies
 **/
class FormTracker {
  prefix = null;

  constructor(prefix) {
    this.prefix = prefix;
  }

  get cookieName() {
    return `${this.prefix}_form_status`.toUpperCase();
  }

  get currentStatus() {
    return cookie.get(this.cookieName);
  }

  /**
   * @method
   * @summary Only `forward` transitions are valid.
   **/
  validateStatus(newStatus) {
    const currentStatus = this.currentStatus;

    return !currentStatus || currentStatus === Status.NONE
      || currentStatus === Status.INITIALISED
      || newStatus === Status.SUBMITTED;
  }

  updateStatus(status) {
    if (this.validateStatus(status)) {
      cookie.set(this.cookieName, status, { path: '', expires: 365 });
    }
  }

  resetStatus() {
    cookie.set(this.cookieName, Status.NONE, { path: '', expires: 365 });
  }
}

export const donateTracker = new FormTracker('donate');
export const activismTracker = new FormTracker('activism');
export const subscribeTracker = new FormTracker('subscribe');
