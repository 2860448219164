import throttle from 'lodash.throttle';
import without from 'ramda/es/without';

let handlers = [];

const onScroll = throttle(() => {
  handlers.forEach(handleFn => handleFn());
}, 150);

let isStarted = false;
const startListeners = () => {
  window.addEventListener('scroll', onScroll);
  isStarted = true;
};
const stopListeners = () => {
  window.removeEventListener('scroll', onScroll);
  isStarted = false;
};

export const addScrollListener = callback => {
  handlers.push(callback);
  if (!isStarted) {
    startListeners();
  }
};
export const removeScrollListener = callback => {
  handlers = without([callback], handlers);
  if (handlers.length === 0) {
    stopListeners();
  }
};
