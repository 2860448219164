import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Icon } from './videoIcon.styles';


/*
 * Positions an icon in the middle of whatever it is contained in.
 */

export class VideoIcon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { onClick, className } = this.props;
    return <Icon className={className} onClick={onClick} />;
  }
}
