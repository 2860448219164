/**
 * Helpers for CMS data parsing aa
 * @module cmsData
 */

import { fromJS, Map } from 'immutable';
import curryN from 'ramda/es/curryN';
import map from 'ramda/es/map';
import compose from 'ramda/es/compose';
import identity from 'ramda/es/identity';
import { decorate } from '../decorators';
import { ROUTES } from '../constants';

export const extract = selectors => item => map(selectFn => selectFn(item), selectors);

export const parseFields = (data, vanillaJS = false) =>
  map(vanillaJS ? identity : field => fromJS(field), data.get('fields').toJS());

/**
 * Returns the value of a field in an immutable map of an object of form { fields: ... }
 * @method
 * @example
 * getField('title', immutizedContentItem);
 **/
export const getField = curryN(2, (field, item = new Map()) => {
  const path = Array.isArray(field) ? field : [field];
  return item.getIn(['fields', ...path]);
});

export const getFieldIn = curryN(2, (fieldsArray, item) => {
  const selector = fieldsArray.reduce((currentSelector, value, index) => {
    return index === 0 ? [value] : [...currentSelector, 'fields', value];
  }, []);
  return getField(selector, item);
});

/**
 * Returns url of media file
 * @method
 **/
export const getFileUrl = file => getField(['file', 'url'], file);
export const getSrc = getFileUrl;

/**
 * Returns props for image media item to be applied directly on `<img>` element
 * @method
 **/
export const getImgProps = extract({
  src: getSrc,
  alt: getField('title'),
});

/**
 *
 * @param {object} contentEntry
 * @returns {string=}
 */
export const getImageCredit = contentEntry => {
  const imageCredit = contentEntry.getIn(['fields', 'imageCredit']);

  return imageCredit && `Photo: ${imageCredit}`;
};

export const getId = item => item.getIn(['sys', 'id']);
export const getComponentName = c => c.getIn(['sys', 'contentType', 'sys', 'id']);
export const getComponentId = c => c.getIn(['sys', 'id']);
export const getContentType = getComponentName;
/**
 * Returns article data from CMS `article` item
 * @method
 **/
// export const getArticleCardData = (item) => {
// return {
// id: getId(item),
// slug: getField('slug', item),
// title: getField('title', item),
// excerpt: getField('excerpt', item),
// heroVideo: getField('heroVideo', item),
// authors: decorate(getField('authors', item)),
// date: getField('date', item),
// category: compose(
// getField('name'),
// getField('category')
// )(item),
// image: compose(
// getSrc,
// getField('image'),
// getField('heroImage'),
// )(item),
// imageAlt: compose(
// getField('title'),
// getField('image'),
// getField('heroImage'),
// )(item),
// url: compose(
// slug => ROUTES.article.url.replace(':slug', slug),
// getField('slug'),
// )(item),
// };
// };

export const getArticleCardData = extract({
  id: getId,
  slug: getField('slug'),
  title: getField('title'),
  excerpt: getField('excerpt'),
  heroVideo: getField('heroVideo'),
  authors: compose(l => decorate(l), getField('authors')),
  date: getField('date'),
  dateRevised: getField('dateRevised'),
  category: compose(getField('name'), getField('category')),
  image: compose(getSrc, getField('image'), getField('heroImage')),
  imageAlt: compose(getField('title'), getField('image'), getField('heroImage')),
  url: compose(slug => ROUTES.article.url.replace(':slug', slug), getField('slug')),
});

export const toFields = obj => fromJS({ fields: obj });

export const getUrls = urls => (urls ? urls.toJS().map(u => u.fields.url) : null);
