import { getRelevantLocaleData } from './locales/config';
import { isSmallDevice } from './theme/media';

const { authorPath, articlePath } = getRelevantLocaleData();

export const ROUTES = {
  home: {
    url: '/',
  },
  component: {
    previewUrl: '/component/preview/:sysid',
  },
  page: {
    // match against location.pathname rather than params
    url: '/:slug',
    splat: '/*',
    previewUrl: '/preview/:sysid',
  },
  article: {
    url: `/${articlePath}/:slug`,
    previewUrl: `/${articlePath}/preview/:sysid`,
  },
  author: {
    url: `/${authorPath}/:slug`,
    previewUrl: `/${authorPath}/preview/:sysid`,
  },
  notFound: {
    url: '/404',
  },
};

export const SOCIAL_ICONS_SIZE = {
  SMALL: 'sm',
  REGULAR: 'lg',
};

export const MODAL_TEMPLATE = 'Modal';
export const MODAL_WITH_BUTTONS_TEMPLATE = 'Modal with table of buttons';
export const BANNER_TO_MODAL_TEMPLATE = 'Banner on mobile / Modal on desktop';
export const MODAL_TEMPLATES = isSmallDevice()
  ? [MODAL_TEMPLATE, MODAL_WITH_BUTTONS_TEMPLATE]
  : [MODAL_TEMPLATE, MODAL_WITH_BUTTONS_TEMPLATE, BANNER_TO_MODAL_TEMPLATE];
export const BANNER_TEMPLATES = isSmallDevice() ? [BANNER_TO_MODAL_TEMPLATE, 'Banner'] : ['Banner'];
export const CURTAIN_TEMPLATES = ['Curtain'];
