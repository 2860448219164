import { put, takeLatest, all } from 'redux-saga/effects';
import { PeopleHrTypes, PeopleHrActions } from './peopleHr.redux';
import { getJobsFromUrl } from '../../services/peopleHr';


export function* fetchPeopleHrSaga(action) {
  try {
    const jobs = yield getJobsFromUrl(action.url);
    yield put(PeopleHrActions.fetchSuccess(jobs));
  } catch (e) {
    yield put(PeopleHrActions.fetchError(e));
  }
}

export default function* PeopleHrSaga() {
  yield all([
    takeLatest(PeopleHrTypes.FETCH, fetchPeopleHrSaga),
  ]);
}
