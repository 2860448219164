import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { colors, positions } from '../../theme/styled';
import {
  Container,
  HeroImageContainer,
  Section,
  TitleImage,
  Description,
  LineClip,
  LineDecoration,
  TITLE_IMAGE_SIZES,
} from './heroHome.styles';
import { GradientContainer } from '../hero/hero.styles';
import { HeroImage } from '../heroImage/heroImage.component';
import { Badges } from '../badgesWithLink/badges.component';
import { DonateBox } from '../donateBox/donateBox.component';
import { HiddenHeader } from '../hiddenHeader';
import { AnimatedEnter } from '../animatedEnter';
import { identifiers } from '../../utils/rendering';
import {
  HeroContainer as BadgeContainer,
  HeroImageContainer as ImageContainer,
} from '../badgesWithLink/badges.styles';

export class HeroHome extends PureComponent {
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    entryId: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    mobileImage: PropTypes.object,
    titleImage: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    badges: PropTypes.object,
    includeDonateBox: PropTypes.bool,
    donateTitle: PropTypes.string.isRequired,
    donateDescription: PropTypes.string.isRequired,
    donateUrl: PropTypes.string.isRequired,
    donateDefaultAmount: PropTypes.number,
    donateIsRecurring: PropTypes.bool,
    hideGradient: PropTypes.bool,
    focalPointX: PropTypes.number,
    focalPointY: PropTypes.number,
  };

  render() {
    const {
      contentType,
      entryId,
      image,
      mobileImage,
      titleImage,
      imageAlt,
      name,
      description,
      badges,
      includeDonateBox,
      donateTitle,
      donateDescription,
      donateUrl,
      donateDefaultAmount,
      donateIsRecurring,
      hideGradient,
      focalPointX,
      focalPointY,
    } = this.props;

    return (
      <Container {...identifiers('heroHome', contentType, entryId)}>
        <HeroImageContainer>
          <HeroImage
            image={image}
            mobileImage={mobileImage}
            backgroundPosition={positions.top}
            focalPointX={focalPointX}
            focalPointY={focalPointY}
          />
          {!hideGradient && <GradientContainer subtle={true} />}
          <LineClip height={275} />
        </HeroImageContainer>
        <Section componentName="nested" maxWidth={1920}>
          <HiddenHeader title={name} headerLevel={2} />
          <AnimatedEnter>
            <TitleImage src={titleImage} alt={imageAlt} sizes={TITLE_IMAGE_SIZES} />
            <Description>{description}</Description>
          </AnimatedEnter>
          <BadgeContainer>
            <Badges items={badges} ImageWrapper={ImageContainer} />
          </BadgeContainer>
          {includeDonateBox && (
            <DonateBox
              title={donateTitle}
              description={donateDescription}
              defaultAmount={donateDefaultAmount}
              isRecurring={donateIsRecurring}
              backgroundColor={colors.darkGrey}
              url={donateUrl}
              isCutCorner
            />
          )}
          <LineDecoration />
        </Section>
      </Container>
    );
  }
}
