import React from 'react';

const LazyVideoTextSection = React.lazy(() => import('./videoTextSection.container'));

const SuspendedVideoTextSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyVideoTextSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedVideoTextSection;
