import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { IntlProvider } from './IntlProvider.component';

import { selectLocalesLanguage } from '../../../../reducers/locales/locales.selectors';
import { LocalesActions } from '../../../../reducers/locales/locales.redux';

const mapStateToProps = createStructuredSelector({
  language: selectLocalesLanguage,
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setLanguage: LocalesActions.setLanguage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const loc = useLocation();
  return <IntlProvider {...props} location={loc} />;
});
