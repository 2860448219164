import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import StoryBlock from './storyBlock/storyBlock.component';
import * as Styled from './threeUpScrollingBlock.styles';
import { useOnScreen } from '../utils/useOnScreen';
import { MobileCarousel } from './mobileCarousel';
import { DesktopCarousel } from './desktopCarousel';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';

export const MOBILE_LAYOUTS = {
  verticalStack: 'verticalStack',
  oneUpScrollingBlock: 'oneUpScrollingBlock',
};

const randomizeStoryOrder = (firstStoryId, storyIds) => {
  let randomizedStoryIds = [...storyIds];

  randomizedStoryIds.sort(() => Math.random() - 0.5);

  if (firstStoryId) {
    randomizedStoryIds = randomizedStoryIds.filter(id => id !== firstStoryId);
    randomizedStoryIds.unshift(firstStoryId);
  }

  return randomizedStoryIds;
};

const ThreeUpScrollingBlock = ({ mobileLayout, mobileFirstStory, storyBlocks }) => {
  const onScreenRef = useRef(null);
  const isOnScreen = useOnScreen(onScreenRef, 0, 0.2, 0, true);
  const storyOrder = storyBlocks.map(storyBlock => storyBlock.id);
  const enableMobileCarousel = mobileLayout === MOBILE_LAYOUTS.oneUpScrollingBlock;
  const [mobileCarouselStoryOrder, setMobileCarouselStoryOrder] = useState(storyOrder);

  useEffect(() => {
    if (enableMobileCarousel) {
      setMobileCarouselStoryOrder(randomizeStoryOrder(mobileFirstStory, storyOrder));
    }
  }, [storyBlocks.length, mobileFirstStory]);

  return (
    <Styled.ScrollingBlockWrapper ref={onScreenRef}>
      <PlatformDetector>
        {({ isTabletLandscape, isDesktop, isDesktopFull }) => {
          const isLargeDevice = isTabletLandscape || isDesktop || isDesktopFull;
          return (
            <React.Fragment>
              {!isLargeDevice &&
                (enableMobileCarousel ? (
                  <MobileCarousel
                    storyBlocks={storyBlocks}
                    storyOrder={mobileCarouselStoryOrder}
                    isOnScreen={isOnScreen}
                  />
                ) : (
                  <Styled.VerticalStack>
                    {storyBlocks.map(storyBlock => (
                      <Styled.StoryWrapper key={storyBlock.id}>
                        <StoryBlock {...storyBlock} />
                      </Styled.StoryWrapper>
                    ))}
                  </Styled.VerticalStack>
                ))}
              {isLargeDevice && (
                <DesktopCarousel
                  storyBlocks={storyBlocks}
                  storyOrder={storyOrder}
                  isOnScreen={isOnScreen}
                />
              )}
            </React.Fragment>
          );
        }}
      </PlatformDetector>
    </Styled.ScrollingBlockWrapper>
  );
};

ThreeUpScrollingBlock.propTypes = {
  mobileLayout: PropTypes.oneOf(Object.values(MOBILE_LAYOUTS)).isRequired,
  mobileFirstStory: PropTypes.string,
  storyBlocks: PropTypes.arrayOf(StoryBlock),
};

export default ThreeUpScrollingBlock;
