import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';
import { appendHomePageParams, getExternalLinkProps, isInternalLink } from '../utils/linkHelpers';

// Formatted anchor element to be used in place of all <a> elements on the home page
const FormattedA = ({ href, children, ...props }) => {
  const formattedHref = appendHomePageParams(href);
  if (isInternalLink(href)) {
    return (
      <ReactLink to={formattedHref} tabIndex={0} {...props}>
        {children}
      </ReactLink>
    );
  }
  return (
    <a href={formattedHref} {...getExternalLinkProps(href)} {...props}>
      {children}
    </a>
  );
};

FormattedA.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

FormattedA.defaultProps = {
  href: '',
};

export default FormattedA;
