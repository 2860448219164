import { put, all, select, takeEvery } from 'redux-saga/effects';
import max from 'ramda/es/max';
import { ArticleTypes, ArticleActions } from './news.redux';
import { getCollections, onlyCurrentLocaleFilter } from '../../services/contentful';
import {
  selectActiveCategory, selectAllArticles, selectSubcategoriesActiveCategory,
} from './news.selectors';
import { selectLocalesLanguage } from '../locales/locales.selectors';
import { getField } from '../../utils/cmsData';

const COLLECTION_NAME = 'article';

const categoryFilterQuery = categories => ({
  'fields.category.sys.contentType.sys.id': 'newsCategoryDefinition',
  'fields.category.fields.name[in]': categories,
  'fields.date[lt]': new Date(),
});

let activeFetch = {
  limit: 0,
  category: null,
};

export function* fetchArticlesByCategorySaga({ limit: _limit, skip = 0 }) {
  try {
    const limit = max(_limit, 4);

    const category = yield select(selectActiveCategory);
    const subcategories = yield select(selectSubcategoriesActiveCategory);
    let categories = subcategories && subcategories.map(subcategory => getField('name', subcategory)) || category;

    if (categories.size) {
      categories = categories.join();
      categories = `${category},${categories}`;
    }

    if (activeFetch.limit >= limit && skip === 0 && activeFetch.category === categories) {
      // there is already a fetch requested that fulfills current parameters
      return;
    }

    if (skip === 0) {
      activeFetch = {
        category: categories,
        limit,
      };
    }

    const categoryFilter = category ? categoryFilterQuery(categories) : {};
    const locale = yield select(selectLocalesLanguage);

    const { items, total } = yield getCollections({
      collection: COLLECTION_NAME,
      limit,
      skip,
      locale,
      order: '-fields.date',
      ...onlyCurrentLocaleFilter,
      ...categoryFilter,
    });

    activeFetch = {};
    yield put(ArticleActions.fetchByCategorySuccess(items, category));

    const news = yield select(selectAllArticles);

    if (items.length < limit || news.size >= total) {
      yield put(ArticleActions.setLimitReachedByCategory(true, category));
    }
  } catch (e) {
    activeFetch = {};
    yield put(ArticleActions.fetchError(e));
  }
}

export function* fetchArticlesByAuthorSaga({ authorId, limit: _limit, skip = 0 }) {
  try {
    const limit = max(_limit, 4);

    const locale = yield select(selectLocalesLanguage);

    const { items, total } = yield getCollections({
      collection: COLLECTION_NAME,
      limit,
      skip,
      locale,
      'links_to_entry': authorId,
      'fields.date[lt]': new Date(),
      order: '-fields.date',
      ...onlyCurrentLocaleFilter,
    });

    activeFetch = {};
    yield put(ArticleActions.fetchByAuthorSuccess(items, authorId));
    const news = yield select(selectAllArticles);

    if (items.length < limit || news.size >= total) {
      yield put(ArticleActions.setLimitReached(true, category));
    }
  } catch (e) {
    activeFetch = {};
    yield put(ArticleActions.fetchError(e));
  }
}

export default function* ArticlesSaga() {
  yield all([
    takeEvery(ArticleTypes.FETCH_BY_CATEGORY, fetchArticlesByCategorySaga),
    takeEvery(ArticleTypes.FETCH_BY_AUTHOR, fetchArticlesByAuthorSaga),
  ]);
}
