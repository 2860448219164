import React from 'react';

const LazyPage = React.lazy(() => import('./homePage.container'));

const SuspendedHomePage = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyPage ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedHomePage;
