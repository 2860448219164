import React from 'react';

const LazyVideoModal = React.lazy(() => import('./videoModal.container'));

const SuspendedVideoModal = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyVideoModal ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedVideoModal;
