import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import {
  isSrcSetSupported,
  getImageSource,
  getImageCustomSource,
  getLimitedWidth,
} from '../../utils/image';
import { getDevicePixelRatio, sizes } from '../../theme/media';
import { getSrc } from '../../utils/cmsData';
import { interactionProps } from '../../utils/rendering';
import { SectionViewportContext } from '../contentManager/pageSection/pageSection.component';

/**
 * Renders accessible img element
 **/
export class Image extends PureComponent {
  /**
   * PropTypes
   * @property src {string} - source of the image
   * @property image {object} - CMS image data object (can be used instead of src string)
   * @property srcSet {array} - list of image sources for different breakpoints
   * @property alt
   * @property sizes {array} - list defining what src to use for different browser sizes
   * @property preload {boolean} - whether or not to preload this image in the <head>, use with caution
   **/
  static propTypes = {
    src: PropTypes.string,
    image: PropTypes.object,
    srcSet: PropTypes.object,
    alt: PropTypes.string,
    className: PropTypes.string,
    sizes: PropTypes.object,
    role: PropTypes.string,
    loading: PropTypes.string,
    fetchpriority: PropTypes.string,
    onLoad: PropTypes.func,
    preload: PropTypes.bool,
  };

  static contextType = SectionViewportContext;

  state = {
    fallbackSrc: '',
  };

  componentDidMount() {
    if (!isSrcSetSupported()) {
      this.setState({
        fallbackSrc: this.getFallbackSrc(this.src),
      });

      window.addEventListener('resize', this.onResize);
    }
  }

  componentWillUnmount() {
    if (!isSrcSetSupported()) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize = () => {
    if (this.src) {
      this.setState({
        fallbackSrc: this.getFallbackSrc(this.src),
      });
    }
  };

  getFallbackSrc(src) {
    if (window.innerWidth <= sizes.mobile) {
      return getImageSource(src, sizes.mobile);
    } else if (window.innerWidth <= sizes.tablet) {
      return getImageSource(src, sizes.tablet);
    } else if (window.innerWidth <= sizes.desktop) {
      return getImageSource(src, sizes.desktop);
    }

    return getImageSource(src, sizes.desktopFull);
  }

  get fileSrc() {
    return this.props.image && getSrc(this.props.image);
  }

  get src() {
    return this.props.src || this.fileSrc;
  }

  get customSrcSet() {
    return this.props.srcSet && this.props.srcSet.map(image => image.getIn(['fields', 'file']));
  }

  get srcSet() {
    if (this.customSrcSet && this.customSrcSet.size) {
      return this.customSrcSet.toArray().map(getImageCustomSource).join();
    }

    const imageSizes = {};

    Object.values(sizes).map(size => {
      if (this.props.sizes && !this.props.sizes[size]) {
        Object.keys(this.props.sizes).map(key => {
          if (key < size) {
            imageSizes[size] = this.props.sizes[key];
          }
        });
      } else {
        imageSizes[size] =
          this.props.sizes && this.props.sizes[size] ? this.props.sizes[size] : size;
      }
    });

    return Object.values(sizes)
      .map(
        size =>
          `${getImageSource(this.src, imageSizes[size] * getDevicePixelRatio())}
      ${getLimitedWidth(Math.floor(size * getDevicePixelRatio()))}w`,
      )
      .join();
  }

  get alt() {
    return this.props.alt || '';
  }

  render() {
    const src = isSrcSetSupported() ? this.src + '?q=75' : this.state.fallbackSrc + '?q=75';

    return (
      <React.Fragment>
        {this.props.preload && (
          <Helmet>
            <link
              rel="preload"
              as="image"
              href={src}
              imagesrcset={this.context.isActive ? this.srcSet : undefined}
              type="image/webp"
              imagesizes={`(max-width: ${sizes.desktopFull}px) 100vw, ${sizes.desktopFull}px`}
            />
          </Helmet>
        )}
        <img
          className={this.props.className}
          src={this.context.isActive ? src : undefined}
          srcSet={this.context.isActive ? this.srcSet : undefined}
          alt={this.alt}
          role={this.props.role}
          loading={this.props.loading || 'lazy'}
          fetchpriority={this.props.fetchpriority}
          onLoad={this.props.onLoad}
          {...interactionProps(this.props)}
        />
      </React.Fragment>
    );
  }
}
