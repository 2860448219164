import React from 'react';
import PropTypes from 'prop-types';
import remarkMath from 'remark-math';
import remarkGFM from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkDirective from 'remark-directive';
import visit from 'unist-util-visit';
import * as Styled from './markdown.styles';
import { Header, Link } from './markdown.styles';
import { LINK_COLOR_LABEL } from '../link/link.styles';
import MarkdownGiftValue from './customRenderers/markdownGiftValue.component';

const identifiers = (componentName, contentType, entryId) => ({
  'data-component-name': componentName,
  'data-content-type': contentType,
  'data-entry-id': entryId,
  id: entryId ? `thl-${entryId}` : null,
});

const tf = node => {
  if (
    node.type === 'textDirective' ||
    node.type === 'leafDirective' ||
    node.type === 'containerDirective'
  ) {
    // eslint-disable-next-line no-param-reassign
    node.data = node.data || {};
    const { data } = node;
    data.hName = node.name;
    data.hProperties = { ...node.attributes };
  }
};

function customDirectives() {
  return tree => {
    visit(tree, tf);
  };
}

const Markdown = props => {
  const { contentType, entryId, renderers, activeValue } = props;
  const text = props.children || props.text;
  const theme = props.colorTheme;
  const componentMap = {
    a: props => <Link url={props.href} text={props.children[0]} theme={theme} {...props} />,
    h2: props => <Header {...props} />,
    giftvalue: props => <MarkdownGiftValue activeValue={activeValue} {...props}>{props.children}</MarkdownGiftValue>,
    ...renderers,
  };
  return (
    <Styled.Wrapper
      components={componentMap}
      className={props.className}
      remarkPlugins={[remarkMath, remarkDirective, customDirectives, remarkGFM]}
      rehypePlugins={[rehypeRaw]}
      {...identifiers('Text', contentType, entryId)}
    >
      {text}
    </Styled.Wrapper>
  );
};

Markdown.propTypes = {
  contentType: PropTypes.string,
  entryId: PropTypes.string,
  children: PropTypes.any,
  text: PropTypes.string,
  renderers: PropTypes.objectOf(PropTypes.func),
  className: PropTypes.string,
  src: PropTypes.string,
  href: PropTypes.string,
  colorTheme: PropTypes.oneOf(Object.values(LINK_COLOR_LABEL)),
  activeValue: PropTypes.number,
};

export default Markdown;
