import styled, { css } from 'styled-components';
import { media } from '../../../../theme/media';
import { Markdown } from '../../markdown';
import { LINK_COLOR as IMPACT_COLOR_THEME } from '../../link/link.styles';

export const StatBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  flex: 1;

  ${media.tabletLarge`
    max-width: 330px;
  `}
`;

export const Icon = styled.div`
  ${props =>
    props.color &&
    css`
      background-color: ${IMPACT_COLOR_THEME[props.color]};
      mask: url(${props.iconUrl}) no-repeat center;
    `}

  img {
    height: 85px;
    opacity: ${props => (props.color ? 0 : 1)};
    display: block;
  }

  ${media.tabletLarge`
    img {
      height: 90px;
    }
  `}
`;

export const Description = styled(Markdown)`
  ${props =>
    props.endsWithLink &&
    css`
      a:last-child {
        display: block;
        width: fit-content;
        margin: 30px auto;
      }

      ${media.tabletLarge`
        a:last-child {
          margin: 0 auto;
        }
      `}
    `}
`;
