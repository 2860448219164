import React from 'react';

const LazyCarouselSection = React.lazy(() => import('./carouselSection.container'));

const SuspendedCarouselSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyCarouselSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedCarouselSection;
