import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonLabel: {
    id: 'review.buttonLabel',
    defaultMessage: 'Donate',
  },
  checkboxLabel: {
    id: 'review.checkboxLabel',
    defaultMessage: 'Monthly',
  },
  placeholder: {
    id: 'donateBox.placeholder',
    defaultMessage: '25',
  },
  prefix: {
    id: 'donateBox.prefix',
    defaultMessage: '$',
  },
  amountParameter: {
    id: 'donateBox.amountParameter',
    defaultMessage: 'am',
  },
  recurParameter: {
    id: 'donateBox.recurParameter',
    defaultMessage: 'r',
  },
  recurTrue: {
    id: 'donateBox.recurTrue',
    defaultMessage: 'true',
  },
  recurFalse: {
    id: 'donateBox.recurFalse',
    defaultMessage: 'false',
  },
});
