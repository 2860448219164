import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { SourceIconLink, SocialIcon } from './twitterShare.styles';
import { getSocialIcon } from '../../utils/image';
import { SOCIAL_ICONS_SIZE } from '../../constants';

import messages from './twitterShare.messages';
import { addUtmString, PlatformId } from '../../utils/socials';

class TwitterShare extends PureComponent {
  static propTypes = {
    shareText: PropTypes.string,
    className: PropTypes.string,
    intl: PropTypes.any,
  };

  share = () => {
    const text = encodeURIComponent(this.props.shareText);
    const url = encodeURIComponent(
      addUtmString(PlatformId.TWITTER)(location.href)
    );
    window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`);
  };

  render() {
    const { className } = this.props;

    return (
      <SourceIconLink className={className}>
        <SocialIcon
          src={getSocialIcon('twitter', 'black', SOCIAL_ICONS_SIZE.SMALL)}
          hoverSrc={getSocialIcon('twitter', 'hover', SOCIAL_ICONS_SIZE.SMALL)}
          handleShare={this.share}
          alt={this.props.intl.formatMessage(messages.share)}
        />
      </SourceIconLink>
    );
  }
}

export default injectIntl(TwitterShare);
