import React from 'react';
import PropTypes from 'prop-types';

import { Markdown } from '../markdown';
import { LINK_COLOR_LABEL, LINK_TEXT_COLOR_LABEL } from '../link/link.styles';
import * as Styled from './oneUpNewsMajor.styles';
import { FormattedA, Link } from '../link';

const Wrapper = ({ children }) => (
  <Styled.Section>
    <Styled.Pad>
      <Styled.OuterContainer>
        <Styled.Content>{children}</Styled.Content>
      </Styled.OuterContainer>
    </Styled.Pad>
  </Styled.Section>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const OneUpNewsMajor = ({
  url,
  headline = '',
  date,
  description,
  imageSrc,
  imageAlt,
  links,
  isVideo = false,
}) => {
  return (
    <Wrapper>
      <Styled.ImageSection>
        <Styled.Title displayOnMobile>
          <FormattedA href={url}>
            <Styled.H2>{headline}</Styled.H2>
          </FormattedA>
        </Styled.Title>
        <FormattedA href={url}>
          <Styled.ImageContainer>
            <Styled.Image src={imageSrc} alt={imageAlt} />
            {isVideo && <Styled.VideoPlayIcon />}
          </Styled.ImageContainer>
        </FormattedA>
      </Styled.ImageSection>
      <Styled.TextSection>
        <Styled.Title displayOnDesktop>
          <FormattedA href={url}>
            <Styled.H2>{headline}</Styled.H2>
          </FormattedA>
        </Styled.Title>
        {date && <Styled.SmallText>{date}</Styled.SmallText>}
        {description && <Markdown colorTheme={LINK_COLOR_LABEL.RED}>{description}</Markdown>}
        {links && (
          <Styled.Links>
            {links.map(({ label, url }, index) => (
              <Link
                key={index}
                text={label}
                url={url}
                theme={LINK_COLOR_LABEL.RED}
                fontColor={LINK_TEXT_COLOR_LABEL.DARK_GRAY}
              />
            ))}
          </Styled.Links>
        )}
      </Styled.TextSection>
    </Wrapper>
  );
};

OneUpNewsMajor.propTypes = {
  url: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  date: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  isVideo: PropTypes.bool,
};

export default OneUpNewsMajor;
