/**
 * Sagas for handling individual component data fetching
 * @module "componentPage.sagas"
 **/

import { put, takeLatest, select } from 'redux-saga/effects';
import { PageActions } from '../page.redux';
import { ComponentPageTypes, ComponentPageActions } from '../componentPage/componentPage.redux';
import { getPreviewComponent } from '../../../services/contentful';
import { selectLocalesLanguage } from '../../locales/locales.selectors';
import { PageLoaderActions } from '../../pageLoader/pageLoader.redux';

/**
 * @method
 * @summary It loads an article with provided slug, and sets proper data to redux store
 * @param sysid {string} - contentful component sys id to load
 **/
function* fetchContentfulComponentSaga({ sysid }) {
  try {
    yield put(PageLoaderActions.openPageLoader());

    const locale = yield select(selectLocalesLanguage);
    const opts = {
      locale,
      'sys.id': sysid,
    };
    const { items: { 0: component } } = yield getPreviewComponent(opts);

    if (component) {
      yield put(ComponentPageActions.fetchContentfulComponentSuccess(component));
    } else {
      yield put(PageActions.fetchError());
    }
  } catch (e) {
    yield put(PageActions.fetchError(e));
  }
  yield put(PageLoaderActions.closePageLoader());
}

export default function* componentPageSaga() {
  yield takeLatest(ComponentPageTypes.FETCH_CONTENTFUL_COMPONENT, fetchContentfulComponentSaga);
}
