/**
 * Decorator providing utility functions for mediaItem: author content models.
 *
 * @module AuthorDecorator
 **/

import Decorator from './decorator';
import ImageDecorator from './image.decorator';
import { getField } from '../utils/cmsData';
import { ROUTES } from '../constants';

export default class AuthorDecorator extends Decorator {
  /**
   * Returns the author name.
   *
   * @public
   * @return {string} The name, or an empty string if absent.
   */
  get name() {
    return getField('name', this.data) || '';
  }

  lastName() {
    return this.name.split(' ').pop();
  }

  get text() {
    return getField('text', this.data) || '';
  }

  get entryId() {
    return this.data.getIn(['sys', 'id']);
  }

  get authorPageUrl() {
    return ROUTES.author.url.replace(':slug', this.name.replace(' ', '-')).toLowerCase();
  }

  /**
   * Returns the an image decorator decorating the author's image field.
   *
   * @public
   * @return {ImageDecorator} Decorated author image data.
   */
  get image() {
    return new ImageDecorator(getField('image', this.data));
  }
}
