import React from 'react';

const LazyCategoryCarousel = React.lazy(() => import('./categoryCarousel.container'));

const SuspendedCategoryCarousel = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyCategoryCarousel ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedCategoryCarousel;
