import styled, { css } from 'styled-components';
import { media } from '../../../../theme/media';
import {
  GUTTER_RESPONSIVE,
  COLORS,
  DIVIDER_WIDTH,
  DIVIDER_COLOR,
  FADE_IN_UP_DISTANCE,
} from '../../styles/homePageStyles';
import { PROGRESS_TAG_OVERHANG, STORY_BLOCK_IMAGE_HEIGHT } from '../threeUpScrollingBlock.styles';

export const INITIAL_ANIMATION_DURATION = 2;
const STORY_BLOCK_FLY_IN_DURATION = INITIAL_ANIMATION_DURATION * 0.4;

const storyBlockFlyIn = delay =>
  css`
    --animation: ${STORY_BLOCK_FLY_IN_DURATION * (2 / 3)}s ${delay}s;
    transition: transform var(--animation), opacity var(--animation);
    transform: translateX(50%);
    opacity: 0;

    ${props =>
    props.isOnScreen &&
      css`
        transform: translateX(0px);
        opacity: 1;
      `};
  `;

export const StoryWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: calc(100% / 3 - (${GUTTER_RESPONSIVE} * 2 / 3));

  a {
    pointer-events: ${props => (props.dragging ? 'none' : '')};
  }

  :nth-child(1) {
    ${storyBlockFlyIn(0)}
  }

  :nth-child(2) {
    ${storyBlockFlyIn(STORY_BLOCK_FLY_IN_DURATION * (1 / 6))}
  }

  :nth-child(3) {
    ${storyBlockFlyIn(STORY_BLOCK_FLY_IN_DURATION * (1 / 3))}
  }

  :not(:last-child):after {
    content: '';
    position: absolute;
    height: ${props => props.dividerHeight}px;
    width: ${DIVIDER_WIDTH};
    background: ${DIVIDER_COLOR};
    right: calc(calc(${GUTTER_RESPONSIVE} / 2) * -1);
  }
`;

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  column-gap: ${GUTTER_RESPONSIVE};
  padding-inline: ${PROGRESS_TAG_OVERHANG};
  margin-inline: -${PROGRESS_TAG_OVERHANG};
  // Prevents tally embellishments from clipping when they fade in/up
  padding-bottom: ${FADE_IN_UP_DISTANCE};
  margin-bottom: -${FADE_IN_UP_DISTANCE};
`;

export const ArrowButton = styled.button`
  --button-size: 45px;

  display: none;

  ${media.tabletLarge`
    width: var(--button-size);
    height: var(--button-size);
    background: ${COLORS.DARK_GRAY};
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: calc(${STORY_BLOCK_IMAGE_HEIGHT} / 2 - var(--button-size) / 2);
    ${props => (props.back ? 'left' : 'right')}: calc(14px - var(--button-size));
    transition: background 0.2s ease-in-out 0s;

    animation: ${props =>
    props.animate ? `fadeInCarouselArrow ${INITIAL_ANIMATION_DURATION}s ease-out` : 'none'};
    
    
    &:hover {
      background: color-mix(in srgb, ${COLORS.DARK_GRAY}, white 10%);
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }


    @keyframes fadeInCarouselArrow {
      0%, 35% {
        transform: translateY(10px);
        opacity: 0;
      }
      50% {
        transform: translateY(0px);
        opacity: 1;
      }

      77.5%, 92.5% {
        transform: translateX(10px);
      }
      70%, 85%, 100% {
        transform: translateX(0px);

      }
    }

    > svg {
      ${props => props.back && 'transform: rotate(180deg)'};
    }
  `}
`;
