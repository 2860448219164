import { Component } from 'react';
import PropTypes from 'prop-types';


export class RouterResetFocus extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.activeElement.blur();
      document.body.focus();
    }
  }

  render = () => null;
}
