import styled from 'styled-components';
import { Link as LinkComponent } from '../../../../components/link/link.component';
import { colors, fonts } from '../../../../theme/styled';
import ArrowDownImage from '../../../../images/arrow-expand-down.svg';
import { getImageSource } from '../../../../utils/image';
import { getDevicePixelRatio } from '../../../../theme/media';

export const Link = styled(LinkComponent)`
  padding: 14px 24px;
  display: flex;
  align-items: center;

  &.hovered {
    background-color: ${colors.languageOptionHover};
  }
`;

export const Icon = styled.div`
  width: 44px;
  height: 44px;
  background-image: url(${props => getImageSource(props.image, 44 * getDevicePixelRatio())});
  background-size: cover;
  margin-right: 4px;
`;

export const Label = styled.p`
  font-size: 14px;
  font-family: ${fonts.smallFont};
  line-height: 1.57;
  color: ${colors.white};
`;

export const ActiveLanguage = styled.div`
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowDown = styled.div`
  width: 44px;
  height: 44px;
  background-image: url(${ArrowDownImage});
  background-size: cover;
`;
