import { useEffect, useState } from 'react';

/*
 * Determines whether an element is on screen. Adapted from SPB.
 * @param ref - A RefObject to check if on screen
 * @param margin - Number of pixels of ref required to be considered "on screen"
 * @param threshold - Percent of element visible needed to trigger (0 = 1px, 1= 100%)
 * @param delay - Delay once element enters viewport before setting "on screen" to true
 * @param shoudStayOnOnceOn - Determines whether the element should be considered "on screen" perpetually once it has entered the viewport
 */
export const useOnScreen = (
  ref,
  margin = 0,
  threshold = 0,
  delay = 0,
  shouldStayOnOnceOn = false,
) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const [observer, setObserver] = useState(null);
  const rootMargin = typeof margin === 'string' ? margin : `${margin.toString()}px`;

  const startObserving = () => {
    if (ref.current) {
      if (!observer) {
        const newObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting || !shouldStayOnOnceOn) {
              setTimeout(() => setIntersecting(entry.isIntersecting), delay);
            }
          },
          {
            rootMargin,
            threshold,
          },
        );
        newObserver.observe(ref.current);
        setObserver(newObserver);
      } else {
        observer.unobserve(ref.current);
        observer.observe(ref.current);
      }
    }
  };

  // Re-initialize observer if component has mounted without a window reload
  useEffect(() => {
    if (document.readyState === 'complete') {
      // Add small timeout to ensure the root bounds are set before we start observing
      setTimeout(() => {
        startObserving();
      }, 1);
    }
  }, []);

  // Re-initialize observer if window has reloaded or been resized
  useEffect(() => {
    window.addEventListener('load', startObserving);
    window.addEventListener('resize', startObserving);
    return () => {
      window.removeEventListener('load', startObserving);
      window.removeEventListener('resize', startObserving);
    };
  }, [isIntersecting]);

  return isIntersecting;
};
