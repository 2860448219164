import React from 'react';

const LazyWideImageDonateSection = React.lazy(() => import('./wideImageDonateSection.container'));

const SuspendedWideImageDonateSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyWideImageDonateSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedWideImageDonateSection;
