import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Page } from '../../layouts/page';
import { ROUTES } from '../../constants';

const noSlash = url => url.replace(/^\//gi, '');

export class NotFoundHandler extends PureComponent {
  static propTypes = {
    location: PropTypes.any,
    isNotFound: PropTypes.bool,
    setNotFound: PropTypes.func.isRequired,
    children: PropTypes.any,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.props.setNotFound(false);
    }
  }

  renderNotFound = () => <Page slug={noSlash(ROUTES.notFound.url)} />;

  renderContent = () => this.props.children;

  render = () => this.props.isNotFound ? this.renderNotFound() : this.renderContent();
}
