/*
 * This component loads all providers children need to access the general site environment
 */

import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';

import { ReduxProvider } from './ReduxProvider';
import { IntlProvider } from './IntlProvider';
import browserHistory from '../../../utils/history';
import { SectionViewportContext } from '../../../components/contentManager/pageSection/pageSection.component';

import { PlatformDetectorProvider } from '../../../components/platformDetector/platformDetector.component';
import expose from '../../../utils/expose';
expose({ browserHistory });

export const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <SectionViewportContext.Provider value={{ isActive: true }}>
        <PlatformDetectorProvider>
          <BrowserRouter history={browserHistory}>
            <IntlProvider>
              <React.Fragment>{children}</React.Fragment>
            </IntlProvider>
          </BrowserRouter>
        </PlatformDetectorProvider>
      </SectionViewportContext.Provider>
    </ReduxProvider>
  );
};

Providers.propTypes = {
  children: PropTypes.any,
};
