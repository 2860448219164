import styled, { css } from 'styled-components';

export const FixedWrap = styled.div`
  ${props =>
    props.isFixed &&
    css`
      display: block;
      position: fixed !important;
      width: 100%;
      top: 0;
      left: 0;
    `}
`;
