import styled from 'styled-components';
import { H2 } from '../../theme/layout';
import { Link as LinkComponent } from '../link/link.component';

export const Icon = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: opacity 0.1s ease-in-out;
  opacity: ${(props) => props.hasActiveIcon ? 1 : 0.68};
  
  &.hovered {
    opacity: ${(props) => props.hasActiveIcon ? 0 : 1};
  }
`;

export const IconActive = styled(Icon)`
  z-index: 1;
  display: ${(props) => props.hasActiveIcon ? 'block' : 'none'};
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  position: relative;
`;

export const Title = styled(H2)`
  margin-right: 25px;
`;

export const IconListItem = styled.li`
  margin-left: 12px;
  
  &:first-child {
    margin-left: 0;
  }
`;

export const Link = styled(LinkComponent)`
  display: block;
`;
