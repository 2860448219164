import React from 'react';

const LazyLinksSection = React.lazy(() => import('./linksSection.container'));

const SuspendedLinksSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyLinksSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedLinksSection;
