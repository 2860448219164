import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './threeUpImpactStats.styles';
import { IMPACT_COLOR_THEME_LABEL } from './threeUpImpactStats.styles';
import { InnerContainer, Pad } from '../styles/homePageStyles';
import { StatBlock } from './statBlock';

const ThreeUpImpactStats = ({ statBlocks, colorTheme }) => (
  <Styled.Section>
    <Pad>
      <InnerContainer>
        <Styled.ThreeUpContainer>
          {statBlocks.map(({ id, ...props }) => (
            <StatBlock key={id} colorTheme={colorTheme} {...props}></StatBlock>
          ))}
        </Styled.ThreeUpContainer>
      </InnerContainer>
    </Pad>
  </Styled.Section>
);

ThreeUpImpactStats.propTypes = {
  statBlocks: PropTypes.arrayOf(StatBlock),
  colorTheme: PropTypes.oneOf(Object.values(IMPACT_COLOR_THEME_LABEL)),
};

export default ThreeUpImpactStats;
