/*
 * index.js
 *
 * This is the entry file for the main application, only polyfills ans setup
 * code.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './polyfills';

import { THW } from './apps/thw';

if (process.env.REACT_APP_WITH_A11Y === 'true') {
  const a11y = require('react-a11y').default;
  a11y(React, ReactDOM, {
    rules: {
      'click-events-have-key-events': 'warn',
      'aria-role': 'warn',
      'img-uses-alt': 'warn',
      'tabindex-no-positive': 'warn',
      'tabindex-uses-button': 'warn',
      'onclick-uses-role': 'warn',
      'hidden-uses-tabindex': 'warn',
      'no-hash-ref': 'warn',
      'no-onchange': 'warn',
      'no-access-key': 'warn',
      'mouse-events-have-key-events': 'warn',
      'label-has-for': 'warn',
      'interactive-supports-focus': 'warn',
      'img-redundant-alt': ['warn', ['image', 'photo', 'foto', 'bild']],
    },
  });
}

ReactDOM.render(<THW />, document.getElementById('app'));
