import styled from 'styled-components';
import { colors, fonts, fontWeights } from '../../theme/styled';
import { media } from '../../theme/media';


export const Quote = styled.blockquote`
  margin: 0;
  position: relative;
  padding-top: 55px;
  margin-bottom: 16px;
  font-size: 28px;
  font-family: ${fonts.secondFont};
  font-weight: ${fontWeights.normal};
  font-style: italic;
  line-height: 1.43;
  color: ${colors.darkGrey};
  letter-spacing: -0.5px;

  ${media.tabletLarge`
    font-size: 32px;
    padding-top: 51px;
    line-height: 1.38;
    letter-spacing: -1px;
    margin-bottom: 24px;
  `}
`;

export const QuotationMark = styled.div`
  position: absolute;
  top: -11px;
  left: 0;
  color: ${colors.yellow};
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  font-style: normal;
  font-size: 84px;
  line-height: 1.05;
  letter-spacing: 1px;
`;
