import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './framedContent.styles';
import { OuterContainer as StyledOuterContainer } from '../styles/homePageStyles';
import { FormattedA, Link } from '../link';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';
import { LINK_TEXT_COLOR_LABEL } from '../link/link.styles';

const getBadgeImage = badges => {
  const length = badges.length;
  const badgeImages = [];

  for (let i = 0; i < length; i++) {
    badgeImages.push(
      <FormattedA href={badges[i].url.url} key={`${i}-${badges[i].image.imageFile}`}>
        <Styled.BadgeImage src={badges[i].image.imageFile} alt={badges[i].imageAlt} />
      </FormattedA>,
    );
  }

  return badgeImages;
};

const BadgeContainer = ({ featuredBadge, badges }) => {
  return (
    <Styled.BadgeContainer>
      <Styled.FeaturedBadgeContainer>
        <FormattedA
          href={featuredBadge.url.url}
          key={`featured-badge-${featuredBadge.image.imageFile}`}
        >
          <Styled.BadgeImage
            src={featuredBadge.image.imageFile}
            alt={featuredBadge.imageAlt}
            large
          />
        </FormattedA>
      </Styled.FeaturedBadgeContainer>
      <div>{getBadgeImage(badges)}</div>
    </Styled.BadgeContainer>
  );
};

BadgeContainer.propTypes = {
  featuredBadge: PropTypes.object.isRequired,
  badges: PropTypes.array.isRequired,
};

const FramedContent = ({ cardContent, linkTitle, linkUrl, cardTitle, badges, featuredBadge }) => {
  return (
    <PlatformDetector>
      {({ isMobile }) => (
        <Styled.Pad>
          <StyledOuterContainer>
            <Styled.Card>
              <Styled.TextContent>
                <h4>{cardTitle}</h4>
                {isMobile && <BadgeContainer featuredBadge={featuredBadge} badges={badges} />}
                <p>{cardContent}</p>
                <Link text={linkTitle} url={linkUrl} fontColor={LINK_TEXT_COLOR_LABEL.DARK_GRAY} />
              </Styled.TextContent>
              {!isMobile && (
                <React.Fragment>
                  <Styled.Divider />
                  <BadgeContainer featuredBadge={featuredBadge} badges={badges} />
                </React.Fragment>
              )}
            </Styled.Card>
          </StyledOuterContainer>
        </Styled.Pad>
      )}
    </PlatformDetector>
  );
};

FramedContent.propTypes = {
  featuredBadge: PropTypes.object.isRequired,
  cardContent: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired,
};

export default FramedContent;
