import styled from 'styled-components';
import { FONTS, BUTTON_COLOR, BUTTON_COLOR_LABEL } from '../../styles/homePageStyles';
import { FormattedA } from '../../link';

export const BUTTON_ARRAY_COLOR = {
  [BUTTON_COLOR_LABEL.BLUE]: BUTTON_COLOR[BUTTON_COLOR_LABEL.BLUE],
  [BUTTON_COLOR_LABEL.GREEN]: BUTTON_COLOR[BUTTON_COLOR_LABEL.GREEN],
};

export const StyledButton = styled.div`
  position: relative;
  display: flex;
  align-items: ${props => (props.withEmbellishment ? 'baseline' : 'center')};

  padding: ${props => (props.withEmbellishment ? '10px 28px 17px 20px' : '17px 28px 17px 24px')};
  width: fit-content;
  height: 52px;

  color: ${props => props.textColor};
  font-size: 1rem;
  font-family: ${FONTS.TITLE_FONT};

  border: none;
  cursor: pointer;

  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%);
`;

export const LinkContainer = styled(FormattedA)`
  ${StyledButton} {
    background: ${props => props.backgroundColor};
  }

  &:hover {
    ${StyledButton} {
      transition: background 0.2s ease-in-out 0s;
    }
  }

  &:hover,
  &:focus {
    ${StyledButton} {
      background: color-mix(in srgb, ${props => props.backgroundColor}, black 20%);
    }
  }
`;

export const Embellishment = styled.span`
  position: relative;
  top: 6px;

  padding-right: 12px;

  svg {
    width: 18px;
  }
`;
