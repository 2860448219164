import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import compose from 'ramda/es/compose';
import { injectIntl } from 'react-intl';
import { Curtain } from './curtain.component';
import { AccessoryActions } from '../../reducers/accessories/accessories.redux';
import { selectCurtainContent } from '../../reducers/accessories/accessories.selectors';

const mapStateToProps = createStructuredSelector({
  contentEntry: selectCurtainContent,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeCurtain: AccessoryActions.closeCurtain,
    },
    dispatch,
  );

const Connected = compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(Curtain);

export default props => {
  const routerLocation = useLocation();
  return <Connected {...props} location={routerLocation && routerLocation.pathname} />;
};
