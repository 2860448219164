import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Section } from './genericArticleSection.styles';
import { ContentContext, ContentContextType } from '../../../components/contentContext';

const SECOND_ELEMENT_PAGE_INDEX = 1;

/**
 * Wrapper for generic article section component.
 * It adds appropriate paddings for the section depending on whether it is placed inside article or on other pages.
 **/
export class GenericArticleSection extends PureComponent {
  /**
   * PropTypes
   * @property children
   * @property isHeroPresent {bool} - injected from redux store,
   *           specifies whether the hero section is visible on the page
   * @property index {number} - order of the section on the page
   **/
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    entryId: PropTypes.string.isRequired,
    componentName: PropTypes.string,
    children: PropTypes.any,
    isHeroPresent: PropTypes.bool,
    index: PropTypes.number,
  };

  static contextType = ContentContext;

  render() {
    const {
      children,
      isHeroPresent,
      index,
      ...otherProps
    } = this.props;
    return (
      <Section
        {...otherProps}
        isFirstSectionBelowHero={isHeroPresent && index === SECOND_ELEMENT_PAGE_INDEX}
        insideArticle={this.context === ContentContextType.ARTICLE}
      >
        {children}
      </Section>
    );
  }
}
