import styled from 'styled-components';
import { fonts, fontWeights, colors } from './styled';

export const Wrapper = styled.div`
  max-width: 1302px;
  margin: 0 auto;
`;

/**
 * Generic container element with full-width style and with flex-aligned children
 **/
export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.row ? 'row' : 'column'};
  flex: ${(props) => props.flex};
  width: 100%;
  align-items: center;
  position: relative;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: ${(props) => props.row ? 'row' : 'column'};
`;

export const H6 = styled.h6`
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  color: ${colors.darkGrey};
`;

export const H5 = styled.h5`
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  color: ${colors.darkGrey};
`;

export const H4 = styled.h4`
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  color: ${colors.darkGrey};
`;

export const H3 = styled.h3`
  font-family: ${fonts.titleFont};
  color: ${colors.darkGrey};
`;

export const H2 = styled.h2`
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  color: ${colors.darkGrey};
`;

export const H1 = styled.h1`
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  color: ${colors.darkGrey};
`;
