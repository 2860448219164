import expose from '../../utils/expose';
export { default as THW } from './thw.container';

expose({
  version: process.env.VERSION,
  build: process.env.BUILD_DATE,
  changeLog: process.env.CHANGE_LOG,
  locale: process.env.REACT_APP_LOCALE,
  branch: process.env.GIT_BRANCH,
});
