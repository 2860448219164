import { createActions, createReducer } from 'reduxsauce';
import { Record, Map, List, fromJS } from 'immutable';

export const HeaderStyle = {
  // logo only, no menu, on top of the page content
  MINIMAL: 'minimal',
  // pushes the page content down
  RELATIVE: 'relative',
  // on top of the page content
  ABSOLUTE: 'absolute',
};

export const { Types: MenuTypes, Creators: MenuActions } = createActions({
  fetch: [],
  fetchSuccess: ['items'],
  fetchError: ['payload'],
  setHeaderStyle: ['headerStyle'],
  setMenuHidden: ['menuHidden'],
  setMenuOpened: ['opened'],
  setCategoryMenuVisible: ['isVisible'],
}, { prefix: 'MENU_' });

const MenuRecord = new Record({
  data: Map({
    items: List(),
    form: Map(),
    linksList: Map(),
    socialIcons: Map(),
    termsLinkList: Map(),
  }),
  headerStyle: HeaderStyle.RELATIVE,
  isMenuHidden: false,
  isOpened: false,
  isCategoryMenuVisible: false,
});

export const INITIAL_STATE = new MenuRecord({});

const fetchSuccessHandler = (state, { items }) => state.set('data', fromJS(items));

const handleHeaderStyleChanged = (state, { headerStyle }) => {
  return state.set('headerStyle', fromJS(headerStyle));
};
const handleMenuHidden = (state, { menuHidden }) => state.set('isMenuHidden', fromJS(menuHidden));
const handleMenuOpened = (state, { opened }) => state.set('isOpened', fromJS(opened));
const handleCategoryMenuVisible = (state, { isVisible }) => state.set('isCategoryMenuVisible', isVisible);

export const reducer = createReducer(INITIAL_STATE, {
  [MenuTypes.FETCH_SUCCESS]: fetchSuccessHandler,
  [MenuTypes.SET_HEADER_STYLE]: handleHeaderStyleChanged,
  [MenuTypes.SET_MENU_HIDDEN]: handleMenuHidden,
  [MenuTypes.SET_MENU_OPENED]: handleMenuOpened,
  [MenuTypes.SET_CATEGORY_MENU_VISIBLE]: handleCategoryMenuVisible,
});
