import styled from 'styled-components';
import { GenericArticleSection, wideSectionStyle } from '../styles';
import { ImageWithTextItem } from '../../../components/imageWithTextList/imageWithTextItem';
import { Quote as BlockQuote } from '../../../components/text/text.styles';
import { media } from '../../../theme/media';

export const Container = styled(GenericArticleSection)`
  ${wideSectionStyle};

  ${media.tablet`
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const Quote = styled(ImageWithTextItem)`
  ${BlockQuote} {
    max-width: 568px;

    ${media.tabletLarge`
      max-width: 619px;
    `};

    ${media.desktop`
      max-width: 640px;
    `};
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

