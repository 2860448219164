import styled from 'styled-components';
import { Image as ResponsiveImage } from '../../image/image.component';
import { Text } from '../../text';
import { H3 } from '../../../theme/layout';
import { sizes, media, noTouchHover } from '../../../theme/media';
import { colors, fontWeights, fonts } from '../../../theme/styled';
import { Link as LinkComponent } from '../../link/link.component';
import { text } from '../../../theme/typography';

export const PhotoContainer = styled.span`
  display: flex;
  justify-content: center;
  margin-bottom: 36px;

  ${media.tablet`
    margin-bottom: 40px;
  `}

  ${media.tabletLarge`
    margin-bottom: 0;
  margin-right: 20px;
  `}

  ${media.desktop`
    margin-right: ${props => props.narrow ? 40 : 90}px;
  `}
`;

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    max-width: 686px;
    margin-bottom: 100px;
  `}

  ${media.tabletLarge`
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;

    &:nth-child(2n) {
      flex-direction: row-reverse;

      ${PhotoContainer} {
        margin-left: 20px;
        margin-right: 0;
      }
    }
  `}

  ${media.desktop`
    margin-bottom: 178px;

    &:nth-child(2n) {
      ${PhotoContainer} {
        margin-left: ${props => props.narrow ? 40 : 90}px;
      }
    }
  `}
`;

export const IMAGE_SIZES = {
  [sizes.mobile]: 220,
  [sizes.desktop]: 280,
};

export const Image = styled(ResponsiveImage)`
  width: 220px;
  height: 220px;
  border-radius: 50%;

  ${media.desktop`
    width: 280px;
    height: 280px;
  `}
`;

export const Content = styled.span``;

export const Title = styled(H3)`
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 1px;
  margin-bottom: 28px;
  color: ${colors.darkGrey};
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};

  ${media.tabletLarge`
    font-size: 22px;
    line-height: normal;
    margin-bottom: 36px;
    letter-spacing: 0.2px;
  `}
`;

export const TextBody = styled(Text)`
  color: ${colors.darkGrey};

  & > p, h3:not(${Title}) {
    ${text};
  }

  h3:not(${Title}) {
    font-weight: ${fontWeights.bold};
  }

  blockquote + p {
    font-size: 14px;
    font-family: ${fonts.smallFont};
    line-height: 1.57;
    font-style: normal;
    font-weight: ${fontWeights.normal};
  }

  a {
    text-decoration: underline;
    transition: color 0.2s ease-out;

    ${noTouchHover`
      color: ${colors.accessibilityYellow};
    `}
  }
`;

export const Footer = styled.footer`
  font-size: 0;
`;

export const QuoteImage = styled.img`
  margin-top: 8px;
  max-height: 48px;
  max-width: 180px;

  ${media.tabletLarge`
    margin-top: 16px;
  `};
`;

export const QuoteLink = styled(LinkComponent)`
  display: inline-block;
  font-size: 0;
`;

export const ShareWrapper = styled.div`
  margin-top: 34px;
  ${media.tablet`
    margin-top: 20px;
  `};
`;
