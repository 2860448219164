/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { Section, COLORS, TEXT_COLOR, RESPONSIVE_BOTTOM_MARGIN } from '../styles/homePageStyles';
import { media, sizes } from '../../../theme/media';
import { Button } from '../button/button.styles';
import { ScrollingBlockWrapper as ThreeUpScrollingBlock } from '../threeUpScrollingBlock/threeUpScrollingBlock.styles';

export const SECTION_BACKGROUND_COLOR_LABEL = {
  WHITE: 'white',
  BLUE: 'blue',
  GREEN: 'green',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
};

const SECTION_BACKGROUND_COLOR = {
  [SECTION_BACKGROUND_COLOR_LABEL.WHITE]: COLORS.WHITE,
  [SECTION_BACKGROUND_COLOR_LABEL.BLUE]: COLORS.BLUE_ACCESSIBLE,
  [SECTION_BACKGROUND_COLOR_LABEL.GREEN]: COLORS.LIGHT_GREEN,
  [SECTION_BACKGROUND_COLOR_LABEL.RED]: COLORS.RED,
  [SECTION_BACKGROUND_COLOR_LABEL.TEAL]: COLORS.TEAL,
  [SECTION_BACKGROUND_COLOR_LABEL.YELLOW]: COLORS.YELLOW,
};

export const NOTCHES = {
  LEFT: 'left',
  CENTER: 'center',
};

const NOTCH_DEPTHS = {
  [NOTCHES.LEFT]: '10.5vw',
  [NOTCHES.CENTER]: '6.9vw',
};

export const Frame = styled(Section)`
  z-index: 1;
  width: 100%;
  background-image: ${({ bgImg }) => (bgImg ? `url(${bgImg})` : 'none')};
  background-color: ${({ bgColor }) => SECTION_BACKGROUND_COLOR[bgColor]};
  color: ${({ bgImg, bgColor }) => (bgImg ? COLORS.DARK_GRAY : TEXT_COLOR[bgColor])};
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    display: flex;
  `}

  ${media.tabletLarge`
    gap: 40px;
  `};

  ${({ notch }) => {
    switch (notch) {
      case NOTCHES.LEFT:
        return css`
          margin-top: -${NOTCH_DEPTHS[notch]};
          clip-path: polygon(0 5.4vw, 32.5% ${NOTCH_DEPTHS[notch]}, 100% 0, 100% 100%, 0 100%);
          padding: calc(${NOTCH_DEPTHS[notch]} + 75px) 0 30px;

          ${media.tabletLarge`
            padding: calc(${NOTCH_DEPTHS[notch]} + 100px) 0 90px;
          `};
        `;
      case NOTCHES.CENTER:
        return css`
          margin-top: calc(-${NOTCH_DEPTHS[notch]} - 0.1vw);
          clip-path: polygon(0 0, 50% ${NOTCH_DEPTHS[notch]}, 100% 0, 100% 100%, 0 100%);
          padding: calc(${NOTCH_DEPTHS[notch]} + 50px) 0 35px;

          ${media.tabletLarge`
            padding: calc(${NOTCH_DEPTHS[notch]} + 90px) 0 90px;
          `};
        `;
      default:
        return css`
          padding: 118px 0 35px;

          ${media.tabletLarge`
            padding: 125px 0;
          `};
        `;
    }
  }}

  > ${ThreeUpScrollingBlock} {
    margin-block: 75px 45px;

    ${media.tabletLarge`
      margin-block: 50px;
    `};

    :last-child {
      margin-block: 75px 95px;

      ${media.tabletLarge`
        margin-block: 50px 80px;
      `};
    }
  }

  :has(${ThreeUpScrollingBlock}:last-child) {
    padding-block-end: 0;

    > ${ThreeUpScrollingBlock}:last-child {
      @media screen and (max-width: ${sizes.tabletLarge + 'px'}) {
        margin-block: 60px 90px;
      }

      :last-child {
        ${media.tabletLarge`
          margin-block-end: ${RESPONSIVE_BOTTOM_MARGIN};
        `};
      }
    }
  }

  > ${Button} {
    margin: 30px 0;
    ${media.tabletLarge`
      margin: 0;
    `};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;
  ${media.tabletLarge`
    gap: 23px;
  `};
`;
