import styled from 'styled-components';
import { Section as SectionComponent } from '../../../components/section';
import { media } from '../../../theme/media';

const getPaddingTopOnMobile = ({ insideArticle, isFirstSectionBelowHero }) => {
  if (insideArticle) { return 20; }
  if (isFirstSectionBelowHero) { return 60; }

  return 30;
};

const getPaddingTop = ({ insideArticle, isFirstSectionBelowHero }) => {
  if (insideArticle) { return 30; }
  if (isFirstSectionBelowHero) { return 100; }

  return 50;
};

export const Section = styled(SectionComponent)`
  padding-top: ${getPaddingTopOnMobile}px;
  padding-bottom: ${props => props.insideArticle ? 20 : 30}px;
  margin-left: auto;
  margin-right: auto;
  
  ${media.desktop`
    padding-top: ${getPaddingTop}px;
    padding-bottom: ${props => props.insideArticle ? 30 : 50}px;
  `};
`;
