import React from 'react';

const LazyArticleListBackgroundSection = React.lazy(() =>
  import('./articleListBackgroundSection.container'),
);

const SuspendedArticleListBackgroundSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyArticleListBackgroundSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedArticleListBackgroundSection;
