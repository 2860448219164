import styled from 'styled-components';
import { FONTS, COLORS, TEXT_COLOR, Section, BUTTON_COLOR } from '../styles/homePageStyles';
import { Button as ButtonComponent } from '../button/button.styles';
import TickIconSrc from '../../../images/icon-tick-black.svg';
import { media } from '../../../theme/media';
import { TYPES } from './donationBox.component';
import { focusOutline } from '../../../theme/mixins';

const OPACITY_MASK = 'linear-gradient(to top, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, .8) 100%)';
// Altering the opacity mask on Firefox to be 90% to allow a smooth transition
const MOZ_OPACITY_MASK = 'linear-gradient(to top, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, .8) 100%)';

export const BoxContainer = styled(Section)`
  --theme-color: ${props => props.theme ? BUTTON_COLOR[props.theme] : COLORS.BLUE_ACCESSIBLE};
  --theme-font-color: ${props => props.themeBase ? TEXT_COLOR[props.themeBase] : COLORS.WHITE};

  margin-top: ${props => props.type === TYPES.TABS ? '0' : '92%'};
  margin-bottom: -1px;
  mask-image: ${props => props.type === TYPES.TABS ? 'none' : OPACITY_MASK};

  ${media.tablet`
    mask-image: none;
    margin-top: 0px;
    background-color: ${props => props.type === TYPES.TABS ? COLORS.WHITE : '#FFFFFFE6'};
  `}

  @-moz-document url-prefix() {
    mask-image: ${props => props.type === TYPES.TABS ? 'none' : MOZ_OPACITY_MASK};
  }
`;

export const DonationContainer = styled.div`
  background-color: ${COLORS.WHITE};
  padding: ${props => props.type === TYPES.TABS ? '30px 30px 36px' : '27px 30px'};
  margin-bottom: -1px;
  mask-image: ${props => props.type === TYPES.TABS ? 'none' : OPACITY_MASK};
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.type === TYPES.TABS ? '25px' : '26px'};

  ${media.tablet`
    padding: 35px 35px 40px;
  `}

  @-moz-document url-prefix() {
    mask-image: ${props => props.type === TYPES.TABS ? 'none' : MOZ_OPACITY_MASK};
  }
`;

export const TabContainer = styled.div`
  display: inline-flex;
  width: 100%;
`;

const TabBase = styled.button`
  font-family: ${FONTS.TITLE_FONT};
  font-size: .938rem;
  font-weight: 700;
  border: none;
  color: ${props => props.active ? 'var(--theme-font-color)' : COLORS.DARK_GRAY};
  justify-content: center;
  align-items: center;
  display: inline-flex;
  pointer-events: auto;
  cursor: pointer;
  width: 50%;
  height: 3em;
  background: ${props => props.active ? 'var(--theme-color)' : COLORS.LIGHT_GRAY};
  
  img {
    height: .8rem;
    margin: 0 5px 3px 0;
  }
`;

export const GiveOnceTab = styled(TabBase)`
`;

export const MonthlyTab = styled(TabBase)`
`;

export const TabsDescription = styled.div`
  font-size: .85rem;
  font-style: italic;
  font-weight: 400;
  font-family: ${FONTS.SECOND_FONT};
  color: ${COLORS.DARK_GRAY};
  margin-block: -4px 3px;

  ${media.tablet`
    margin: 0;
  `}
`;

// Checkbox
export const CheckboxContainer = styled.div`
  padding: 7px 0 26px 0;

  ${media.tablet`
    padding: 9px 0 24px 0;
  `}

`;

export const Label = styled.label`
  position: absolute;
  padding-left: 28px;
  cursor: pointer;
  font-family: ${FONTS.SMALL_FONT};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
  outline: none;

  &::selection {
    background-color: transparent;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 20px;
    height: 20px;
    border: 1px solid ${COLORS.DARK_GRAY};
    background-color: transparent;
    transition: background-color .2s, border-color .2s;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(${TickIconSrc});
    background-repeat: no-repeat;
    top: 4px;
    left: 5px;
    width: 10px;
    height: 8px;
    transition: opacity .2s;
  }

  ${media.tablet`
    font-size: 0.875rem;
    line-height: 1;
  `}
`;

export const Checkbox = styled.input`
  position: absolute;
  padding-left: 32px;
  opacity: 0;

  & + ${Label}:before {
    border-color: #B4B4B5;
    background-color: ${COLORS.WHITE};
  }

  &:not(:checked) + ${Label}:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + ${Label}:after {
    opacity: 1;
    transform: scale(1.5);
  }

  &:focus-visible + ${Label} {
    ${focusOutline}
  }
`;

export const Button = styled(ButtonComponent)`
  font-size: 1.25rem;
  
  img {
    width: 13px;
    margin: 0 0.5em 0.2em 0;
  }
`;
