import styled from 'styled-components';
import { H1 } from '../../theme/layout';

export const Header = styled(H1)`
  position: absolute;
  overflow: hidden;
  left: -100%;
  width: 0;
  height: 0;
  font-size: 0; 
`;
