import styled from 'styled-components';
import { GUTTER_RESPONSIVE, COLORS, Section as SectionComponent } from '../styles/homePageStyles';
import { media } from '../../../theme/media';

export { LINK_COLOR_LABEL as IMPACT_COLOR_THEME_LABEL } from '../link/link.styles';

export const Section = styled(SectionComponent)`
  padding: 83px 0 78px;
  background: ${COLORS.WHITE};

  ${media.tabletLarge`
    padding: 135px 0 152px;
  `};
`;

export const ThreeUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: ${GUTTER_RESPONSIVE};
  row-gap: 55px;
  color: ${COLORS.DARK_GRAY};

  ${media.tabletLarge`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  `}
`;
