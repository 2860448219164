import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import compose from 'ramda/es/compose';
import { PageMetaTags } from './pageMetaTags.component';
import {
  selectMetaImage, selectMetaTitle, selectMetaTwitterSite, selectGoogleTagManagerId,
} from '../../reducers/config/config.selectors';
import { selectLocalesLanguage } from '../../reducers/locales/locales.selectors';


const mapStateToProps = createStructuredSelector({
  defaultTitle: selectMetaTitle,
  defaultTwitterSite: selectMetaTwitterSite,
  defaultImage: selectMetaImage,
  language: selectLocalesLanguage,
  googleTagManagerId: selectGoogleTagManagerId,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({ }, dispatch);

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(PageMetaTags);
