import { useCallback, useEffect } from 'react';

export const useDragScroll = (node, setDragDirection, setDragging, enabled) => {
  const handleDrag = (e, moveListener, endListener, isTouch) => {
    if (!node) {
      return;
    }

    if (!enabled) {
      node.style.cursor = '';
      return;
    }

    const updateCursor = ele => {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
    };

    const resetCursor = ele => {
      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');
    };

    const enableVerticalScroll = () => null;

    const disableVerticalScroll = e => {
      e = e || window.event;
      if (e.preventDefault) {
        e.preventDefault();
      }
      e.returnValue = false;
    };

    const event = isTouch ? e.touches[0] : e;

    const startPos = {
      left: node.scrollLeft,
      x: event.clientX,
      y: event.clientY,
    };

    let dragDirection;

    const handleMove = e => {
      const event = isTouch ? e.touches[0] : e;
      const dx = event.clientX - startPos.x;
      const dy = event.clientY - startPos.y;
      // Don't accidentally swipe the carousel when scrolling the page
      if (Math.abs(dx) > 3 && Math.abs(dy) < Math.abs(dx) / 2) {
        setDragging(true);
        if (isTouch) {
          window.removeEventListener('touchmove', enableVerticalScroll, { passive: false });
          window.addEventListener('touchmove', disableVerticalScroll, { passive: false });
        }
        if (dx < 0) {
          dragDirection = 'right';
        } else {
          dragDirection = 'left';
        }
        node.scrollLeft = startPos.left - dx;
      }
      updateCursor(node);
    };

    const handleEnd = () => {
      setDragging(false);
      if (isTouch) {
        window.removeEventListener('touchmove', disableVerticalScroll, { passive: false });
        window.addEventListener('touchmove', enableVerticalScroll, { passive: false });
      }
      if (dragDirection) {
        setDragDirection(dragDirection);
      }
      document.removeEventListener(moveListener, handleMove);
      document.removeEventListener(endListener, handleEnd);
      resetCursor(node);
    };

    document.addEventListener(moveListener, handleMove);
    document.addEventListener(endListener, handleEnd);
  };

  const handleMouseDown = useCallback(e => handleDrag(e, 'mousemove', 'mouseup'), [node, enabled]);

  const handleTouchStart = useCallback(
    e => handleDrag(e, 'touchmove', 'touchend', true),
    [node, enabled],
  );

  useEffect(() => {
    if (!node) {
      return;
    }
    node.addEventListener('mousedown', handleMouseDown);
    node.addEventListener('touchstart', handleTouchStart);
    // eslint-disable-next-line consistent-return
    return () => {
      node.removeEventListener('mousedown', handleMouseDown);
      node.removeEventListener('touchstart', handleTouchStart);
    };
  }, [node, enabled]);
};
