import React from 'react';

const LazyPage = React.lazy(() => import('./page.container'));

const SuspendedPage = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyPage ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedPage;
