import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import compose from 'ramda/es/compose';
import { ContentfulComponent } from './contentfulComponent.component';
import { ComponentPageActions } from '../../reducers/page/componentPage/componentPage.redux';
import { selectContentfulComponent } from '../../reducers/page/componentPage/componentPage.selectors';


const mapStateToProps = createStructuredSelector({
  data: selectContentfulComponent,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchData: ComponentPageActions.fetchContentfulComponent,
}, dispatch);

const Connected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ContentfulComponent);

export default props => {
  const sysid = useParams();
  return <Connected {...props} sysid={sysid} />;
};
