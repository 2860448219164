import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Section,
  Figure,
  Container,
  VideoCta,
  VideoOverlay,
  IMAGE_SIZES,
} from './articleFigureBlock.styles';
import { identifiers, renderWhenNotNil } from '../../../utils/rendering';
import { getField } from '../../../utils/cmsData';
import { accessibleClickHandler } from '../../../utils/accessibility';
import messages from './articleFigureBlock.messages';
import { SectionBackground } from '../../../components/sectionBackground';

/**
 * Section with labeled figure.
 * It could display either video or image.
 **/
export class ArticleFigureBlock extends PureComponent {
  /**
   * PropTypes
   * @property image {object} - CMS image data object
   * @property videoId {string} - Youtube video ID
   * @property openVideo - redux action, opens video popup
   **/
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    entryId: PropTypes.string.isRequired,
    image: PropTypes.object,
    videoId: PropTypes.string,
    videoHost: PropTypes.string,
    openVideo: PropTypes.func.isRequired,
    sizes: PropTypes.object,
    index: PropTypes.number,
    intl: PropTypes.object.isRequired,
    backgroundPattern: PropTypes.object,
  };

  openVideo = () => {
    this.props.openVideo(this.props.videoId, this.props.videoHost);
  };

  renderVideoCta = renderWhenNotNil(() => (
    <VideoOverlay
      {...accessibleClickHandler(this.openVideo)}
      aria-label={this.props.intl.formatMessage(messages.play)}
    >
      <VideoCta />
    </VideoOverlay>
  ));

  render() {
    const { contentType, entryId, index, image, videoId, backgroundPattern } = this.props;
    return (
      <Section {...identifiers('ArticleFigureBlock', contentType, entryId)} index={index}>
        {backgroundPattern && (
          <SectionBackground
            mainColor={getField('mainColor', backgroundPattern)}
            mainHeight={0.7}
            secondaryColor={getField('secondaryColor', backgroundPattern)}
            backgroundImage={getField('backgroundImage', backgroundPattern)}
          />
        )}
        <Container>
          <Figure image={image} sizes={IMAGE_SIZES} padded={!!backgroundPattern}>
            {this.renderVideoCta(videoId)}
          </Figure>
        </Container>
      </Section>
    );
  }
}
