import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Caption,
  Credits,
  Description,
  Image } from './figure.styles';
import { getField } from '../../utils/cmsData';
import { Image as ResponsiveImage } from '../image/image.component';

export class Figure extends PureComponent {
  static propTypes = {
    image: PropTypes.object,
    children: PropTypes.any,
    className: PropTypes.string,
    sizes: PropTypes.object,
  };

  get description() {
    return getField('description', this.props.image);
  }

  get credit() {
    return getField('credits', this.props.image);
  }

  render() {
    const { image } = this.props;
    return (
      <Container className={this.props.className}>
        <Image>
          <ResponsiveImage
            sizes={this.props.sizes}
            alt={getField('imageAlt', image)}
            image={getField('image', image)}
          />
          {this.props.children}
        </Image>

        <Caption>
          {this.description && <Description>{this.description}</Description>}
          {this.credit && <Credits>{this.credit}</Credits>}
        </Caption>
      </Container>
    );
  }
}
