import { createActions, createReducer } from 'reduxsauce';
import { Record, fromJS } from 'immutable';
import config from '../../data/globalConfig';

export const { Types: ConfigTypes, Creators: ConfigActions } = createActions(
  { test: null },
  { prefix: 'CONFIG_' },
);

const ConfigRecord = new Record({
  config: fromJS({
    ...config.fields,
    contentType: config.sys.contentType.sys.id,
    entryId: config.sys.id,
  }),
});

export const INITIAL_STATE = new ConfigRecord({ });

export const reducer = createReducer(INITIAL_STATE, { });
