import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { colors, fonts } from '../styled';
import { entryAnimation, focusOutline, visuallyHidden } from '../mixins';
import { PAGE_LOADER_OPEN_CLASS } from '../../components/pageLoader/pageLoader.component';
import { ENTER_ANIMATION_CLASS_NAME } from '../../components/animatedEnter/animatedEnter.component';

const normalize = require('!raw-loader!normalize.css/normalize.css'); // eslint-disable-line

// eslint-disable-next-line
export const GlobalStyle = createGlobalStyle`
  ${normalize.default};

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:focus-visible {
    ${focusOutline};
  }

  body {
    background-color: ${colors.darkGrey};
    font-family: ${fonts.mainFont};
    overflow-y: ${props => (props.isMenuOpened ? 'hidden' : 'auto')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  #preApp {
    z-index: 999;
    width: 100%;
    position: sticky;
    top: 0;
    pointer-events: none;
    
    & > * {
      pointer-events: auto;
    }
  }

  #slideoutPanel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    z-index: 1000;
  }

  #app {
    -webkit-overflow-scrolling: touch;
    position: relative;
  }

  main[role=main] {
    min-height: 100vh;
    background-color: ${colors.white};
    overflow: hidden;
    position: relative;
    z-index: 3;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    appearance: none;
  }


  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }

  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ReactModal__Content:focus {
    outline: none;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .${PAGE_LOADER_OPEN_CLASS} {
    overflow-y: hidden;
  }

  .${ENTER_ANIMATION_CLASS_NAME} {
    ${entryAnimation};
  }

  .shopify-buy-frame--cart {
    max-width: 499px!important;
  }
  .shopify-buy-frame--modal.is-active {
    background: rgba(0,0,0,0.33)!important;
  }

  .visually-hidden {
    ${visuallyHidden}
  }
`;

GlobalStyle.propTypes = {
  headerStyle: PropTypes.string,
  isMenuOpened: PropTypes.bool,
  categoryNavLevel: PropTypes.number,
};
