import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { isMobile, isTablet } from '../../theme/media';

export class Collapse extends PureComponent {
  static propTypes = {
    component: PropTypes.any,
    children: PropTypes.any,
    visibilityDuration: PropTypes.number,
    location: PropTypes.object,
  };

  state = {
    collapsed: false,
    itemsAccessible: false,
    height: 0,
  };

  componentDidMount() {
    const height = this.props.component.current.offsetHeight;
    this.setHeight(height);
    this.collapseItem(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location) {
      if (
        this.props.location.pathname !== prevProps.location.pathname ||
        this.props.location.key !== prevProps.location.key
      ) {
        this.collapseItem(true);
        this.setItemsAccessible(false);
      }
    }
  }

  setHeight = (height) => {
    this.setState({ height });
  };

  setItemsAccessible = (option) => {
    if (option) {
      this.setState({ itemsAccessible: option });
    } else {
      clearTimeout(this.itemsAccessibleTimout);
      this.itemsAccessibleTimout = setTimeout(() => {
        this.setState({ itemsAccessible: option });
      }, this.props.visibilityDuration);
    }
  };

  collapseItem = (option) => {
    this.setState({ collapsed: option });
  };

  collapse = () => {
    this.collapseItem(!this.state.collapsed);

    if (this.props.visibilityDuration) {
      this.setItemsAccessible(!this.state.itemsAccessible);
    }
  };

  isSmallDevice = () => {
    return isMobile() || isTablet();
  };

  render() {
    const { collapsed, height, itemsAccessible } = this.state;

    return this.props.children({
      collapsed,
      height,
      collapse: this.collapse,
      itemsAccessible,
    });
  }
}
