import React from 'react';

const LazyParallaxTextsSection = React.lazy(
  () => import('./parallaxTextsSection.container'),
);

const SuspendedParallaxTextsSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyParallaxTextsSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedParallaxTextsSection;
