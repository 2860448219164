import styled from 'styled-components';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import add from 'ramda/es/add';
import { media } from '../../theme/media';

export const paddingBreakpoint = pipe(prop('maxWidth'), add(2 * 41));

export const SectionContainer = styled.section`
  position: relative;
  width: 100%;
  max-width: ${prop('maxWidth')}px;
  padding: 37px 24px;

  ${media.tablet`
    padding: 42px;
  `}

  ${media.desktop`
    padding: 75px 0;

    @media(max-width: ${paddingBreakpoint}px) {
      padding-left: 42px;
      padding-right: 42px;
    }
  `}
`;
