import styled from 'styled-components';

export const VideoPlayIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  transition: background 0.2s ease-in-out 0s;

  img {
    position: absolute;
    height: 25.5%;
    width: 25.5%;
    bottom: 2%;
    left: 1%;
  }

  &:has(img:hover) {
    background: rgba(0, 0, 0, 0.1);
  }
`;
