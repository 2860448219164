import React from 'react';

const LazyBanner = React.lazy(() => import('./banner.container'));

const SuspendedBanner = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyBanner ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedBanner;
