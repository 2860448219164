import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './colorChangeableDivider.styles';

const ColorChangeableDivider = ({ color }) => <Styled.Section color={color} />;

ColorChangeableDivider.propTypes = {
  color: PropTypes.oneOf(['blue', 'green']),
};

export default ColorChangeableDivider;
