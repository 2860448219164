import { useState, useEffect } from 'react';
import { matchWidth, sizes } from '../../../theme/media';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(matchWidth('max', sizes.tablet - 1));
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
