import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ colorHex = '#fff' }) => (
  <svg
    width="3.212mm"
    height="5.3658mm"
    version="1.1"
    viewBox="0 0 3.212 5.3658"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clipPath224">
        <path transform="translate(-1511.5 -5669.6)" d="M 0,9342.212 H 1618.65 V 0 H 0 Z" />
      </clipPath>
    </defs>
    <g transform="translate(-41.275 -132.03)">
      <path
        transform="matrix(.26458 0 0 -.26458 43.429 134.71)"
        d="m0 0-8.141-8.14 2-2 10.14 10.14-10.14 10.14-2-1.999z"
        clipPath="url(#clipPath224)"
        fill={colorHex || '#ffffff'}
      />
    </g>
  </svg>
);

Arrow.propTypes = {
  colorHex: PropTypes.string,
};

export default Arrow;
