import React from 'react';
import PropTypes from 'prop-types';
import { LINK_COLOR_LABEL, LINK_TEXT_COLOR_LABEL, StyledLink } from './link.styles';

const Link = ({ url, text, theme, arrow, fontColor, tabIndex, className }) => {
  return (
    <StyledLink
      className={className}
      href={url}
      theme={theme}
      arrow={arrow}
      fontColor={fontColor}
      tabIndex={tabIndex || 0}
    >
      {text}
      {arrow ? <span> »</span> : ''}
    </StyledLink>
  );
};

Link.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(LINK_COLOR_LABEL)),
  arrow: PropTypes.bool,
  fontColor: PropTypes.oneOf(Object.values(LINK_TEXT_COLOR_LABEL)),
  tabIndex: PropTypes.number,
};

Link.defaultProps = {
  theme: LINK_COLOR_LABEL.GREEN,
  arrow: true,
  fontColor: LINK_TEXT_COLOR_LABEL.WHITE,
  className: '',
};

export default Link;
