import React from 'react';

const LazyIframe = React.lazy(() => import('./iframe.container'));

const SuspendedIframe = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyIframe ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedIframe;
