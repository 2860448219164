import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './heroSlide.styles';
import { PlatformDetector } from '../../../platformDetector/platformDetector.component';
import { getDevicePixelRatio } from '../../../../theme/media';
import { getDynamicHeroImage } from '../../../../utils/image';
import { TextHighlight } from '../../textHighlight';
import { COLORS } from '../../styles/homePageStyles';
import { headerSize } from '../../../../theme/styled';

const parseHighlightedText = input => {
  const regex = /\*(.*?)\*/g;
  let currentIndex = 0,
    match;
  const result = [];

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(input)) !== null) {
    if (match.index > currentIndex) {
      result.push({ content: input.slice(currentIndex, match.index).trim(), highlighted: false });
    }
    result.push({ content: match[1].trim(), highlighted: true });
    currentIndex = regex.lastIndex;
  }

  if (currentIndex < input.length) {
    result.push({ content: input.slice(currentIndex).trim(), highlighted: false });
  }

  return result;
};

const HeroSlide = ({
  slideIndex,
  isActive,
  heading,
  text,
  link,
  colorTheme,
  heroImageDesktop,
  heroImageMobile,
  heroVideoDesktop,
  heroVideoMobile,
  preload,
  slideDuration,
  transitionDuration,
  navigationDirection,
  isMobile,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const videoRef = useRef(null);
  const highlightTimeout = useRef();

  const getDynamicHeroImageObject = activeResolution =>
    getDynamicHeroImage(activeResolution, heroImageDesktop, heroImageMobile, getDevicePixelRatio());

  const getVideoUrl = isMobile => {
    if (!isMobile) {
      return heroVideoDesktop.getIn(['fields', 'file', 'url']);
    }
    return heroVideoMobile.getIn(['fields', 'file', 'url']);
  };

  const isActiveAndLoaded = isActive && isLoaded;

  const getDynamicHeroMedia = (activeResolution, isMobile) => {
    const dynamicHeroImageObject = getDynamicHeroImageObject(activeResolution);
    if (heroVideoDesktop && heroVideoMobile) {
      return (
        <video
          loop={true}
          muted={true}
          autoPlay={true}
          controls=""
          playsInline={true}
          onLoadedData={() => setIsLoaded(true)}
          poster={dynamicHeroImageObject && dynamicHeroImageObject.src}
          ref={videoRef}
        >
          <source src={getVideoUrl(isMobile)} type="video/mp4" />
        </video>
      );
    }
    return (
      <img
        src={dynamicHeroImageObject.src}
        onLoad={() => setIsLoaded(true)}
        alt={dynamicHeroImageObject.alt || ''}
        loading={isFetched ? 'eager' : 'lazy'}
      />
    );
  };

  const getDynamicHeroMediaCredit = isMobile => {
    if (heroVideoDesktop && heroVideoMobile) {
      if (!isMobile) {
        return heroVideoDesktop.getIn(['fields', 'description']);
      }
      return heroVideoMobile.getIn(['fields', 'description']);
    }
    if (heroImageMobile && heroImageDesktop) {
      if (!isMobile) {
        return heroImageMobile.getIn(['fields', 'description']);
      }
      return heroImageDesktop.getIn(['fields', 'description']);
    }
    return null;
  };

  useEffect(() => {
    if (isActive) {
      if (videoRef && videoRef.current) {
        videoRef.current.load();
      }
      highlightTimeout.current = setTimeout(() => {
        setIsHighlighted(true);
      }, transitionDuration * 1000);
    } else {
      // Reset initial highlight state
      // Pause off screen videos
      highlightTimeout.current = setTimeout(() => {
        setIsHighlighted(false);
        if (videoRef && videoRef.current) {
          videoRef.current.pause();
        }
      }, transitionDuration * 1000);
    }
    return () => {
      clearTimeout(highlightTimeout.current);
    };
  }, [isActive]);

  useEffect(() => {
    if (isActive || preload) {
      setIsFetched(true);
    }
  }, [preload, isActive]);

  return (
    <Fragment>
      {isActive && !isLoaded && slideIndex > 0 && (
        <Styled.LoadingSpinner color="white" size="2rem" />
      )}
      <PlatformDetector>
        {({ activeResolution }) => (
          <Styled.Slide
            isSlideActive={isActiveAndLoaded}
            transitionDuration={transitionDuration}
            paddingTop={headerSize[isMobile ? 'mobile' : 'desktop']}
          >
            <Styled.SlideContent>
              <Styled.InnerContainer>
                <Styled.Content
                  navigationDirection={navigationDirection}
                  isSlideActive={isActiveAndLoaded}
                  transitionDuration={transitionDuration}
                >
                  {heading && (
                    <Styled.Heading>
                      {parseHighlightedText(heading).map((segment, i) =>
                        segment.highlighted ? (
                          <React.Fragment key={segment + i}>
                            <TextHighlight
                              isActive={isHighlighted}
                              highlightTextColor={COLORS.DARK_GRAY}
                              isFirstNode={i === 0}
                              theme={colorTheme}
                            >
                              {segment.content}
                            </TextHighlight>
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={segment + i}>
                            {segment.content}&nbsp;{' '}
                          </React.Fragment>
                        ),
                      )}
                    </Styled.Heading>
                  )}
                  {(text || link) && (
                    <Styled.Text>
                      <Styled.PseudoFuzzyBackground theme="dark" blur={40}>
                        {text ? `${text} ` : ''}
                        {link && link.text && (
                          <Styled.CTA
                            text={link.text}
                            url={link.url}
                            theme={colorTheme}
                            tabIndex={isActive ? 0 : -1}
                          />
                        )}
                      </Styled.PseudoFuzzyBackground>
                    </Styled.Text>
                  )}
                </Styled.Content>
              </Styled.InnerContainer>
            </Styled.SlideContent>
            {isFetched && (
              <React.Fragment>
                <Styled.BackgroundMedia
                  isSlideActive={isActiveAndLoaded}
                  transitionDuration={slideDuration * 0.8}
                >
                  {getDynamicHeroMedia(activeResolution, isMobile)}
                </Styled.BackgroundMedia>
                {getDynamicHeroMediaCredit(isMobile) && (
                  <Styled.MediaCredit>
                    <Styled.MediaCreditInner>
                      {getDynamicHeroMediaCredit(isMobile)}
                    </Styled.MediaCreditInner>
                  </Styled.MediaCredit>
                )}
              </React.Fragment>
            )}
          </Styled.Slide>
        )}
      </PlatformDetector>
    </Fragment>
  );
};

HeroSlide.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  preload: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  colorTheme: PropTypes.string.isRequired,
  heroImageDesktop: PropTypes.object.isRequired,
  heroImageMobile: PropTypes.object,
  heroVideoDesktop: PropTypes.object,
  heroVideoMobile: PropTypes.object,
  isActive: PropTypes.bool,
  slideDuration: PropTypes.number.isRequired,
  transitionDuration: PropTypes.number.isRequired,
  navigationDirection: PropTypes.oneOf(['forwards', 'backwards']),
  isMobile: PropTypes.bool.isRequired,
};

HeroSlide.defaultProps = {
  isActive: false,
  navigationDirection: 'forwards',
};

export default HeroSlide;
