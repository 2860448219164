/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

const MendedHeart = ({ colorHex = '#fff', heightAndWidth = 22 }) => (
  <svg
    width={`${heightAndWidth}px`}
    height={`${heightAndWidth}px`}
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="01_Homepage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="THL_Favicon_Transparent"
        transform="translate(-5.000000, -5.000000)"
        fill={colorHex || '#F53814'}
      >
        <g id="THL-heart">
          <path d="M19.2629108,5 L19.2441315,5.03158344 L19.2313274,5 L15.9116517,8.19504908 L12.663679,5.00256082 L8.45710627,5.00256082 L5,8.38625694 L5,15.789586 L15.8928724,26.4801536 L27,15.7545881 L27,14.3401622 L27,12.7089202 L27,8.43235169 L23.4276569,5.00256082 L19.2629108,5 Z M19.7409304,6.69526248 L22.8975672,6.69526248 L22.9137857,6.7106274 L20.3162612,12.5245412 L17.0349979,9.29876227 L19.7409304,6.69526248 Z M15.3115664,23.7264191 L12.8233035,21.288519 L15.1160905,16.0209134 L17.6538626,18.4827145 L15.3115664,23.7264191 Z M13.9381135,14.8779343 L11.6188647,20.1088348 L8.83525395,17.3807085 L12.6175843,13.5975245 L13.9381135,14.8779343 Z M18.3358942,16.9573197 L13.5821596,12.2812633 L14.8506189,9.30729834 L16.3862569,10.819889 L19.6393513,14.0396927 L18.3358942,16.9573197 Z M9.06060606,6.69526248 L12.1993171,6.69526248 L13.7119078,8.18565941 L12.4255228,11.1434059 L8.41869398,7.3422962 L9.06060606,6.69526248 Z M6.68843363,9.08536065 L7.24498506,8.52454119 L11.501067,12.5134443 L7.7238583,16.2923602 L6.68843363,15.2765685 L6.68843363,9.08536065 Z M25.3038839,15.2483995 L17.3755869,22.8190354 L20.7780623,15.1673069 L20.7925736,15.1818182 L20.787452,15.1451131 L21.1451131,14.3401622 L21.4157064,14.3401622 L25.3038839,14.3401622 L25.3038839,15.2483995 Z M25.3038839,12.7089202 L21.8706786,12.7089202 L24.0448143,7.82116944 L25.3038839,9.05633803 L25.3038839,12.7089202 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);

MendedHeart.propTypes = {
  colorHex: PropTypes.string,
  heightAndWidth: PropTypes.number,
};

export default MendedHeart;
