/**
 * Decorator for Global Config.
 *
 * @module GlobalConfigDecorator
 **/

import Decorator from './decorator';
import { getField } from '../utils/cmsData';

export default class GlobalConfigDecorator extends Decorator {
  get googleTagManagerId() {
    return getField('googleTagManagerId', this.data);
  }
}
