import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './storyBlock.styles';
import { BUTTON_COLOR_LABEL, H3 } from '../../styles/homePageStyles';
import { Tally } from '../tally';
import { getImgProps } from '../../../../utils/cmsData';

export const BUTTONS = {
  tally: 'signatureTally',
  cta: 'ctaButton',
};

export const EMBELLISHMENTS = {
  progressTag: 'progressTag',
  videoPlayIcon: 'videoPlayIcon',
};

const StoryButton = ({ button, tallyPetitionId, tallyFallbackText, ctaText, ctaColor }) => {
  switch (button) {
    case BUTTONS.tally:
      return <Tally petitionId={tallyPetitionId} fallbackText={tallyFallbackText} />;
    case BUTTONS.cta:
      return ctaText.trim() ? (
        <Styled.CTAButton color={ctaColor}>{ctaText}</Styled.CTAButton>
      ) : null;
    default:
      return null;
  }
};

const StoryEmbellishment = ({ embellishment, progressTagText }) => {
  switch (embellishment) {
    case EMBELLISHMENTS.videoPlayIcon:
      return <Styled.VideoEmbellishment />;
    case EMBELLISHMENTS.progressTag:
      return progressTagText.trim() ? (
        <Styled.ProgressTag>{progressTagText}</Styled.ProgressTag>
      ) : null;
    default:
      return null;
  }
};

const StoryBlock = ({
  id,
  link,
  image,
  headline,
  text,
  button,
  tallyPetitionId,
  tallyFallbackText,
  ctaText,
  ctaColor,
  embellishment,
  progressTagText,
  scrolledStory,
  handleKeyUp,
  setScrollOffset,
}) => {
  const containerRef = useRef(null);
  const { src, alt } = image.src ? image : getImgProps(image);

  useEffect(() => {
    if (scrolledStory === id && containerRef.current) {
      let storyOffsetLeft = containerRef.current.offsetLeft;
      if (storyOffsetLeft === 0 || storyOffsetLeft === 1) {
        storyOffsetLeft = containerRef.current.offsetParent.offsetLeft;
      }
      setScrollOffset(storyOffsetLeft);
    }
  }, [scrolledStory]);

  return (
    <Styled.StoryBlockContainer ref={containerRef}>
      <Styled.LinkContainer href={link} draggable={false} onKeyUp={handleKeyUp}>
        <Styled.ImageContainer>
          <Styled.ScaledImageWrapper>
            <Styled.StoryImage src={src} alt={alt} />
          </Styled.ScaledImageWrapper>
          {embellishment && <StoryEmbellishment {...{ embellishment, progressTagText }} />}
        </Styled.ImageContainer>
        <H3>{headline}</H3>
        <Styled.StoryText>{text}</Styled.StoryText>
        {button && (
          <StoryButton {...{ button, tallyPetitionId, tallyFallbackText, ctaText, ctaColor }} />
        )}
      </Styled.LinkContainer>
    </Styled.StoryBlockContainer>
  );
};

StoryButton.propTypes = {
  button: PropTypes.oneOf([...Object.values(BUTTONS), null]),
  tallyPetitionId: PropTypes.string,
  tallyFallbackText: PropTypes.string,
  ctaText: PropTypes.string,
  ctaColor: PropTypes.oneOf([BUTTON_COLOR_LABEL.RED, BUTTON_COLOR_LABEL.TEAL]),
};

StoryButton.defaultProps = {
  tallyPetitionId: null,
  tallyFallbackText: 'Trending',
  ctaText: '',
  ctaColor: BUTTON_COLOR_LABEL.RED,
};

StoryEmbellishment.propTypes = {
  embellishment: PropTypes.oneOf([...Object.values(EMBELLISHMENTS), null]),
  progressTagText: PropTypes.string,
};

StoryEmbellishment.defaultProps = {
  progressTagText: '',
};

StoryBlock.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ...StoryButton.propTypes,
  ...StoryEmbellishment.propTypes,
};

export default StoryBlock;
