import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../text';
import { FlexContainer, Shadow, ShadowContainer } from './textShadow.styles';

export const TextShadow = ({ children, blurRadius }) =>
  children ? (
    <FlexContainer>
      <ShadowContainer>
        <Shadow blurRadius={blurRadius} />
        <div>
          <Text>{children}</Text>
        </div>
      </ShadowContainer>
    </FlexContainer>
  ) : (
    children
  );

TextShadow.propTypes = {
  children: PropTypes.any,
  blurRadius: PropTypes.string,
};
