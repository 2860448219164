import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getField } from '../../../utils/cmsData';
import {
  Container,
  PhotoContainer,
  Content,
  Image,
  Title,
  Footer,
  TextBody,
  IMAGE_SIZES,
  ShareWrapper,
} from './imageWithTextItem.styles';
import { TwitterShare } from '../../twitterShare';
import { AnimatedEnter } from '../../animatedEnter';

export class ImageWithTextItem extends PureComponent {
  static propTypes = {
    photo: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    allowShare: PropTypes.bool,
    narrow: PropTypes.bool,
  };

  static defaultProps = {
    allowShare: false,
    text: '',
    narrow: false,
  };

  markerRef = React.createRef();

  render() {
    const { photo, text, className, allowShare, narrow } = this.props;

    const quoteMatch = text.match(/^>([\s\S]*)$/m);
    const quote = quoteMatch && quoteMatch[1];

    return (
      <AnimatedEnter markerRef={this.markerRef}>
        <Container className={className} ref={this.markerRef} narrow={narrow}>
          {photo && (
            <PhotoContainer narrow={narrow}>
              <Image image={photo} alt={getField('title', photo)} sizes={IMAGE_SIZES} />
            </PhotoContainer>
          )}

          <Content>
            <TextBody
              text={text}
              renderers={{ h1: ({ children }) => <Title>{children}</Title> }}
            />
            <Footer>
              {allowShare && (
                <ShareWrapper>
                  <TwitterShare shareText={quote || text} />
                </ShareWrapper>
              )}
            </Footer>
          </Content>
        </Container>
      </AnimatedEnter>
    );
  }
}
