import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PageLoader } from './pageLoader.component';
import { selectPageLoaderOpen } from '../../../reducers/pageLoader/pageLoader.selectors';
import { PageLoaderActions } from '../../../reducers/pageLoader/pageLoader.redux';


const mapStateToProps = createStructuredSelector({
  isOpen: selectPageLoaderOpen,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  openPageLoader: PageLoaderActions.openPageLoader,
  closePageLoader: PageLoaderActions.closePageLoader,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageLoader);
