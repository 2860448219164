import React, { Fragment, PureComponent, createContext } from 'react';
import PropTypes from 'prop-types';
import { WindowListener } from '../windowListener/windowListener.component';
import {
  isMobile, isTablet, isTabletPortrait, isTabletLandscape, isDesktop, isDesktopFull, isSmallDevice,
} from '../../theme/media';

export const MOBILE = 'mobile';
export const TABLET_PORTRAIT = 'tabletPortrait';
export const TABLET_LANDSCAPE = 'tabletLandscape';
export const DESKTOP = 'desktop';
export const DESKTOP_FULL = 'desktopFull';

const Context = createContext();

/**
 * Component used to react to platform breakpoint changes and set context state appropriately
 **/
export class PlatformDetectorProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    activeResolution: '',
    isMobile: false,
    isTablet: false,
    isTabletPortrait: false,
    isTabletLandscape: false,
    isDesktop: false,
    isDesktopFull: false,
  };

  componentDidMount() {
    this.setWindowResolution();
  }

  setStateForResolution = (activeResolution) => {
    this.setState({
      activeResolution,
      isMobile: isMobile(),
      isTabletPortrait: isTabletPortrait(),
      isTabletLandscape: isTabletLandscape(),
      isTablet: isTablet(),
      isDesktop: isDesktop(),
      isDesktopFull: isDesktopFull(),
      isSmallDevice: isSmallDevice(),
    });
  };

  /**
   * Updates the current breakpoint data.
   * _setState_ is only invoked if the current state is obsolete, so we can avoid redundant renders
   **/
  setWindowResolution = () => {
    if (isMobile() && this.state.activeResolution !== MOBILE) {
      this.setStateForResolution(MOBILE);
    } else if (isTabletPortrait() && this.state.activeResolution !== TABLET_PORTRAIT) {
      this.setStateForResolution(TABLET_PORTRAIT);
    } else if (isTabletLandscape() && this.state.activeResolution !== TABLET_LANDSCAPE) {
      this.setStateForResolution(TABLET_LANDSCAPE);
    } else if (isDesktop() && this.state.activeResolution !== DESKTOP) {
      this.setStateForResolution(DESKTOP);
    } else if (isDesktopFull() && this.state.activeResolution !== DESKTOP_FULL) {
      this.setStateForResolution(DESKTOP_FULL);
    }
  };

  render() {
    return (
      <Fragment>
        <WindowListener handledFunction={this.setWindowResolution} throttleValue={100} eventType="resize" />
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
      </Fragment>
    );
  }
}

/**
 * Component used to dynamically re-render content when the platform breakpoint changes.
 * It uses context API value provided by __PlatformDetectorProvider__
 **/
export class PlatformDetector extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  render() {
    return (
      <Context.Consumer>
        {(context => this.props.children(context))}
      </Context.Consumer>
    );
  }
}
