import { defineMessages } from 'react-intl';

export default defineMessages({
  play: {
    id: 'video.ariaPlay',
    defaultMessage: 'Play',
  },
  shareTwitter: {
    id: 'article.share.twitter',
    defaultMessage: '',
  },
  shareEmailBody: {
    id: 'article.share.emailBody',
    defaultMessage: '',
  },
  shareEmailSubject: {
    id: 'article.share.emailSubject',
    defaultMessage: '',
  },
});
