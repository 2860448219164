import React from 'react';

const LazyJobListSection = React.lazy(() => import('./jobListSection.container'));

const SuspendedJobListSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyJobListSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedJobListSection;
