import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { renderWhenNotNil } from '../../utils/rendering';
import { Icon, IconActive, IconContainer, Title, IconListItem, Link } from './socialIcons.styles';
import { Container, List } from '../../theme/layout';
import { getField } from '../../utils/cmsData';
import { Hoverable } from '../hoverable';

export class SocialIcons extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    icons: PropTypes.object,
  };

  render() {
    const {
      className,
      title,
      icons,
    } = this.props;

    return (
      <Container row className={className}>
        {renderWhenNotNil(() => <Title>{title}</Title>)(title)}
        <List row>
          {
            icons && icons.toArray().map((item, index) => {
              const iconSrc = getField(['icon', 'fields', 'file', 'url'], item);
              const iconAlt = getField(['icon', 'fields', 'title'], item);
              const iconActiveSrc = getField(['iconActive', 'fields', 'file', 'url'], item);
              const iconActiveAlt = getField(['iconActive', 'fields', 'title'], item);
              const url = getField(['link', 'fields', 'url', 'fields', 'url'], item);
              const title = getField(['link', 'fields', 'title'], item);

              return (
                <IconListItem key={index}>
                  <Link url={url} aria-label={title} newTab>
                    <IconContainer>
                      <Hoverable>
                        <Icon src={iconSrc} hasActiveIcon={!!iconActiveSrc} alt={iconAlt} />
                      </Hoverable>
                      <IconActive src={iconActiveSrc} alt={iconActiveAlt} />
                    </IconContainer>
                  </Link>
                </IconListItem>
              );
            })
          }
        </List>
      </Container>
    );
  }
}
