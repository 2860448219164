import React from 'react';

export const ContentContextType = {
  PAGE: 'PAGE',
  ARTICLE: 'ARTICLE',
};

/**
 * Provides context for the current page. It could be either __PAGE__ or __ARTICLE__.
 * It uses react context API to keep the current context value.
 **/
export const ContentContext = React.createContext(ContentContextType.PAGE);
