import React from 'react';

const LazyImageWithTextList = React.lazy(() => import('./imageWithTextList.container'));

const SuspendedImageWithTextList = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyImageWithTextList ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedImageWithTextList;
