import styled from 'styled-components';
import {
  InnerContainer as StyledInnerContainer,
  FONTS,
  COLORS,
  GUTTER_RESPONSIVE,
  Section,
} from '../styles/homePageStyles';
import { Link as StyledLink } from '../link';
import { PseudoFuzzyBackground } from '../pseudoFuzzyBackground/pseudoFuzzyBackground.styles';
import { media } from '../../../theme/media';

export const GivingSection = styled(Section)`
  background: ${COLORS.WHITE};
  width: 100%;

  position: relative;
  max-height: none;

  ${media.tablet`
    height: clamp(52.5rem, min(95dvh, 45.927rem + 26.97vw), 67.5rem);
  `};

  ${media.desktop`
    max-height: none;
  `};
`;

export const OuterContainer = styled.div`
  position: relative;
  inset: 0;
  z-index: 1;
  margin: 0;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-inline: ${GUTTER_RESPONSIVE};
  `};
`;

export const ImageContainer = styled.div`
  position: absolute;
  inset: 0 0 auto 0;
  z-index: 1;

  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: clamp(43.375rem, 30rem + 60.88vw, 67.5rem);
    z-index: -1;
  }
`;

export const InnerContainer = styled(StyledInnerContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  position: relative;
  padding-top: 62px;
  width: 100%;
  z-index: 1;

  ${media.tablet`
    padding: 0;
    width: 465px;
  `}
`;

export const HeaderSection = styled(PseudoFuzzyBackground)`
  margin-bottom: 42px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 0 30px 0 30px;

  ${media.tablet`
    text-align: left;
    padding: 0;
  `}
`;

export const BoldHeader = styled.div`
  font-family: ${FONTS.TITLE_FONT};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  color: ${COLORS.BLUE_ACCESSIBLE};
  text-transform: uppercase;
  display: ${props => (props.inLine ? 'inline-block' : 'block')};

  ${media.tablet`
    font-size: 2.656rem;
  `}
`;

export const ItalicHeader = styled.div`
  font-family: ${FONTS.SECOND_FONT};
  font-size: 2rem;
  font-weight: 400;
  font-style: italic;
  color: ${COLORS.DARK_GRAY};
  display: ${props => (props.inLine ? 'inline-block' : 'block')};

  ${media.tablet`
    font-size: 2.656rem;
  `}
`;

export const MendedHeart = styled.div`
  width: 33px;
  margin-left: 10px;
  margin-top: 5px;
  display: inline-block;
  vertical-align: sub;

  img {
    width: 100%;
  }
`;

export const LinkSection = styled.div`
  padding-top: 10px;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: -1px 0;

  & > span {
    padding-top: 5px;
    color: ${COLORS.WHITE};
  }

  a {
    color: ${COLORS.DARK_GRAY};
    font-size: auto;
  }

  ${media.tablet`
    background-color: transparent;
    justify-content: center;
    flex-direction: row;
    padding-top: 0;
    margin-top: 20px;

    a {
      color: ${COLORS.WHITE};
    }
  `}
`;

export const Link = styled(StyledLink)`
  ${media.tablet`
    background-color: ${COLORS.DARK_GRAY}59;
    box-shadow: 0 0 30px 20px ${COLORS.DARK_GRAY}59;
  `}
`;

export const MobilePadding = styled.div`
  height: 13.5vh;
  background-color: ${COLORS.WHITE};
`;
