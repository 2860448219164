import styled from 'styled-components';
import {
  GUTTER_RESPONSIVE,
  COLORS,
  FONTS,
  Pad as unstyledPad,
  DIVIDER_WIDTH,
  DIVIDER_COLOR,
} from '../styles/homePageStyles';
import { media } from '../../../theme/media';
import { Image } from '../../image';

export const Pad = styled(unstyledPad)`
  max-width: 100vw;
`;

export const Card = styled.div`
  background: ${COLORS.WHITE};
  color: ${COLORS.DARK_GRAY};
  padding-inline: ${GUTTER_RESPONSIVE};
  padding-block: 27px 37px;
  display: flex;
  flex-direction: column;
  column-gap: 45px;
  row-gap: 20px;
  margin-block: 53px 40px;

  & > * {
    flex: 1;
  }

  ${media.tablet`
    padding: ${GUTTER_RESPONSIVE};
  `};

  ${media.tabletLarge`
    flex-direction: row;
    margin-block: 35px 50px;
  `};
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0 0 2rem 0;

    ${media.tabletLarge`
      padding-right: clamp(0px, 2.25vw, 40px);
    `};
  }

  h4 {
    font-weight: 300;

    ${media.tablet`
      font-size: 1.2rem;
      font-family: ${FONTS.TITLE_FONT};
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 2rem;

    `};

    ${media.tabletLarge`
      max-width: 30rem;
    `};
  }
`;

export const Divider = styled.div`
  flex: 0 0;
  width: ${DIVIDER_WIDTH};
  min-width: ${DIVIDER_WIDTH};
  background: ${DIVIDER_COLOR};
`;

export const BadgeImage = styled(Image)`
  width: auto;
  height: 100%;
  vertical-align: bottom;
  max-width: ${props => (props.large ? 'auto' : '5.75rem')};
  max-height: ${props => (props.large ? '8rem' : '5.75rem')};
  object-fit: contain;
  min-width: 0;
  margin-block: 13px;

  ${media.tabletLarge`
    max-width: ${props => (props.large ? 'auto' : '5.25rem')};
    max-height: ${props => (props.large ? '8rem' : '6.25rem')};
  `};
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 25px 40px;

  div {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    > * {
      flex: 1;
      margin-inline: 10px;
      text-align: center;
    }

    ${media.tablet`
      flex-wrap: nowrap;

      > * {
        flex: 0;
      }  
    `};
  }

  ${media.tablet`
      padding: 0;
    `};
`;

export const FeaturedBadgeContainer = styled.div`
  padding-bottom: 35px;

  img {
    width: 100%;
  }

  a {
    margin: 0;
  }

  ${media.tablet`
    img {
      width: auto;
    }

    a {
      margin-inline: 10px;
    }
  `};
`;
