import styled, { css } from 'styled-components';
import { BUTTON_COLOR, FONTS, TEXT_COLOR } from '../styles/homePageStyles';
import { FormattedA } from '../link';

const getButtonSecondaryColor = color => `color-mix(in srgb, ${color}, black 20%)`;

export const Button = styled(FormattedA)`
  background: ${props => BUTTON_COLOR[props.color]};
  color: ${props => TEXT_COLOR[props.color]};
  position: relative;
  border: none;
  cursor: pointer;
  font-family: ${FONTS.TITLE_FONT};
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.015em;
  width: fit-content;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out 0s;
  white-space: nowrap;
  padding: 0 1.625rem;

  &:focus,
  &:hover {
    background: ${props => getButtonSecondaryColor(BUTTON_COLOR[props.color])};
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: solid ${props => getButtonSecondaryColor(BUTTON_COLOR[props.color])};
    border-left: solid transparent;
    border-width: 16.5px;
    transition: all 0.2s ease-in-out 0s;
  }

  ${props =>
    (props.big || props.fullWidth) &&
    css`
      font-size: 1.25rem;

      &:after {
        border-width: 19.5px;
      }
    `}

  ${props =>
    props.big &&
    css`
      height: 54px;
      padding: 0 2rem;
    `}

  ${props =>
    props.fullWidth &&
    css`
      height: 63.6px;
      min-width: 100%;
    `}

  ${props =>
    props.fullWidthOnSmallerScreens &&
    css`
      @media screen and (max-width: 600px) {
        font-size: 1.25rem;
        height: 63.6px;
        min-width: 100%;

        &:after {
          border-width: 19.5px;
        }
      }
    `}
`;
