import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../image';
import {
  GivingSection,
  OuterContainer,
  ImageContainer,
  InnerContainer,
  Content,
  HeaderSection,
  BoldHeader,
  ItalicHeader,
  MendedHeart,
  LinkSection,
  Link,
  MobilePadding,
} from '../givingBreakout.styles';
import { LearnMoreBox, Button } from './legacyGivingBreakout.styles';
import { BUTTON_COLOR_LABEL } from '../../styles/homePageStyles';
import { PlatformDetector } from '../../../platformDetector/platformDetector.component';
import MendedHeartSvg from '../../../../images/icon-heart.svg';

export const LegacyGivingBreakout = ({ heroDesktop, heroMobile, description }) => {
  const getDynamicHero = isMobile => {
    if (isMobile) {
      return heroMobile.getIn(['fields', 'file', 'url']);
    }
    return heroDesktop.getIn(['fields', 'file', 'url']);
  };

  const getAlt = isMobile => {
    if (isMobile) {
      return heroMobile.getIn(['fields', 'title']);
    }
    return heroDesktop.getIn(['fields', 'title']);
  };

  return (
    <PlatformDetector>
      {({ isMobile }) => (
        <GivingSection>
          <OuterContainer>
            <ImageContainer>
              <Image src={getDynamicHero(isMobile)} alt={getAlt(isMobile)} />
            </ImageContainer>
            <InnerContainer>
              <Content>
                <HeaderSection theme="light">
                  <ItalicHeader>Leave a legacy of</ItalicHeader>
                  <BoldHeader inLine>Compassion</BoldHeader>
                  <MendedHeart>
                    <img src={MendedHeartSvg} alt="Mended Heart" loading="lazy" />
                  </MendedHeart>
                </HeaderSection>
                <LearnMoreBox>
                  <div>{description}</div>
                  <Button
                    href="/leave-a-legacy"
                    color={BUTTON_COLOR_LABEL.BLUE}
                    fullWidth
                  >
                    Learn More
                  </Button>
                </LearnMoreBox>
                <LinkSection>
                  <Link
                    url="https://secure.everyaction.com/p/UI8VaH49Rku2TzlEfpUo8g2"
                    text="Manage my donations"
                  />
                  {!isMobile && <span>|</span>}
                  <Link url="/ways-to-give" text="More ways to give" />
                </LinkSection>
                {isMobile && <MobilePadding />}
              </Content>
            </InnerContainer>
          </OuterContainer>
        </GivingSection>
      )}
    </PlatformDetector>
  );
};

LegacyGivingBreakout.propTypes = {
  heroDesktop: PropTypes.object.isRequired,
  heroMobile: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
};

export default LegacyGivingBreakout;
