import React from 'react';

const LazyArticleTextBlock = React.lazy(() => import('./articleTextBlock.container'));

const SuspendedArticleTextBlock = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyArticleTextBlock ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedArticleTextBlock;
