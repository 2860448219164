import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Container, Icon, IconActive } from './socialIcon.styles';
import { renderWhenTrue } from '../../utils/rendering';
import { accessibleClickHandler } from '../../utils/accessibility';
import messages from './socialIcon.messages';

class SocialIcon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    hoverSrc: PropTypes.string,
    role: PropTypes.string,
    handleShare: PropTypes.func.isRequired,
    intl: PropTypes.object,
    noHover: PropTypes.bool,
  };

  state = {
    tooltip: null,
  };

  componentWillUnmount() {
    clearTimeout(this.tooltipTimeout);
  }

  get iconBaseProps() {
    return {
      ...accessibleClickHandler(this.handleShare, this.props.role),
    };
  }

  handleShare = () => {
    const result = this.props.handleShare();
    if (result) {
      this.setState({ tooltip: messages.copied });
    }
    this.tooltipTimeout = setTimeout(() => this.setState({ tooltip: null }), 1000);
  };

  renderHoverIcon = renderWhenTrue(() => (
    <IconActive
      {...this.iconBaseProps}
      src={this.props.hoverSrc}
      alt={this.props.alt}
      aria-hidden="true"
      tabIndex={-1}
    />
  ));

  render() {
    const { src, hoverSrc, alt, className, noHover } = this.props;
    return (
      <Container
        className={className}
        hasActiveIcon={!!hoverSrc}
        noHover={noHover}
        tooltip={this.state.tooltip && this.props.intl.formatMessage(this.state.tooltip)}
      >
        <Icon {...this.iconBaseProps} src={src} alt={alt} tabIndex={0} />
        {this.renderHoverIcon(!!hoverSrc)}
      </Container>
    );
  }
}

export default injectIntl(SocialIcon);
