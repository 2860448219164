// Save params to pass through to iframes which may occur on other pages from the users's entry.

import { parse } from 'query-string';

const params = parse(location.search);

const passthroughParams = ['sourceid'].reduce((accum, param) => {
  if (params[param]) {
    accum[param] = params[param];
  }
  return accum;
}, {});

export default passthroughParams;
