import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Quote, List } from './articleQuoteBlock.styles';
import { parseFields } from '../../../utils/cmsData';

/**
 * Section with single quote item
 **/
export class ArticleQuoteBlock extends PureComponent {
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    entryId: PropTypes.string.isRequired,
    quote: PropTypes.object,
    index: PropTypes.number,
  };

  render() {
    const { contentType, entryId, index, quote } = this.props;
    const quoteFields = { ...parseFields(quote) };
    return (
      <Container
        contentType={contentType}
        entryId={entryId}
        componentName="Article Quote Block"
        index={index}
      >
        <List>
          <Quote {...quoteFields} />
        </List>
      </Container>
    );
  }
}
