import styled from 'styled-components';
import { media, sizes } from '../../theme/media';
import { Image as ResponsiveImage } from '../image';

export const IMAGE_SIZES = {
  [sizes.mobile]: 240,
  [sizes.desktop]: 240,
};

export const Image = styled(ResponsiveImage)`
  width: auto;
  height: 5rem;
  max-width: 13rem;
  object-fit: contain;
  min-width: 0;
`;

export const FooterBadgeContainer = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;

  ${media.tablet`
    margin-top: -20px;
    margin-bottom: 20px;
  `};

  ${media.tabletLarge`
    margin: 40px 0;
    order: 4;
    > * {
      margin-right: 20px;
    }
  `};
`;

export const FooterBadgeImageContainer = styled.div`
  margin: 0 20px 0 0;
  * {
    max-width: 8rem;
  }

  ${media.tabletLarge`
    * {
      max-width: 10rem;
    }
  `};
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 28px;

  ${media.tablet`
    margin-top: 30px;
  `};

  ${media.tabletLarge`
    margin-top: 173px;
  `};

  ${media.desktop`
    margin-top: 302px;
  `};

  ${media.desktopFull`
    margin-top: 382px;
  `};
`;

export const HeroImageContainer = styled.div`
  margin-right: 32px;

  * {
    display: block;
  }

  ${media.desktop`
    margin-right: 48px;
  `};

  &:last-child {
    margin-right: 0;
  }
`;
