import { List } from 'immutable';

import MediaItemAuthorDecorator from './mediaItem.author.decorator';
import MediaItemImageDecorator from './mediaItem.image.decorator';
import ListDecorator from './list.decorator';
import ImageDecorator from './image.decorator';
import FileDecorator from './file.decorator';
import GlobalConfigDecorator from './globalConfig.decorator';
import ExternalEmbedDecorator from './externalEmbed.decorator';

function contentType(obj) {
  return obj.getIn && obj.getIn(['sys', 'contentType', 'sys', 'id']);
}

function type(obj) {
  return obj.getIn && obj.getIn(['fields', 'type']);
}

const decorate = (obj) => {
  if (!obj || obj.decorated) { return obj; }
  if (obj instanceof List) { return new ListDecorator(obj); }
  if (contentType(obj) === 'mediaItem' && type(obj) === 'Author') {
    return new MediaItemAuthorDecorator(obj);
  }
  if (contentType(obj) === 'mediaItem' && type(obj) !== 'Author') {
    return new MediaItemImageDecorator(obj);
  }
  if (contentType(obj) === 'externalEmbed') {
    return new ExternalEmbedDecorator(obj);
  }
  if (contentType(obj) === 'globalConfig') {
    return new GlobalConfigDecorator(obj);
  }
  return obj;
};

// Include decorate function in decorators to allow for nested decoration
ListDecorator.decorate = decorate;

export {
  ListDecorator,
  ImageDecorator,
  FileDecorator,
  GlobalConfigDecorator,
  ExternalEmbedDecorator,
  MediaItemAuthorDecorator,
  MediaItemImageDecorator,
  decorate,
};
