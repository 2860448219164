import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, DecorationSection, Triangle } from './sectionBackground.styles';
import { getField } from '../../utils/cmsData';
import { colors } from '../../theme/styled';
import { ArrowDirection, ArrowSide } from '../triangleClip/triangleClip.component';
import { getDevicePixelRatio } from '../../theme/media';
import { getResponsiveImage } from '../../utils/image';
import { PlatformDetector } from '../platformDetector/platformDetector.component';
import { SectionViewportContext } from '../contentManager/pageSection/pageSection.component';

export const Layout = {
  MAIN_ON_TOP: 'MAIN_ON_TOP',
  MAIN_ON_BOTTOM: 'MAIN_ON_BOTTOM',
  MAIN_ON_TOP_CONCAVE: 'MAIN_ON_TOP_CONCAVE',
  MAIN_ON_BOTTOM_CONCAVE: 'MAIN_ON_BOTTOM_CONCAVE',
};

export class SectionBackground extends PureComponent {
  static propTypes = {
    mainColor: PropTypes.object,
    secondaryColor: PropTypes.object,
    backgroundImage: PropTypes.object,
    layout: PropTypes.oneOf([
      Layout.MAIN_ON_BOTTOM, Layout.MAIN_ON_TOP, Layout.MAIN_ON_TOP_CONCAVE, Layout.MAIN_ON_BOTTOM_CONCAVE,
    ]),
    mainHeight: PropTypes.number,
    mainHeightTabletLandscape: PropTypes.number,
    mainHeightTabletPortrait: PropTypes.number,
    mainHeightMobile: PropTypes.number,
    arrowSide: PropTypes.oneOf([ArrowSide.LEFT, ArrowSide.RIGHT]),
    className: PropTypes.string,
  };

  static contextType = SectionViewportContext;

  static defaultProps = {
    layout: Layout.MAIN_ON_BOTTOM,
    mainHeight: 0.8,
    mainHeightMobile: 0.8,
    mainHeightTabletLandscape: 0.8,
    mainHeightTabletPortrait: 0.8,
    arrowSide: ArrowSide.LEFT,
  };

  get isArrowReversed() {
    const { layout } = this.props;
    return layout === Layout.MAIN_ON_TOP || layout === Layout.MAIN_ON_TOP_CONCAVE;
  }

  get isArrowFillOuter() {
    const { layout } = this.props;
    return layout === Layout.MAIN_ON_TOP_CONCAVE || layout === Layout.MAIN_ON_BOTTOM_CONCAVE;
  }

  get arrowDirection() {
    const { layout } = this.props;

    if (layout === Layout.MAIN_ON_TOP || layout === layout.MAIN_ON_BOTTOM_CONCAVE) {
      return ArrowDirection.UP;
    }

    return ArrowDirection.DOWN;
  }

  get theme() {
    return {
      mainColor: getField('color', this.props.mainColor) || colors.white,
      secondaryColor: getField('color', this.props.secondaryColor) || colors.white,
      mainHeight: this.props.mainHeight,
      mainHeightMobile: this.props.mainHeightMobile,
      mainHeightTabletLandscape: this.props.mainHeightTabletLandscape,
      mainHeightTabletPortrait: this.props.mainHeightTabletPortrait,
      isArrowReversed: this.isArrowReversed,
      isArrowFillOuter: this.isArrowFillOuter,
      arrowDirection: this.arrowDirection,
      arrowSide: this.props.arrowSide,
      isMainOnTop: this.props.layout === Layout.MAIN_ON_TOP || this.props.layout === Layout.MAIN_ON_TOP_CONCAVE,
    };
  }

  render() {
    return (
      <PlatformDetector>
        {
          ({ activeResolution }) => {
            const backgroundImage = getResponsiveImage(
              { activeResolution, image: this.props.backgroundImage, multiplier: getDevicePixelRatio() }
            );
            return (
              <Container {...this.theme} className={this.props.className}>
                <DecorationSection
                  {...this.theme}
                  backgroundImage={this.context.isActive && backgroundImage}
                >
                  <Triangle
                    reversed={this.theme.isArrowReversed}
                    fillOuter={this.theme.isArrowFillOuter}
                    color={this.theme.secondaryColor}
                    arrowSide={this.theme.arrowSide}
                    arrowDirection={this.theme.arrowDirection}
                  />
                </DecorationSection>
              </Container>
            );
          }
        }
      </PlatformDetector>
    );
  }
}
