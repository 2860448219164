import { createActions, createReducer } from 'reduxsauce';
import { Record, Map, fromJS } from 'immutable';


export const { Types: FooterTypes, Creators: FooterActions } = createActions({
  fetch: [],
  fetchSuccess: ['items'],
  fetchError: ['payload'],
}, { prefix: 'FOOTER_' });

const FooterRecord = new Record({
  data: Map(),
});

export const INITIAL_STATE = new FooterRecord({});

const fetchSuccessHandler = (state, { items }) => state.set('data', fromJS(items));

export const reducer = createReducer(INITIAL_STATE, {
  [FooterTypes.FETCH_SUCCESS]: fetchSuccessHandler,
});
