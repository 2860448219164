import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addScrollListener, removeScrollListener } from './throttledScroll';
import { isPrerenderBot } from '../../utils/userAgent';

export const ENTERED_CLASS_NAME = 'entered';
export const ENTER_ANIMATION_CLASS_NAME = 'default_animation';
export const ANIMATION_OFFSET = 50;

const ScrollMarker = styled.span`
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  visibility: hidden;
`;


/**
 * @summary Component that animates the elements when they enter the viewport
 * @return props.children element with ENTERED_CLASS_NAME className applied if the element is in the viewport
 **/
export class AnimatedEnter extends PureComponent {
  /**
   * PropTypes
   * @property {node} children - target element to animate
   * @property {string} enteredClass - class applied then the element has entered the viewport
   * @property {node} markerRef - element to measure the position on the screen.
   *                  It is measured on scroll event to determine if the target element animation should be
   *                  triggered
   * @property {number} delay - delay in milliseconds to wait after element enters the screen and before the
   *                    animation starts
   * @property {number} threshold - <0-1> defines how far on the screen (percentage of the viewport height)
   *                    the element needs to be to trigger the animation
   */
  static propTypes = {
    children: PropTypes.any,
    enteredClass: PropTypes.string,
    markerRef: PropTypes.any,
    disableDefaultAnimation: PropTypes.bool,
    delay: PropTypes.number,
    threshold: PropTypes.number,
  };

  static defaultProps = {
    enteredClass: ENTERED_CLASS_NAME,
    delay: 0,
    threshold: 0,
  };

  state = {
    entered: false,
  };

  componentDidMount() {
    const handleContentReady = () => {
      if (this.isInViewport) {
        this.enter();
      } else {
        addScrollListener(this.checkEntered);
      }
    };

    if (this.props.markerRef) {
      setTimeout(handleContentReady, 0);
    } else {
      handleContentReady();
    }
  }

  componentWillUnmount() {
    removeScrollListener(this.checkEntered);
    clearTimeout(this.entryTimeout);
  }

  /**
   * set element has entered the viewport
   */
  setEntered = () => {
    this.setState({ entered: true });
    clearTimeout(this.entryTimeout);
  };

  get useCustomMarker() {
    return !this.props.markerRef && !this.isDomElement;
  }

  get isDomElement() {
    return this.childRef.current && !this.childRef.current.isReactComponent;
  }

  get marker() {
    if (this.isDomElement) {
      return this.childRef;
    }
    return this.props.markerRef ? this.props.markerRef : this.markerRef;
  }

  entryTimeout = null;

  markerRef = React.createRef();
  childRef = React.createRef();

  enter = () => {
    if (this.props.delay > 0) {
      this.entryTimeout = setTimeout(this.setEntered, this.props.delay);
    } else {
      this.setEntered();
    }
  };

  checkEntered = () => {
    if (this.isInViewport) {
      this.enter();
      removeScrollListener(this.checkEntered);
    }
  };

  shouldShowEnteredClass = () => this.state.entered || isPrerenderBot;

  /**
   * @getter
   * @return {bool} whether the element has entered the viewport enough (based on threshold) to start
   *                the entry animation
   **/
  get isInViewport() {
    const marker = this.marker.current;
    const { threshold } = this.props;
    if (!marker) {return false;}

    const screenHeight = window.innerHeight;
    const markerRect = marker.getBoundingClientRect();
    const positionTop = markerRect.top - ANIMATION_OFFSET;
    const positionBottom = markerRect.top + markerRect.height - ANIMATION_OFFSET;

    const isBottomBelowThreshold = positionBottom > threshold * screenHeight;
    const isTopAboveThreshold = positionTop < (1 - threshold) * screenHeight;

    return isBottomBelowThreshold && isTopAboveThreshold;
  }

  render() {
    const enteredClass = this.shouldShowEnteredClass() ? this.props.enteredClass : '';

    return (
      <Fragment>
        {!this.state.entered && this.useCustomMarker && <ScrollMarker ref={this.markerRef} />}
        {React.Children.map(this.props.children, child => {
          return child && (
            React.cloneElement(child, {
              className: [
                child.props.className || '',
                enteredClass,
                this.props.disableDefaultAnimation ? '' : ENTER_ANIMATION_CLASS_NAME,
              ].join(' ').trim(),
              ref: this.childRef,
            })
          );
        }
        )}
      </Fragment>
    );
  }
}
