import styled, { css } from 'styled-components';
import { GenericArticleSection, wideSectionStyle } from '../styles';
import { media, sizes } from '../../../theme/media';
import { VideoIcon } from '../../../components/video/videoIcon';
import { IconHoverWrapper } from '../../../components/video/videoIcon/videoIcon.styles';
import { Figure as FigureComponent } from '../../../components/figure';

export const Container = styled(GenericArticleSection)`
  margin: 0;

  ${media.tablet`
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${media.tabletLarge`
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const IMAGE_SIZES = {
  [sizes.mobile]: 375,
  [sizes.tablet]: 644,
  [sizes.tabletLarge]: 724,
  [sizes.desktop]: 859,
};

export const Figure = styled(FigureComponent)`
  ${wideSectionStyle};
  ${props =>
    props.padded &&
    css`
      padding-bottom: 100px;
    `}
`;

export const VideoOverlay = IconHoverWrapper;

export const VideoCta = VideoIcon;
