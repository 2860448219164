import FontFaceObserver from 'fontfaceobserver';

// Fonts
// Merriweather
import '@fontsource/merriweather/300.css';
import '@fontsource/merriweather/700.css';
// Open Sans
import 'fontsource-open-sans/300.css';
import 'fontsource-open-sans/400.css';
import 'fontsource-open-sans/600.css';
import 'fontsource-open-sans/700.css';
// Montserrat
import 'fontsource-montserrat/200-normal.css';
import 'fontsource-montserrat/300-normal.css';
import 'fontsource-montserrat/400-normal.css';
import 'fontsource-montserrat/700-normal.css';
// Noto Serif
import 'fontsource-noto-serif/400.css';
import 'fontsource-noto-serif/700.css';

export const Fonts = () => {
  return null;
};

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded');
  },
  () => {
    document.body.classList.remove('fontLoaded');
  },
);
