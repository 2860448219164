import styled, { keyframes } from 'styled-components';

import { COLORS } from '../../styles/homePageStyles';

const digitHeightRange = 'clamp(2.8rem, 11.3vw, 3.3rem)';

const flipUpperCounter = keyframes`
  0% {
    transform: rotateX(0deg);
    filter: brightness(1);
  }

  50% {
    transform: rotateX(-90deg);
    filter: brightness(0.8);
  }

  100% {
    transform: rotateX(-90deg);
    filter: brightness(0.8);
  }
`;

const unflipLowerCounter = keyframes`
  0% {
    transform: rotateX(90deg) translate(0, 0);
  }

  50% {
    transform: rotateX(90deg) translate(0, 0);
  }

  100% {
    transform: rotateX(0deg) translate(0, 0);
  }
`;

const darkenLowerCounter = keyframes`
  0% {
    filter: brightness(1);
  }

  50% {
    filter: brightness(1);
  }

  100% {
    filter: brightness(0.8);
  }
`;

export const CounterDigitValue = styled.div`
  font-size: clamp(2rem, 7.5vw, 2.2rem);
  font-weight: 700;
  text-align: center;

  user-select: none;
`;

export const CounterDigitContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const BaseUpperCounterDigitContainer = styled(CounterDigitContainer)`
  background: ${COLORS.WHITE};

  ${CounterDigitValue} {
    color: ${COLORS.MEDIUM_GRAY};
  }
`;

export const UpperFrontCounterDigitContainer = styled(BaseUpperCounterDigitContainer)`
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
`;
export const UpperBehindCounterDigitContainer = styled(BaseUpperCounterDigitContainer)``;

const BaseLowerCounterDigitContainer = styled(CounterDigitContainer)`
  clip-path: inset(50% 0 0 0);

  background: ${COLORS.NEUTRAL_GRAY};

  ${CounterDigitValue} {
    color: ${COLORS.DARK_GRAY};
  }
`;

export const LowerFrontCounterDigitContainer = styled(BaseLowerCounterDigitContainer)`
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
`;
export const LowerBehindCounterDigitContainer = styled(BaseLowerCounterDigitContainer)``;

export const CounterDigitWrapper = styled.div`
  position: relative;

  display: inline-block;

  width: clamp(2rem, 8.5vw, 2.3rem);
  height: ${digitHeightRange};
  line-height: ${digitHeightRange};

  perspective-origin: 50% 50%;
  perspective: 300px;

  &:not(:first-of-type) {
    margin-left: 0.1rem;
  }

  &.flip {
    ${UpperFrontCounterDigitContainer} {
      animation: ${flipUpperCounter} 400ms ease-in 0s 1 normal forwards;
    }

    ${LowerFrontCounterDigitContainer} {
      animation: ${unflipLowerCounter} 400ms ease-out 0s 1 normal forwards;
    }

    ${LowerBehindCounterDigitContainer} {
      animation: ${darkenLowerCounter} 400ms ease-out 0s 1 normal forwards;
    }
  }
`;
