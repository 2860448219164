import React, { Fragment, PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { ContentManager } from '../../components/contentManager';

export class PageTemplate extends PureComponent {
  static propTypes = {
    template: PropTypes.object,
    children: PropTypes.any,
  };

  render() {
    const { children, template = Map() } = this.props;

    return (
      <Fragment>
        <ContentManager content={template.getIn(['fields', 'sectionsAboveContent'])} />
        {children}
        <ContentManager content={template.getIn(['fields', 'sectionsBelowContent'])} />
      </Fragment>
    );
  }
}
