import React from 'react';

const LazyCtaCardsSection = React.lazy(() => import('./ctaCardsSection.container'));

const SuspendedCtaCardsSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyCtaCardsSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedCtaCardsSection;
