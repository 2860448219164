import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { selectIsNotFound } from '../../reducers/page/page.selectors';
import { PageActions } from '../../reducers/page/page.redux';
import { NotFoundHandler } from './notFoundHandler.component';

const mapStateToProps = createStructuredSelector({
  isNotFound: selectIsNotFound,
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNotFound: PageActions.setNotFound,
    },
    dispatch,
  );

const Connected = connect(mapStateToProps, mapDispatchToProps)(NotFoundHandler);

export default (props) => {
  const routerLoc = useLocation();
  return <Connected {...props} location={routerLoc} />;
};
