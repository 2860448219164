import { createActions, createReducer } from 'reduxsauce';
import { Record } from 'immutable';

export const { Types: PageLoaderTypes, Creators: PageLoaderActions } = createActions({
  openPageLoader: [],
  closePageLoader: [],
  initialLoaded: [],
}, { prefix: 'PAGE_LOADER_' });

const PageLoaderRecord = new Record({
  isOpen: false,
  initialLoad: {
    isConfigLoaded: true,
    isLanguageLoaded: true,
  },
});

export const INITIAL_STATE = new PageLoaderRecord({});

const handlePageLoaderOpened = state => state.set('isOpen', true);
const handlePageLoaderClosed = state => state.set('isOpen', false);

export const reducer = createReducer(INITIAL_STATE, {
  [PageLoaderTypes.OPEN_PAGE_LOADER]: handlePageLoaderOpened,
  [PageLoaderTypes.CLOSE_PAGE_LOADER]: handlePageLoaderClosed,
});
