import styled, { css } from 'styled-components';
import prop from 'ramda/es/prop';
import multiply from 'ramda/es/multiply';
import compose from 'ramda/es/compose';
import { fillAbsolute } from '../../theme/mixins';
import { TriangleClip } from '../triangleClip';
import { media } from '../../theme/media';
import { backgroundUrl } from '../../utils/rendering';

const percentHeight = compose(multiply(100), prop('mainHeight'));
const percentHeightTabletLandscape = compose(multiply(100), prop('mainHeightTabletLandscape'));
const percentHeightTabletPortrait = compose(multiply(100), prop('mainHeightTabletPortrait'));
const percentHeightMobile = compose(multiply(100), prop('mainHeightMobile'));

export const Container = styled.div`
  ${fillAbsolute};
  background: ${prop('secondaryColor')};
`;

export const DecorationSection = styled.div`
  position: absolute;
  ${props => props.isMainOnTop ? css`top: 0;` : css`bottom: 0;`};
  left: 0;
  width: 100%;
  height: ${percentHeightMobile}%;
  background-color: ${prop('mainColor')};
  background-image: ${backgroundUrl('backgroundImage')};
  background-size: cover;
  background-position: bottom;

  ${media.tablet`
    height: ${percentHeightTabletPortrait}%;
  `};

  ${media.tabletLarge`
    height: ${percentHeightTabletLandscape}%;
  `};

  ${media.desktop`
    height: ${percentHeight}%;
  `};
`;

export const Triangle = styled(TriangleClip)`
  position: absolute;
  ${props => props.reversed ? css`bottom: -1px;` : css`top: -1px;`};
  left: 0;
  width: 100%;
`;
