import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const BgVideo = ({ poster, directUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute('src', directUrl || '');
      videoRef.current.setAttribute('poster', poster || '');
      videoRef.current.load();
    }
  }, [directUrl, poster]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      ref={videoRef}
      controls={false}
      poster={poster}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
    >
      <source src={directUrl} type="video/mp4" />
    </video>
  );
};

BgVideo.propTypes = {
  poster: PropTypes.string,
  directUrl: PropTypes.string,
};

export default BgVideo;
