import styled, { css } from 'styled-components';
import { media } from '../../theme/media';
import { Container } from '../../theme/layout';
import { GenericArticleSection } from './genericArticleSection';

export { GenericArticleSection } from './genericArticleSection';

export const Article = styled(Container)`
  padding-bottom: 60px;

  ${media.tablet`
    padding-bottom: 80px;
  `};
`;

export const ContentSection = styled.section`
  max-width: 100%;
`;

export const wideSectionStyle = css`
  width: 100%;

  ${media.tablet`
    width: 687px;
  `}

  ${media.tabletLarge`
    width: 779px;
  `}

  ${media.desktop`
    width: 859px;
  `}
`;

export const narrowSectionStyle = css`
  max-width: 455px;

  ${media.tablet`
    padding-left: 0;
    padding-right: 0;
  `}

  ${media.tabletLarge`
    max-width: 620px;
  `};

  ${media.desktop`
    max-width: 640px;
  `};
`;

export const GenericArticleContentSection = styled(GenericArticleSection)`
  ${narrowSectionStyle};
`;
