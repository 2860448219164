import styled from 'styled-components';
import { colors } from '../../../theme/styled';

export const COLLAPSE_TIME = 400;

const getHeight = (height) => {
  return height ? `${height}px` : 'auto';
};

export const Container = styled.div`
  height: auto;
  overflow: hidden;
  transition: max-height ${COLLAPSE_TIME}ms ease-in-out;
  max-height: ${(props) => props.collapsed ? '72px' : getHeight(props.componentHeight) };

  div:focus {
    outline-offset: -2px;
  }
`;

export const ListItem = styled.li`
  background-color: ${colors.grey};
  border-top: 1px solid ${colors.borderMenuItem};
  visibility: ${(props) => props.isVisible ? 'visible' : 'hidden' };

  a:focus {
    outline-offset: -2px;
  }
`;

export const ActiveOption = styled.div`
  background-color: ${colors.grey};
  -webkit-tap-highlight-color: transparent;
`;
