import { createSelector } from 'reselect';

export const selectPageLoaderDomain = state => state.pageLoader;

export const selectPageLoaderOpen = createSelector(
  selectPageLoaderDomain,
  state => state.get('isOpen'),
);

export const selectInitialLoaded = createSelector(
  selectPageLoaderDomain,
  state => state.getIn(['initialLoad', 'isConfigLoaded']) &&
    state.getIn(['initialLoad', 'isLanguageLoaded']),
);
