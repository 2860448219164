import styled, { keyframes } from 'styled-components';
import { colors } from '../../theme/styled';
import iconHeart from '../../images/icon-heart.svg';

export const HIDE_TIME = 0.5;

const pulse = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0.8;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  position: fixed;
  display: flex;
  background-color: ${colors.darkGrey};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  transition: opacity ${HIDE_TIME}s ease-in-out;

  &:after {
    display: block;
    content: '';
    margin: auto;
    width: 60px;
    height: 60px;
    background: url(${iconHeart}) no-repeat;
    background-size: cover;
    animation: ${pulse} 1.5s linear infinite;
    will-change: transform, opacity;
  }

  &.hide {
    opacity: 0;
  }
`;
