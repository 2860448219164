import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;
`;

// Below is used for storybook only
// Example of how to use this image component
export const ImageContainerExample = styled.div`
  img {
    max-width: 100%;
  }
  overflow: hidden;
`;
