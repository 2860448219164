import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import PropTypes from 'prop-types';
import configureStore from '../../../../reducers/store';
const initialState = {};
// Create redux store with history
const { store, persistor } = configureStore(initialState);

export const ReduxProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};

ReduxProvider.propTypes = {
  children: PropTypes.any,
};
