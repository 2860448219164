import styled from 'styled-components';

export const Icon = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
`;

export const IconActive = styled(Icon)`
  opacity: 0;
  pointer-events: none;
`;

const getIconOpacity = props => {
  if (props.hasActiveIcon) {return 0;}
  if (props.noHover) {return 1;}
  return 0.68;
};

export const Container = styled.div`
  position: relative;

  &:hover, &:active {
    ${Icon} {
      opacity: ${getIconOpacity};
    }

    ${IconActive} {
      opacity: 1;
    }
  }
  
  &:after {
    content: ${({ tooltip }) => tooltip ? `'${tooltip}'` : null};
    position: absolute;
    top: 100%;
    display: inline-block;
    line-height: 10px;
    padding: 5px;
    background: white;
    color: black;
    font-size: 10px;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;
