import { createSelector } from 'reselect';
import { getProp } from '../selectorHelpers';

export const selectConfigDomain = state => state.config.get('config');

export const selectMetaTitle = createSelector(
  selectConfigDomain,
  getProp('metaTitle')
);

export const selectMetaDescription = createSelector(
  selectConfigDomain,
  getProp('metaDescription')
);

export const selectMetaImage = createSelector(
  selectConfigDomain,
  getProp('metaImage')
);

export const selectLogo = createSelector(
  selectConfigDomain,
  getProp('logo')
);

export const selectLogoMobile = createSelector(
  selectConfigDomain,
  getProp('logoMobile')
);

export const selectMetaTwitterSite = createSelector(
  selectConfigDomain,
  getProp('metaTwitterSite')
);

export const selectGoogleTagManagerId = createSelector(
  selectConfigDomain,
  getProp('googleTagManagerId')
);
