import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record, Map } from 'immutable';

export const { Types: AccessoryTypes, Creators: AccessoryActions } = createActions(
  {
    fetchAccessories: ['content'],
    setModalContent: ['content'],
    setBannerContent: ['content'],
    setCurtainContent: ['content'],
    closeModal: ['modalId'],
    closeBanner: ['bannerId'],
    closeCurtain: null,
    resetModalState: null,
    resetBannerState: null,
  },
  { prefix: 'ACCESSORIES_' },
);

const AccessoryRecord = new Record({
  modalContent: Map(),
  bannerContent: Map(),
  curtainContent: Map(),
  isModalOpen: false,
});

export const INITIAL_STATE = new AccessoryRecord({});

const handleSetModalContent = (state, { content }) =>
  state.set('modalContent', fromJS(content)).set('isModalOpen', true);

const handleSetBannerContent = (state, { content }) =>
  state.set('bannerContent', fromJS(content)).set('isModalOpen', true);

const handleSetCurtainContent = (state, { content }) =>
  state.set('curtainContent', fromJS(content)).set('isModalOpen', true);

const handleResetModal = state => state.set('modalContent', Map()).set('isModalOpen', false);

const handleResetBanner = state => state.set('bannerContent', Map());

const handleResetCurtain = state => state.set('curtainContent', Map());

export const reducer = createReducer(INITIAL_STATE, {
  [AccessoryTypes.SET_MODAL_CONTENT]: handleSetModalContent,
  [AccessoryTypes.SET_BANNER_CONTENT]: handleSetBannerContent,
  [AccessoryTypes.SET_CURTAIN_CONTENT]: handleSetCurtainContent,
  [AccessoryTypes.RESET_MODAL_STATE]: handleResetModal,
  [AccessoryTypes.RESET_BANNER_STATE]: handleResetBanner,
  [AccessoryTypes.CLOSE_CURTAIN]: handleResetCurtain,
});
