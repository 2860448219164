import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import zipObj from 'ramda/es/zipObj';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';

import { decorate } from '../../decorators/index';
import { HeaderStyle } from '../../reducers/menu/menu.redux';
import { SectionErrorBoundary } from '../sectionErrorBoundary';
import {
  getDisplayComponent,
  isAbsoluteMenuHeroComponent,
  isHeroComponent,
  isHeroWithTitleComponent,
} from './contentManager.map';

import { getComponentId, getContentType } from '../../utils/cmsData';
import { propGetter } from './utils';
import { PageSection } from './pageSection';

export const getPropsFromFields = (data, props) =>
  pipe(
    map(propGetter),
    map(getterFn => getterFn(data)),
    zipObj(props.map(item => item.name || item)),
  )(props);

/**
 * @summary Component used to parse CMS data and render proper React Components based on the content
 **/
export class ContentManagerComponent extends PureComponent {
  /**
   * PropTypes
   * @property {array | object} content - content data from CMS
   * @property {object} componentProps - object with props that will be passed to every rendered component
   * @property {bool} asPage - if true, the rendered content will be treated as `first-level` page sections
   * @property {func} setHeaderStyle - sets header layout (differs depending on the page content
   **/
  static propTypes = {
    content: PropTypes.object,
    componentProps: PropTypes.any,
    setHeaderStyle: PropTypes.func.isRequired,
    setHeroPresent: PropTypes.func.isRequired,
    setHeroWithTitlePresent: PropTypes.func.isRequired,
    asPage: PropTypes.bool,
    sizes: PropTypes.object,
    headerStyle: PropTypes.string,
    pageHeaderStyle: PropTypes.string,
  };

  static defaultProps = {
    componentProps: {},
  };

  componentDidMount() {
    this.refreshHeaderStyle();
  }

  componentDidUpdate() {
    this.refreshHeaderStyle();
  }

  /**
   * @method
   * @summary Changes the page header depending on the content.
   * The header could be either ABSOLUTE or RELATIVE
   **/
  refreshHeaderStyle = () => {
    const { content, asPage, pageHeaderStyle } = this.props;

    if (!asPage || !content) {
      return;
    }

    const firstSection = content.get(0);
    const isHero = isHeroComponent(firstSection);
    const isAbsoluteMenuHero = isAbsoluteMenuHeroComponent(firstSection);
    const isHeroWithTitle = isHeroWithTitleComponent(firstSection);
    const newHeaderStyle =
      pageHeaderStyle || (isAbsoluteMenuHero ? HeaderStyle.ABSOLUTE : HeaderStyle.RELATIVE);

    this.props.setHeroPresent(isHero);
    this.props.setHeroWithTitlePresent(isHeroWithTitle);
    if (newHeaderStyle !== this.props.headerStyle) {
      this.props.setHeaderStyle(newHeaderStyle);
    }
  };

  /**
   * @method
   * @summary Chooses what component to render based on data coming from CMS
   * Mapping between specific content-models and ReactComponents are defined in
   * src/components/contentManager/contentManager.map.js

   * @param item - Single renderable item data from CMS
   * @param {number} index - index of the section in the content array
   * @param {function} decorator - a function to wrap the item in a decorator.
   * This provides an alternative to passing specific props.
   **/
  renderContentComponent = (item, index) => {
    const id = getComponentId(item);
    const contentType = getContentType(item);
    const {
      decorated,
      component: DisplayComponent,
      props: passthroughProps,
    } = getDisplayComponent(item);

    if (DisplayComponent) {
      const additionalProps = {
        ...this.props.componentProps,
        asPage: this.props.asPage,
        sizes: this.props.sizes,
      };
      const props = getPropsFromFields(item, passthroughProps);
      const content = (
        <DisplayComponent
          {...props}
          {...{ [contentType]: decorated ? decorate(item) : null }}
          key={props.name + String(index)}
          {...additionalProps}
          contentType={contentType}
          entryId={id}
          index={index}
        />
      );
      return this.props.asPage ? (
        <PageSection key={props.name + String(index)} index={index}>
          {content}
        </PageSection>
      ) : (
        <SectionErrorBoundary key={`${id}_${index}`}>{content}</SectionErrorBoundary>
      );
    }

    return null;
  };

  render() {
    const { content } = this.props;

    if (!content) {
      return null;
    }

    if (Array.isArray(content.toJS())) {
      return <Fragment>{content.toArray().map(this.renderContentComponent)}</Fragment>;
    }

    return <Fragment>{this.renderContentComponent(content)}</Fragment>;
  }
}
