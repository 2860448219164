/*
 * Collects and stores content entries for website page accessories (modals, banners, etc.)
 */

import { all, put, takeLatest, select } from 'redux-saga/effects';
import cookie from 'js-cookie';
import { parse } from 'query-string';

import { AccessoryTypes, AccessoryActions } from './accessories.redux';
import { getItemByType } from '../../services/contentful';
import {
  activismTracker,
  donateTracker,
  subscribeTracker,
} from '../../components/iframeForm/form/tracker';
import { MODAL_TEMPLATES, BANNER_TEMPLATES, CURTAIN_TEMPLATES } from '../../constants';
import { selectModalContent } from './accessories.selectors';
import { selectLocalesLanguage } from '../locales/locales.selectors';
import { isMobile } from '../../theme/media';

const FIRST_VISIT_RULE = 'On first visit';
const UNTIL_DISMISSED_RULE = 'Until dismissed';
const ALWAYS_RULE = 'Always';

// cookie name
const WELCOME_ACCEPTED = 'welcome_accepted';
const dismissalCookie = entryId => `accessory_dismissed_${entryId}`;

function fetchDialogs(locale) {
  const displaySelector = { 'fields.restrictTo[ne]': isMobile() ? 'Desktop Only' : 'Mobile Only' };
  const contentfulSelector = {
    locale,
    model: 'dialog',
    ...displaySelector,
  };
  return getItemByType(contentfulSelector);
}

const isFirstVisit = () => cookie.get(WELCOME_ACCEPTED) !== '1';
const notPreviouslyDismissed = id => cookie.get(dismissalCookie(id)) !== '1';
// onst isOnHomepage = () => location.pathname === '/';

// Links namesof display rules with functions that test them.
const displayRules = {
  [FIRST_VISIT_RULE]: isFirstVisit,
  [ALWAYS_RULE]: () => true,
  [UNTIL_DISMISSED_RULE]: notPreviouslyDismissed,
};

/**
 * This saga loads all active dialog entries and filters them for appropriateness.
 * It stores only the first eligible modal.
 * Order matters. If the query string includes withDialog= will try to show the appropriate modal.
 **/
function* fetchAccessoriesSaga() {
  let accessories = [];

  const addToAccessories = array => {
    accessories = accessories.concat(array);
  };

  const locale = yield select(selectLocalesLanguage);
  const { items: allAccessories } = yield fetchDialogs(locale);

  const { withDialog: identifier } = parse(location.search);

  if (identifier) {
    const identifiedAccessory = allAccessories.find(accessory => accessory.sys.id === identifier);
    if (identifiedAccessory) {
      accessories.push(identifiedAccessory);
    }
  }

  Object.keys(displayRules).forEach(ruleName => {
    let matchingAccessories = allAccessories.filter(
      accessory =>
        displayRules[ruleName](accessory.sys.id) && accessory.fields.displayRule === ruleName,
    );
    if (matchingAccessories) {
      addToAccessories(matchingAccessories);
    }
  });

  accessories = accessories.filter(accessory => {
    const curDate = new Date();

    return (
      (!accessory.fields.startDate || new Date(accessory.fields.startDate) < curDate) &&
      (!accessory.fields.endDate || new Date(accessory.fields.endDate) > curDate)
    );
  });

  if (accessories[0]) {
    let modal = accessories.find(accessory => MODAL_TEMPLATES.includes(accessory.fields.template));
    let banner = accessories.find(accessory =>
      BANNER_TEMPLATES.includes(accessory.fields.template),
    );
    let curtain = accessories.find(accessory =>
      CURTAIN_TEMPLATES.includes(accessory.fields.template),
    );

    if (modal) {
      yield put(AccessoryActions.setModalContent(modal));
    }

    if (banner) {
      yield put(AccessoryActions.setBannerContent(banner));
    }

    if (curtain) {
      yield put(AccessoryActions.setCurtainContent(curtain));
    }
  }
}

/**
 * Marks modal as seen
 **/
function* setModalClosed({ modalId }) {
  cookie.set(WELCOME_ACCEPTED, '1', { path: '', expires: 365 });
  cookie.set(dismissalCookie(modalId), '1', { path: '', expires: 365 });
}

/**
 * Resets all funnel statuses
 **/
function* resetFunnelStatus() {
  const modalContent = yield select(selectModalContent);
  if (modalContent.size) {
    donateTracker.resetStatus();
    activismTracker.resetStatus();
    subscribeTracker.resetStatus();
  }
  yield put(AccessoryActions.resetModalState());
}

function* setBannerClosed({ bannerId }) {
  cookie.set(dismissalCookie(bannerId), '1', { path: '', expires: 365 });
  yield put(AccessoryActions.resetBannerState());
}

export default function* accessoriesSaga() {
  yield all([
    takeLatest(AccessoryTypes.FETCH_ACCESSORIES, fetchAccessoriesSaga),
    takeLatest(AccessoryTypes.CLOSE_MODAL, resetFunnelStatus),
    takeLatest(AccessoryTypes.CLOSE_MODAL, setModalClosed),
    takeLatest(AccessoryTypes.CLOSE_BANNER, setBannerClosed),
  ]);
}
