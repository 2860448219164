import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ImageWithTextItem } from './imageWithTextItem.component';


const mapStateToProps = createStructuredSelector({ });

export const mapDispatchToProps = (dispatch) => bindActionCreators({ }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageWithTextItem);
