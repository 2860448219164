import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Image, Description } from './authorBig.styles';
import { getField, getImgProps } from '../../utils/cmsData';

export class AuthorBig extends PureComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired,
  };

  render() {
    const { description, photo } = this.props;

    return (
      <Container>
        <Image {...getImgProps(photo)} alt={getField(['title'], photo)} />
        <Description>{description}</Description>
      </Container>
    );
  }
}
