/**
 * Generic decorator function to be extended by individual decorators.
 * This provides general functionality, such as the ability to mock the API of immutable objects.
 *
 * @module Decorator
 **/

import { fromJS, Map } from 'immutable';

export default class Decorator {
  constructor(data) {
    this.data = fromJS(data || new Map());
    this.decorated = true;
    // ↓ Allows should.be.a('...Decorator') in tests.
    this[Symbol.toStringTag] = this.constructor.name;
  }

  get(...args) {
    return this.data.get(...args);
  }

  getIn(...args) {
    return this.data.getIn(...args);
  }
}
