import styled, { css } from 'styled-components';
import { media } from '../../theme/media';
import { colors } from '../../theme/styled';
import { ENTERED_CLASS_NAME } from '../animatedEnter/animatedEnter.component';

const lineUpStyle = css`
  bottom: 0;
  &:after {
    top: 0;
  }
`;

const lineDownStyle = css`
  top: 0;
  &:after {
    bottom: 0;
  }
`;

const circleStyle = css`
  &:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: ${colors.darkGrey};
    border-radius: 50%;
    left: -3px;
    top: 83px;
    
    transition: transform .5s ease-in-out;
    transition-delay: .5s;
    transform: scale(0);
    
    ${media.tablet`
      top: 100px;
    `};
  }
`;

export const Line = styled.div`
  position: absolute;
  ${props => props.pointUp ? lineUpStyle : lineDownStyle};
  height: 83px;
  width: 1px;
  background-color: ${colors.darkGrey};
  ${props => !props.noCircle && circleStyle};
  
  transition: transform .5s ease-in-out;
  transform: scaleY(0);
  transform-origin: ${props => props.pointUp ? 'bottom' : 'top'};
  
  ${media.tablet`
    height: 100px;
  `};
`;

export const Wrapper = styled.div`
  position: relative;
  height: 0;
  display: block;
  
  &.${ENTERED_CLASS_NAME} {
    ${Line} {
      transform: scaleY(1);
      
      &:after {
        transform: scale(1);  
      }
    }
  }
`;
