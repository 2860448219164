import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ifElse from 'ramda/es/ifElse';
import is from 'ramda/es/is';
import T from 'ramda/es/T';
import identity from 'ramda/es/identity';
import pick from 'ramda/es/pick';
import omit from 'ramda/es/omit';
import { Link as ReactLink } from 'react-router-dom';
import { isUrlExternal, isHashPath } from '../../utils/url';
import { interactionProps } from '../../utils/rendering';
import { getField } from '../../utils/cmsData';

const htmlProps = pick(['tabIndex']);

export class Link extends PureComponent {
  static propTypes = {
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.string,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    newTab: PropTypes.bool,
    tabIndex: PropTypes.number,
  };

  get newTab() {
    return ifElse(is(Boolean), identity, T)(this.props.newTab);
  }

  render() {
    const {
      label,
      ariaLabel,
      className,
      children,
      newTab, // eslint-disable-line
      ...otherProps
    } = this.props;

    const url =
      typeof this.props.url === 'string' ? this.props.url : getField('url', this.props.url);
    const sanitizedOtherProps = omit(['buttonType'], otherProps);

    if (isUrlExternal(url)) {
      return (
        <a
          className={className}
          href={url}
          target={this.newTab ? '_blank' : null}
          rel="noopener nofollow"
          tabIndex={this.props.tabIndex ? this.props.tabIndex : 0}
          {...sanitizedOtherProps}
        >
          {label}
          {children}
        </a>
      );
    } else if (isHashPath(url)) {
      const idString = url.split('?')[0];
      return (
        /**
         * FIXME: The accessibility for hash links needs to be fixed.
         *        Adding an href should in theory fix this, but I'm not sure
         *        the intended functionality.
         */
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
        <a
          className={className}
          onClick={e => {
            if (!idString) {
              return;
            }
            if (idString === '#osano') {
              e.preventDefault();
              if (window.Osano) {
                window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
              }
              return;
            }
            const element = document.querySelector(idString);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
            // Adds to url without reloading page
            if (window.history && window.history.pushState) {
              window.history.pushState(null, null, idString);
            }
            if (this.props.onClick) {
              this.props.onClick(e);
            }
          }}
        >
          {label}
          {children}
        </a>
      );
    } else if (url) {
      return (
        <ReactLink
          className={className}
          to={url}
          aria-label={ariaLabel}
          tabIndex={0}
          {...htmlProps(otherProps)}
          {...interactionProps(otherProps)}
        >
          {label}
          {children}
        </ReactLink>
      );
    }

    return null;
  }
}
