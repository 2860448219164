import styled from 'styled-components';

import { media } from '../../../theme/media';
import { Section as SectionComponent } from '../styles/homePageStyles';

import blueDivider from './assets/blue-divider.svg';
import greenDivider from './assets/green-divider.svg';

export const Section = styled(SectionComponent)`
  height: 28px;
  background: center / auto 100%
    url(${props => (props.color === 'blue' ? blueDivider : greenDivider)});

  ${media.tablet`
    height: 40px;
    `}
`;
