/**
 * Helpers for CMS data parsing
 * @module
 */
import any from 'ramda/es/any';
import head from 'ramda/es/head';
import compose from 'ramda/es/compose';
import keys from 'ramda/es/keys';
import { getField, getComponentName } from '../../utils/cmsData';

/**
 * @method
 * @return CMS content-type name
 **/

export const pathGetter = prop => data => data.getIn(['fields'].concat(prop.path || prop));
export const propGetter = propConfig => propConfig.getter || pathGetter(propConfig);

export const basedOnField = (field, config) => data => {
  const fieldValue = (getField(field, data) || '').toString();
  const defaultKey = compose(head, keys)(config);
  return config[fieldValue] || config[defaultKey] || {};
};

/**
 * @method
 * @return value from config object, where the key matches the data type field
 **/
export const basedOnType = config => basedOnField('type', config);

export const isType = (name, type) => component => {
  const nameMatches = getComponentName(component) === name;
  const typeMatches = !type || getField('type', component) === type;
  return typeMatches && nameMatches;
};

export const isAnyOfType = types => component =>
  any(([name, type]) => isType(name, type)(component), types);

export const pageSectionAttribute = props => ({
  'data-page-section': props.asPage ? props.index : null,
});

/**
 * @method
 * @summary Converts Title Case Contentful values (e.g. from dropdowns) to camelCase prop values
 * @return camelCase prop value
 **/

const MANUAL_REPLACEMENTS = {
  '1-up': 'oneUp',
};

export const camelCaseify = titleCaseStr => {
  if (!titleCaseStr) {
    return null;
  }

  const camelCaseStr = titleCaseStr
    .toLowerCase()
    .split(' ')
    .map(word => MANUAL_REPLACEMENTS[word] || word)
    .map((word, i) => (i > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join('');
  return camelCaseStr;
};
