import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Line, Wrapper } from './lineDecoration.styles';
import { AnimatedEnter } from '../animatedEnter';

export class LineDecoration extends PureComponent {
  static propTypes = {
    noCircle: PropTypes.bool,
    pointUp: PropTypes.bool,
    className: PropTypes.string,
  };

  render = () => (
    <AnimatedEnter disableDefaultAnimation>
      <Wrapper className={this.props.className}>
        <Line pointUp={this.props.pointUp} noCircle={this.props.noCircle} />
      </Wrapper>
    </AnimatedEnter>
  );
}
