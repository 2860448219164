import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledLink } from './button.styles';
import { Hoverable } from '../hoverable';

export const BUTTON_RED = 'red';
export const BUTTON_GREY = 'grey';

export class Button extends PureComponent {
  static propTypes = {
    buttonType: PropTypes.oneOf([BUTTON_RED, BUTTON_GREY]),
    disabled: PropTypes.bool,
    children: PropTypes.node,
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
    smallOnMobile: PropTypes.bool,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func,
  };

  registerButtonClick(label) {
    dataLayer.push({
      event: 'Click',
      elementType: 'Button',
      element: label,
    });
  }

  renderLinkButton() {
    const { onClick, label, children } = this.props;
    const { registerButtonClick } = this;
    return (
      <StyledLink
        {...this.props}
        onClick={e => {
          // onClick messes with react links.
          registerButtonClick(label || children);
          if (onClick) {
            onClick(e);
          }
        }}
        className={`${this.props.className} thl-button`}
      />
    );
  }

  renderButton() {
    const { onClick, label, children } = this.props;
    const { registerButtonClick } = this;
    return (
      <StyledButton
        {...this.props}
        onClick={e => {
          registerButtonClick(label || children);
          if (onClick) {
            onClick(e);
          }
        }}
        className={`${this.props.className} thl-button`}
      />
    );
  }

  render() {
    return <Hoverable>{this.props.url ? this.renderLinkButton() : this.renderButton()}</Hoverable>;
  }
}
