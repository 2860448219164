import React from 'react';

const LazySectionTriangleDecoration = React.lazy(() =>
  import('./sectionTriangleDecoration.container'),
);

const SuspendedSectionTriangleDecoration = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazySectionTriangleDecoration ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedSectionTriangleDecoration;
