import React from 'react';

const LazyFlagCta = React.lazy(() => import('./flagCta.container'));

const SuspendedFlagCta = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyFlagCta ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedFlagCta;
