import styled from 'styled-components';
import { media } from '../../theme/media';
import { colors } from '../../theme/styled';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: ${colors.darkGrey};
  ${media.tablet`
    background-position: ${props => props.backgroundPosition};
  `};
`;
