import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { FONTS } from '../styles/homePageStyles';
import { Link as LinkComponent } from '../link';
import { media } from '../../../theme/media';

export const Wrapper = styled(ReactMarkdown)`
  strong {
    font-weight: 700;
  }
`;

export const Header = styled.h2`
  font-style: italic;
  font-family: ${FONTS.SECOND_FONT};
  font-weight: 300;
  font-size: 2rem;
  color: inherit;
  text-align: center;
  margin: 0 10px;
  line-height: 1.1875;

  strong,
  em {
    font-style: normal;
    font-family: ${FONTS.TITLE_FONT};
    font-weight: 700;
    text-transform: uppercase;
  }

  ${media.tabletLarge`
    font-size: 2.656rem;
    line-height: 1.3175;
  `}
`;

export const Link = styled(LinkComponent)`
  color: inherit;
  font-size: inherit;
`;
