import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './thirdBlock.styles';
import { BUTTON_COLOR_LABEL } from '../styles/homePageStyles';
import { Markdown } from '../markdown';

const ThirdBlock = ({ headline, description, buttonText, buttonUrl, imageSrc, imageAlt = '' }) => (
  <Styled.Section>
    <Styled.TextSection>
      <Styled.InnerText>
        <Styled.H2>
          <Markdown>{headline}</Markdown>
        </Styled.H2>
        <Markdown>{description}</Markdown>
        {buttonText !== '' && (
          <Styled.Button
            href={buttonUrl}
            color={BUTTON_COLOR_LABEL.YELLOW}
            fullWidthOnSmallerScreens
          >
            {buttonText}
          </Styled.Button>
        )}
      </Styled.InnerText>
    </Styled.TextSection>
    <Styled.ImageSection>
      <img src={imageSrc} alt={imageAlt} />
    </Styled.ImageSection>
  </Styled.Section>
);

ThirdBlock.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default ThirdBlock;
