import React from 'react';

const LazyEventsSection = React.lazy(() => import('./eventsSection.container'));

const SuspendedEventsSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyEventsSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedEventsSection;
