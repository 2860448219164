import React from 'react';

import PropTypes from 'prop-types';

const GoogleTagManager = ({ googleTagManagerId }) => {
  if (window.THLTagManagerAdded) { return null; }
  return (
    <script>
      {`
        // optimize anti flicker code
        // (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
        // h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
        // (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
        // })(window,document.documentElement,'async-hide','dataLayer',4000,
        // {'${googleTagManagerId}':true});

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');
        // This must come after script is loaded lest a render occur before the script is evaluated.
        window.THLTagManagerAdded = true;
    `}
    </script>
  );
};

GoogleTagManager.propTypes = {
  googleTagManagerId: PropTypes.string,
};

export default GoogleTagManager;
