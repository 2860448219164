import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import messages from './legalActionSection.messages';
import {
  Container, Title, Section, AuthorContainer, ArticleList, ArticleItem, LinkContainer,
} from './legalActionSection.styles';
import { AuthorBig } from '../authorBig';
import { UnderlinedLink } from '../underlinedLink/underlinedLink.component';
import {
  PlatformDetector, MOBILE, TABLET_PORTRAIT, TABLET_LANDSCAPE, DESKTOP,
} from '../platformDetector/platformDetector.component';
import { SectionBackground } from '../sectionBackground';
import { getArticleCardData, getField, getId } from '../../utils/cmsData';
import { Layout } from '../sectionBackground/sectionBackground.component';
import { ArrowSide } from '../triangleClip/triangleClip.component';
import { VerticalCard } from '../verticalCard';
import { identifiers } from '../../utils/rendering';

export class LegalActionSection extends PureComponent {
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    entryId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    author: PropTypes.object.isRequired,
    articleList: PropTypes.object.isRequired,
    background: PropTypes.object.isRequired,
  };

  getSectionBackgroundHeight = (breakpoint) => {
    const map = {
      [MOBILE]: 0.34,
      [TABLET_PORTRAIT]: 0.3075,
      [TABLET_LANDSCAPE]: 0.245,
      [DESKTOP]: 0.78,
    };

    return map[breakpoint];
  };

  render() {
    const { contentType, entryId, title, url, author, articleList, background } = this.props;

    return (
      <PlatformDetector>
        {({ isMobile }) => (
          <Container {...identifiers('LegalActionSection', contentType, entryId)}>
            <SectionBackground
              layout={Layout.MAIN_ON_TOP}
              arrowSide={isMobile ? ArrowSide.LEFT : ArrowSide.RIGHT}
              mainColor={getField('mainColor', background)}
              mainHeight={this.getSectionBackgroundHeight(DESKTOP)}
              mainHeightTabletLandscape={this.getSectionBackgroundHeight(TABLET_LANDSCAPE)}
              mainHeightTabletPortrait={this.getSectionBackgroundHeight(TABLET_PORTRAIT)}
              mainHeightMobile={this.getSectionBackgroundHeight(MOBILE)}
            />
            <Section>
              <Title>{title}</Title>
              <ArticleList>
                <AuthorContainer>
                  <AuthorBig
                    description={getField('description', author)}
                    photo={getField('image', author)}
                  />
                  {
                    url && <LinkContainer>
                      <UnderlinedLink url={url}>
                        <FormattedMessage {...messages.linkLabel} />
                      </UnderlinedLink>
                    </LinkContainer>
                  }
                </AuthorContainer>
                {articleList.map(article => (
                  <ArticleItem
                    hasImage={getField(['heroImage', 'fields', 'image', 'fields', 'file', 'url'], article)}
                  >
                    <VerticalCard
                      key={getId(article)}
                      label={getField(['category', 'fields', 'name'], article)}
                      text={getField('excerpt', article)}
                      imageUrl={
                        getField(['heroImage', 'fields', 'image', 'fields', 'file', 'url'], article)
                      }
                      {...getArticleCardData(article)}
                    />
                  </ArticleItem>
                ))}
              </ArticleList>
            </Section>
          </Container>
        )}
      </PlatformDetector>
    );
  }
}
