import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './dynamicHeroCarousel.styles';
import { HeroSlide } from './heroSlide';
import { useIsMobile } from '../utils/useIsMobile';
const getIndexOrRandom = (index, size) => {
  if (index > size) {
    return size - 1;
  }
  if (index === 0) {
    return Math.floor(Math.random() * size);
  }
  return index - 1;
};

const DynamicHeroCarousel = ({ slides, slideDuration, transitionDuration, mobileSlide }) => {
  const isMobile = useIsMobile();
  const slidesArray = Array.isArray(slides) ? slides : slides.toArray();
  const [processedSlides, setProcessedSlides] = useState(
    isMobile ? [slidesArray[getIndexOrRandom(mobileSlide, slidesArray.length)]] : slidesArray,
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [preloadSlideIndex, setPreloadSlideIndex] = useState(0);
  const [navigationDirection, setNavigationDirection] = useState('forwards');
  const [isAutoAdvancing, setIsAutoAdvancing] = useState(processedSlides.length > 1);
  const autoAdvanceInterval = useRef();

  const nextSlideIndex = slideIndex =>
    slideIndex + 1 >= processedSlides.length ? 0 : slideIndex + 1;

  const autoAdvanceCurrentSlide = () => {
    setNavigationDirection('forwards');
    setCurrentSlide(previousSlide => nextSlideIndex(previousSlide));
  };

  const navigateToSlide = slideIndex => {
    setNavigationDirection(
      slideIndex > currentSlide || (currentSlide === processedSlides.length - 1 && slideIndex === 0)
        ? 'forwards'
        : 'backwards',
    );
    setCurrentSlide(slideIndex);
    setIsAutoAdvancing(false);
  };

  const preloadSlide = slideIndex => {
    setPreloadSlideIndex(slideIndex);
  };

  useEffect(() => {
    if (processedSlides.length > 1 && isAutoAdvancing) {
      autoAdvanceInterval.current = setInterval(autoAdvanceCurrentSlide, slideDuration * 1000);
    }
    return () => clearInterval(autoAdvanceInterval.current);
  }, [processedSlides, isAutoAdvancing]);

  useEffect(() => {
    if (processedSlides.length === 1) {
      setCurrentSlide(0);
    }
  }, [processedSlides]);

  useEffect(() => {
    if (isMobile) {
      setProcessedSlides([slidesArray[getIndexOrRandom(mobileSlide, slidesArray.length)]]);
    } else {
      setProcessedSlides(slidesArray);
    }
  }, [isMobile]);

  return (
    <Styled.Carousel>
      {processedSlides.map((slide, i) => (
        <HeroSlide
          key={`heroSlide-${slide.id}`}
          slideIndex={i}
          preload={i === nextSlideIndex(currentSlide) || preloadSlideIndex === i}
          slideCount={processedSlides.length}
          navigateToSlide={navigateToSlide}
          isActive={currentSlide === i}
          slideDuration={slideDuration}
          transitionDuration={transitionDuration}
          navigationDirection={navigationDirection}
          isMobile={isMobile}
          {...slide}
        />
      ))}
      {processedSlides.length > 1 && (
        <Styled.NavigationPad>
          <Styled.NavigationContainer>
            <Styled.Navigation>
              {processedSlides.map((slide, i) => (
                <Styled.NavigationButton
                  key={`hero-slide-nav-item-${slide.id}`}
                  disabled={i === currentSlide}
                  isActive={i === currentSlide}
                  onClick={() => navigateToSlide(i)}
                  onMouseOver={() => preloadSlide(i)}
                  onFocus={() => preloadSlide(i)}
                >
                  <span className="visually-hidden">{i + 1}</span>
                </Styled.NavigationButton>
              ))}
            </Styled.Navigation>
          </Styled.NavigationContainer>
        </Styled.NavigationPad>
      )}
    </Styled.Carousel>
  );
};

DynamicHeroCarousel.propTypes = {
  slides: PropTypes.object.isRequired,
  slideDuration: PropTypes.number,
  transitionDuration: PropTypes.number,
  mobileSlide: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
};

DynamicHeroCarousel.defaultProps = {
  slideDuration: 7,
  transitionDuration: 0.75,
  mobileSlide: 0,
};

export default DynamicHeroCarousel;
