import React from 'react';

const LazyImageWithBarSection = React.lazy(() => import('./imageWithBarSection.container'));

const SuspendedImageWithBarSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyImageWithBarSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedImageWithBarSection;
