/**
 * Sagas for handling author data fetching
 * @module "authorPage.sagas"
 **/

import { put, takeLatest, select } from 'redux-saga/effects';
import { PageActions } from '../page.redux';
import { AuthorPageTypes, AuthorPageActions } from './authorPage.redux';
import { selectCurrentAuthorPageData } from './authorPage.selectors';
import { getAuthor, getPreviewPage } from '../../../services/contentful';
import { selectLocalesLanguage } from '../../locales/locales.selectors';
import { PageLoaderActions } from './../../pageLoader/pageLoader.redux';

/**
 * @method
 * @summary It loads an author with provided slug, and sets proper data to redux store
 * @param slug - author slug to load
 * @param isPreview {bool} - should load preview version of the page
 * @param _opts {object} - additional options for the request
 **/
function* fetchAuthorSaga({ slug, isPreview, opts: _opts = {} }) {
  try {
    yield put(PageLoaderActions.openPageLoader());
    yield put(AuthorPageActions.setCurrentAuthorPage(slug));

    const authorData = yield select(selectCurrentAuthorPageData);

    if (authorData.size) {
      yield put(PageLoaderActions.closePageLoader());
      return;
    }

    const locale = yield select(selectLocalesLanguage);

    const authorConfig = { 'fields.name[match]': slug.replace('-', ' ') };

    const opts = {
      ..._opts,
      locale,
      'content_type': 'mediaItem',
      ...authorConfig,
    };

    const { items } = isPreview ? yield getPreviewPage(opts) : yield getAuthor(opts);

    if (items.length === 0) {
      yield put(PageActions.fetchError());
    } else {
      yield put(AuthorPageActions.fetchAuthorSuccess(items[0], slug));
    }
  } catch (e) {
    yield put(PageActions.fetchError(e));
  }
  yield put(PageLoaderActions.closePageLoader());
}

export default function* authorPageSaga() {
  yield takeLatest(AuthorPageTypes.FETCH_AUTHOR, fetchAuthorSaga);
}
