import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getHeroImage } from '../../utils/image';

import { Container } from './heroImage.styles';
import { PlatformDetector } from '../platformDetector/platformDetector.component';
import { getDevicePixelRatio } from '../../theme/media';
import { positions } from '../../theme/styled';

export class HeroImage extends PureComponent {
  static propTypes = {
    image: PropTypes.object.isRequired,
    mobileImage: PropTypes.object,
    // Specified in code, overwritten by focalPoint from contentful
    backgroundPosition: PropTypes.string,
    focalPointX: PropTypes.number,
    focalPointY: PropTypes.number,
  };

  static defaultProps = {
    backgroundPosition: positions.topThird,
    focalPointX: null,
    focalPointY: null,
  };

  getImageUrl(activeResolution) {
    const { image, mobileImage } = this.props;
    return getHeroImage(activeResolution, image, mobileImage, getDevicePixelRatio());
  }

  render() {
    const { focalPointX, focalPointY } = this.props;
    const backgroundPosition =
      focalPointX && focalPointY
        ? `${focalPointX}% ${focalPointY}%`
        : this.props.backgroundPosition;
    return (
      <PlatformDetector>
        {({ activeResolution }) => (
          <Fragment>
            <Container
              imageUrl={this.getImageUrl(activeResolution)}
              backgroundPosition={backgroundPosition}
            />
          </Fragment>
        )}
      </PlatformDetector>
    );
  }
}
