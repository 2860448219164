import React from 'react';

const LazySubscribeSection = React.lazy(() => import('./subscribeSection.container'));

const SuspendedSubscribeSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazySubscribeSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedSubscribeSection;
