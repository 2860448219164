import React from 'react';

const LazyOtherWaysToDonate = React.lazy(() => import('./otherWaysToDonate.container'));

const SuspendedOtherWaysToDonate = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyOtherWaysToDonate ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedOtherWaysToDonate;
