import React from 'react';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import connect from 'react-redux/es/connect/connect';
import { useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import compose from 'ramda/es/compose';
import { SideMenu } from './sideMenu.component';
import {
  selectMainMenuLinks,
  selectMenuOpened,
  selectMenuForm,
  selectMenuLinks,
  selectMenuSocialIcons,
  selectMenuTermsLinks,
  selectMenuSocialIconsTitle,
  selectHeaderStyle,
} from '../../reducers/menu/menu.selectors';
import { MenuActions } from '../../reducers/menu/menu.redux';

const mapStateToProps = createStructuredSelector({
  menu: selectMainMenuLinks,
  form: selectMenuForm,
  linksList: selectMenuLinks,
  isOpened: selectMenuOpened,
  socialIcons: selectMenuSocialIcons,
  socialIconsTitle: selectMenuSocialIconsTitle,
  termsLinkList: selectMenuTermsLinks,
  headerStyle: selectHeaderStyle,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  setMenuOpened: MenuActions.setMenuOpened,
}, dispatch);

const Connected = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(SideMenu);

export default props => {
  const routerLocation = useLocation();
  return <Connected {...props} location={routerLocation} />;
};
