import { createActions, createReducer } from 'reduxsauce';
import { Record, List, fromJS } from 'immutable';

export const { Types: PeopleHrTypes, Creators: PeopleHrActions } = createActions({
  fetch: ['url'],
  fetchSuccess: ['jobs'],
  fetchError: ['payload'],
}, { prefix: 'PEOPLEHR_' });

const PeopleHrRecord = new Record({
  jobs: List(),
});

export const INITIAL_STATE = new PeopleHrRecord({});

const fetchSuccessHandler = (state, { jobs }) => state.set('jobs', fromJS(jobs));

export const reducer = createReducer(INITIAL_STATE, {
  [PeopleHrTypes.FETCH_SUCCESS]: fetchSuccessHandler,
});
