import { Map } from 'immutable';
import { createSelector } from 'reselect';

const selectHomePageDomain = state => state.page.homePage;

export const selectCurrentHomePage = createSelector(
  selectHomePageDomain,
  state => state.get('currentHomePage'),
);

export const selectCurrentHomePageData = createSelector(
  selectHomePageDomain,
  selectCurrentHomePage,
  (state, currentHomePage) => state.getIn(['pages', currentHomePage]) || new Map(),
);
