import styled from 'styled-components';
import ReactCountUp from 'react-countup';

import { COLORS, fadeInUp, FONTS } from '../../styles/homePageStyles';
import { media } from '../../../../theme/media';

export const TallyContainer = styled.div`
  --font-size: 0.813rem;

  visibility: ${props => (!props.isOnScreen ? 'hidden' : '')};
  position: relative;
  background: ${COLORS.RED};
  color: ${COLORS.WHITE};
  font-family: ${FONTS.TITLE_FONT};
  font-weight: 700;
  text-transform: uppercase;
  font-size: var(--font-size);
  width: ${props =>
    props.minWidth ? `calc(${props.minWidth + 9} * var(--font-size))` : 'fit-content'};
  height: calc(var(--font-size) * 2.23);
  padding: 0 15px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  clip-path: polygon(
    0% 0%,
    100% 0,
    100% calc(100% - calc(var(--font-size) - 4.5px)),
    calc(100% - calc(var(--font-size) - 1.5px)) 100%,
    0% 100%
  );
  white-space: nowrap;
  ${props => fadeInUp(props.isOnScreen, 0.45)}

  ${media.mobile`
    --font-size: 1rem;
    padding: 0 16px 0 14px;
  `};
`;

export const AnimatedTally = styled(ReactCountUp)`
  text-align: center;
  flex: 1 1;
  margin-right: 3px;
`;
