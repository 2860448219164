import React from 'react';

const LazyWideCardsList = React.lazy(() => import('./wideCardsList.container'));

const SuspendedWideCardsList = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyWideCardsList ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedWideCardsList;
