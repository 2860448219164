import styled, { css } from 'styled-components';
import { Link } from '../link/link.component';
import { BUTTON_RED, BUTTON_GREY } from './button.component';
import { colors } from '../../theme/styled';
import { title } from '../../theme/typography';
import { media } from '../../theme/media';

export const buttonDefaultStyle = css`
  ${title};
  height: ${props => props.small || props.smallOnMobile ? 50 : 60}px;
  line-height: ${props => props.small || props.smallOnMobile ? 50 : 60}px;
  min-width: ${props => props.small || props.smallOnMobile ? 160 : 220}px;
  background-color: ${colors.yellow};
  padding: 0 20px;
  text-align: center;
  color: ${colors.darkGrey};
  display: inline-block;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  border-radius: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  font-size: 15px;

  ${media.tabletLarge`
    height: ${props => props.small ? 50 : 60}px;
    line-height: ${props => props.small ? 50 : 60}px;
    min-width: ${props => props.small ? 160 : 220}px;
  `};

  &.hovered {
    background-color: ${colors.accessibilityYellow};
  }

  &:after {
    content: "";
    display: block !important;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: ${props => props.small || props.smallOnMobile ? 20 : 24}px solid ${colors.accessibilityYellow};
    border-left: ${props => props.small || props.smallOnMobile ? 20 : 24}px solid transparent;

    ${media.tabletLarge`
      border-bottom: ${props => props.small ? 20 : 24}px solid ${colors.accessibilityYellow};
      border-left: ${props => props.small ? 20 : 24}px solid transparent;
    `};
  }
`;

export const buttonRedTriangle = css`
  border-bottom-color: ${colors.hoverRed};
`;

export const buttonRedStyle = css`
  ${buttonDefaultStyle};

  background-color: ${colors.accessibilityRed};
  color: ${colors.white};

  &.hovered {
    background-color: ${colors.hoverRed};
  }

  &:after {
    ${buttonRedTriangle}
  }
`;

export const buttonGreyTriangle = css`
  border-bottom-color: ${colors.black};
`;

export const buttonGreyStyle = css`
  ${buttonDefaultStyle};

  background-color: ${colors.grey};
  color: ${colors.white};

  &.hovered {
    background-color: ${colors.black};
  }

  &:after {
   ${buttonGreyTriangle}
  }
`;

export const buttonDisabledTriangle = css`
  border-bottom-color: ${colors.buttonDisabledSecondary};
`;

export const buttonDisabledStyle = css`
  ${buttonDefaultStyle};
  background-color: ${colors.buttonDisabledPrimary};
  color: ${colors.buttonDisabledFont};
  pointer-events: none;

  &.hovered {
    background-color: ${colors.buttonDisabledPrimary};
  }

  &:after {
   ${buttonDisabledTriangle}
  }
`;

const getButtonStyle = ({ buttonType, disabled }) => {
  if (disabled) {
    return buttonDisabledStyle;
  }

  if (buttonType === BUTTON_RED) {
    return buttonRedStyle;
  }

  if (buttonType === BUTTON_GREY) {
    return buttonGreyStyle;
  }

  return buttonDefaultStyle;
};

export const StyledLink = styled(Link)`${getButtonStyle}`;

export const StyledButton = styled.button`${getButtonStyle}`;
