import styled from 'styled-components';
import prop from 'ramda/es/prop';

export const Line = styled.div`
  position: absolute;
  ${(props) => props.top ? 'top' : 'bottom'}: 0;
  left: 0;
  width: 0;
  height: 0;
  ${(props) => props.top ? 'border-top' : 'border-bottom'}: ${prop('height')}px solid ${prop('color')};
  ${(props) => props.right ? 'border-right' : 'border-left'}
    100vw solid transparent;
`;
