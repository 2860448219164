import React from 'react';
import PropTypes from 'prop-types';

import { Markdown } from '../markdown';
import { OuterContainer, Pad, BUTTON_COLOR_LABEL } from '../styles/homePageStyles';
import { Button } from '../button/button.styles';
import * as Styled from './notchedHeaderSection.styles';
import { SECTION_BACKGROUND_COLOR_LABEL, NOTCHES } from './notchedHeaderSection.styles';

import greenMendedHeartPattern from './assets/greenMendedHeartPattern.svg';
import whiteMendedHeartPattern from './assets/whiteMendedHeartPattern.svg';

const MENDED_HEART_PATTERNS = {
  whiteMendedHeartPattern,
  greenMendedHeartPattern,
};

const NotchedHeaderSection = ({
  headline,
  subheadline,
  notch,
  background,
  buttonLabel,
  buttonUrl,
  buttonColor,
  content,
}) => {
  const hasBackgroundPattern = Object.keys(MENDED_HEART_PATTERNS).includes(background);
  return (
    <Styled.Frame
      bgImg={hasBackgroundPattern && MENDED_HEART_PATTERNS[background]}
      bgColor={!hasBackgroundPattern && background}
      notch={notch}
    >
      <Pad>
        <OuterContainer>
          <Styled.Header>
            <Markdown>{headline.startsWith('##') ? headline : `## ${headline}`}</Markdown>
            <Markdown>{subheadline}</Markdown>
          </Styled.Header>
        </OuterContainer>
      </Pad>
      {!!buttonLabel && !!buttonUrl && (
        <Button href={buttonUrl} color={buttonColor} big>
          {buttonLabel}
        </Button>
      )}
      {content}
    </Styled.Frame>
  );
};

NotchedHeaderSection.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string,
  notch: PropTypes.oneOf(Object.values(NOTCHES)),
  background: PropTypes.oneOf([
    ...Object.values(SECTION_BACKGROUND_COLOR_LABEL),
    ...Object.keys(MENDED_HEART_PATTERNS),
  ]),
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonColor: PropTypes.oneOf(Object.values(BUTTON_COLOR_LABEL)),
  content: PropTypes.node,
};

NotchedHeaderSection.defaultProps = {
  headline: '',
  subheadline: '',
  notch: null,
  background: SECTION_BACKGROUND_COLOR_LABEL.WHITE,
  buttonLabel: '',
  buttonUrl: '',
  buttonColor: BUTTON_COLOR_LABEL.BLUE,
};

export default NotchedHeaderSection;
