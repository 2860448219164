import React from 'react';

const LazySignInList = React.lazy(() => import('./signInList.container'));

const SuspendedSignInList = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazySignInList ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedSignInList;
