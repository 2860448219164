/**
 * Combines sagas for handling page, article, and individual component data fetching
 * @module "page.sagas"
 **/

import { put, takeLatest, fork, all } from 'redux-saga/effects';
import { PageTypes, PageActions } from './page.redux';
import contentPageSaga from './contentPage/contentPage.sagas';
import articlePageSaga from './articlePage/articlePage.sagas';
import authorPageSaga from './authorPage/authorPage.sagas';
import componentPageSaga from './componentPage/componentPage.sagas';
import homePageSaga from './homePage/homePage.sagas';

export function* fetchPageError() {
  yield put(PageActions.setNotFound(true));
}

// 'fork' launches the associated sagas from the saga this is included in.

export default function* pageSaga() {
  yield all([
    fork(contentPageSaga),
    fork(articlePageSaga),
    fork(authorPageSaga),
    fork(componentPageSaga),
    fork(homePageSaga),
    takeLatest(PageTypes.FETCH_ERROR, fetchPageError),
  ]);
}
