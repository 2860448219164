import React from 'react';

const LazyNavigation = React.lazy(() => import('./navigation.container'));

const SuspendedNavigation = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyNavigation ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedNavigation;
