import React from 'react';

const LazyFeaturedArticleHeroSection = React.lazy(() =>
  import('./featuredArticleHeroSection.container'),
);

const SuspendedFeaturedArticleHeroSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyFeaturedArticleHeroSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedFeaturedArticleHeroSection;
