import styled, { css, keyframes } from 'styled-components';
import {
  Section as StyledSection,
  InnerContainer as StyledInnerContainer,
  FONTS,
  COLORS,
  GUTTER_RESPONSIVE,
} from '../../styles/homePageStyles';
import { Link } from '../../link';
import { LoadingSpinner as LoadingSpinnerComponent } from '../../loadingSpinner';
import { media, sizes } from '../../../../theme/media';

const darkGrayTransparent = `${COLORS.DARK_GRAY}59`;

export { PseudoFuzzyBackground } from '../../pseudoFuzzyBackground/pseudoFuzzyBackground.styles';

export const InnerContainer = styled(StyledInnerContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export const Heading = styled.h2`
  font-size: 2.375rem;
  text-transform: uppercase;
  line-height: 1;
  font-family: ${FONTS.TITLE_FONT};
  letter-spacing: -0.03em;
  text-shadow: 0 0 0.25em ${darkGrayTransparent};
  position: relative;
  z-index: 2;

  ${media.mobileSmall`
    font-size: clamp(3rem, 2.274rem + 2.98vw, 5.188rem);
  `}

  ${media.tablet`
    max-width: 7.2em;
  `}
`;

export const Text = styled.div`
  font-family: ${FONTS.MAIN_FONT};
  font-size: 1.125rem;
  max-width: 19em;
  position: relative;
  z-index: 1;

  @media (min-width: ${sizes.mobileSmall}px) and (min-height: 600px) {
    font-size: 1.3125rem;
  }
`;

const slideContentIn = position => keyframes`
  0%, 20% {
    transform: translateX(${position});
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideContentOut = position => keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(${position});
    opacity: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: -5rem;
  position: relative;

  animation: ${({ isSlideActive, navigationDirection }) =>
    isSlideActive
      ? slideContentIn(navigationDirection === 'forwards' ? '1rem' : '-1rem')
      : slideContentOut(navigationDirection === 'forwards' ? '-1rem' : '1rem')}
    ${({ isSlideActive, transitionDuration }) =>
    isSlideActive ? transitionDuration * 1.5 : transitionDuration / 2}s
    ease-out 0s forwards;

  z-index: 2;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

export const BackgroundMedia = styled.div`
  position: absolute;
  inset: 0 0 auto 0;
  height: calc(100% - 9.125rem);
  z-index: -1;
  pointer-events: none;
  transition: transform ${({ transitionDuration }) => transitionDuration}s ease-out;
  transform: scale(${({ isSlideActive }) => (isSlideActive ? 1 : 1.1)});
  will-change: transform;

  ${media.tabletLarge`
    height:100%;
  `}

  @media (prefers-reduced-motion) {
    transition: none;
  }

  img,
  video {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Slide = styled.div`
  position: absolute;
  height: 100%;
  inset: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  overflow: hidden;
  opacity: 0;
  padding-bottom: clamp(8.125rem, 10svh, 13.125rem);

  @media (min-width: ${sizes.mobileSmall}px) {
    padding-top: calc(${({ paddingTop }) => paddingTop}px + clamp(370px, 47.5svh, 475px));
    justify-content: start;
  }

  ${media.tablet`
    justify-content: center;
    padding-top: ${({ paddingTop }) => paddingTop}px;
    padding-bottom: 4vw;
  `};

  ${({ isSlideActive, transitionDuration }) =>
    isSlideActive
      ? css`
          opacity: 1;
          z-index: 1;
          transition:
            opacity ${transitionDuration}s ease-out,
            z-index 0s linear;
        `
      : css`
          opacity: 0;
          pointer-events: none;
          z-index: 0;
          transition:
            opacity 0s linear ${transitionDuration}s,
            z-index 0s linear;
        `}

  &:before,&:after {
    content: '';
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;

    ${media.tabletLarge`
      display: none;
    `}
  }

  &:before {
    bottom: 9.125rem;
    height: 15rem;
    background: linear-gradient(transparent, ${COLORS.MEDIUM_DARK_GRAY});
  }

  &:after {
    top: calc(100% - 9.125rem);
    height: 9.125rem;
    background: ${COLORS.MEDIUM_DARK_GRAY};
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

export const SlideContent = styled(StyledSection)`
  background: transparent;
  color: inherit;
  padding-inline: ${GUTTER_RESPONSIVE};
`;

export const CTA = styled(Link)`
  white-space: nowrap;
  font-size: inherit;
  font-weight: 700;
  border-bottom: none;
`;

export const LoadingSpinner = styled(LoadingSpinnerComponent)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0.15;
`;

export const MediaCredit = styled.div`
  font-size: 0.6875rem;
  line-height: 1;
  font-family: ${FONTS.SMALL_FONT};
  position: absolute;
  right: 1em;
  bottom: 13vw;
  transform: rotate(-90deg);
  z-index: 2;
  opacity: 0.5;
  display: none;

  ${media.tablet`
    display: block;
  `}
`;
export const MediaCreditInner = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  white-space: nowrap;
`;
