import styled from 'styled-components';
import { SocialIcon as SocialIconComponent } from '../socialIcon';

export const SourceIconLink = styled.span``;

export const SocialIcon = styled(SocialIconComponent)`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
