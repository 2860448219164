import styled from 'styled-components';
import { Link as LinkComponent } from '../link/link.component';
import { colors, fonts, fontWeights } from '../../theme/styled';

export const Link = styled(LinkComponent)`
  display: inline-block;
  font-family: ${fonts.titleFont};
  font-size: 15px;
  font-weight: ${fontWeights.bold};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:after {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    background-color: ${props => props.underlineColor || colors.yellow};
    transition: background-color .2s ease-in-out;
    position: relative;
    margin-top: 8px;
  }

  &.hovered {
    &:after {
      background-color: ${props => props.underlineHoverColor || colors.accessibilityYellow};
    }
  }
`;
