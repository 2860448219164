import styled from 'styled-components';
import { Container as ContainerComponent, H2 } from '../../theme/layout';
import { Text as TextComponent } from '../text';
import { Link as LinkComponent } from '../link/link.component';
import { focusOutline } from '../../theme/mixins';
import { text, title, featuredTitle } from '../../theme/typography';
import { BoxShadow } from '../boxShadow/boxShadow.component';
import { media, sizes } from '../../theme/media';
import { CondensedAuthorsList as AuthorComponent } from '../authorsList/condensedAuthorsList';
import { Image as ImageComponent } from '../image';

export const IMAGE_SIZES = {
  [sizes.mobile]: 327,
  [sizes.tabletLarge]: 300,
  [sizes.desktop]: 418,
};

export const Article = styled.article`
  max-width: 418px;
  cursor: pointer;
  position:relative;
  width: 100%;

  ${media.tablet`
    max-width: 31.9%;
   `};

  ${media.desktop`
    max-width: 418px;
    width: 100%;
   `};
`;

export const Container = styled(BoxShadow)`
  cursor: pointer;
  padding-bottom: 65px;
  height: 100%;

  ${media.tabletLarge`
    min-height: 516px;
    padding-bottom: 90px;
  `};
`;

export const Content = styled(ContainerComponent)`
  justify-content: flex-start ;
  align-items: flex-start;
`;

export const ContentWrapper = styled(ContainerComponent)`
  padding: ${(props) => props.hasImage ? '22px 28px' : '28px 28px'};

  ${media.desktop`
    padding: ${(props) => props.hasImage ? '33px 40px 0' : '36px 28px 0'};
  `};
`;

export const ImageContainer = styled(ImageComponent)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  will-change: transform;
  position: relative;

  &.hovered {
    transform: scale(1.1);
  }
`;

export const Image = styled.figure`
  overflow: hidden;
  height: 188px;
  width: 100%;
  display: block;
  flex-shrink: 0;
  margin: 0;

  ${media.tablet`
    height: 125px;
  `};

  ${media.tabletLarge`
    height: 170px;
  `};

  ${media.desktop`
    height: 238px;
  `};
`;

export const Title = styled(H2)`
  ${title};
  font-size: ${(props) => props.hasImage ? '22px' : '28px'};
  padding-bottom: 8px;
  letter-spacing: 0.2px;
  line-height: normal;

  a {
    color: inherit;
  }

  ${media.desktop`
    font-size: ${(props) => props.hasImage ? '22px' : '36px'};
  `};
`;

export const Subtitle = styled.span`
  ${featuredTitle};
  font-size: 16px;
  padding-bottom: 13px;
  letter-spacing: -0.5px;
  line-height: 1.25;

  ${media.desktop`
    padding-bottom: 16px;
  `}
`;

export const Text = styled(TextComponent)`
  ${text};
`;

export const Link = styled(LinkComponent)`
  &:focus > div:first-child {
    ${focusOutline};
  }
`;

export const Author = styled(AuthorComponent)`
  position: absolute;
  bottom: 32px;
  left: 0;
  padding-left: 28px;
  width: 100%;

  ${media.tabletLarge`
    padding-left: 40px;
  `};
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`;
