import styled from 'styled-components';
import { FONTS, fadeInUp } from '../../styles/homePageStyles';
import { media } from '../../../../theme/media';

export const Stat = styled.div`
  ${props => fadeInUp(props.isOnScreen, 0.75)}
  visibility: ${props => (props.isOnScreen ? 'visible' : 'hidden')};
  font-family: ${FONTS.TITLE_FONT};
  font-weight: 700;
  font-size: 3.125rem;
  margin: -3px 0 9px;

  ${media.tabletLarge`
    font-size: 4.25rem;
    margin: -5px 0 5px;
  `}
`;

export const PostCountUpDecorator = styled.span`
  position: absolute;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.25s;
`;
