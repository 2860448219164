import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { PageLoader } from './pageLoader';

import { Modal } from '../../components/modal';
import { VideoModal } from '../../components/video/videoModal';
import { Navigation } from '../navigation';
import { Footer } from '../footer';
import { Banner } from '../../components/banner';
import { PreApp } from '../preApp';
import { SlideoutPanel } from '../slideoutPanel';
import { SideMenu } from '../sideMenu';
import { HeaderStyle } from '../../reducers/menu/menu.redux';
import { Curtain } from '../../components/curtain';

import { FixedWrap } from './sharedLayout.styles';

export class SharedLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isInitialLoaded: PropTypes.bool.isRequired,
    headerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    fetchAccessories: PropTypes.func.isRequired,
    curtain: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.curtainRef = React.createRef();
    this.state = {
      isCurtainOnScreen: true,
    };
  }

  componentDidMount() {
    this.props.fetchAccessories();
    if (this.isCurtained()) {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }
  componentDidUpdate() {
    if (this.isCurtained()) {
      window.removeEventListener('scroll', this.handleScroll);
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    if (this.isCurtained()) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  isCurtained = () => {
    return this.props.curtain.size !== 0;
  };

  handleScroll = () => {
    if (!this.curtainRef.current) {
      return;
    }

    const curtainHeight = this.curtainRef.current.offsetHeight;
    const scrollPosition = Math.ceil(
      document.documentElement.scrollTop || document.body.scrollTop || 0,
    );

    if (scrollPosition < curtainHeight) {
      this.setState({ isCurtainOnScreen: true });
    } else {
      this.setState({ isCurtainOnScreen: false });
    }
  };

  rollUpCurtain = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: this.curtainRef.current.offsetHeight,
    });
  };

  render() {
    const { isInitialLoaded, children, headerStyle } = this.props;

    if (!isInitialLoaded) {
      return null;
    }

    // Put nav in preApp if unless it should be absolutely positioned on page.
    const isHeaderMinimal = headerStyle === HeaderStyle.MINIMAL;

    return (
      <Fragment>
        <PageLoader />
        {this.isCurtained() && (
          <div ref={this.curtainRef}>
            <Curtain isOnScreen={this.state.isCurtainOnScreen} rollUpCurtain={this.rollUpCurtain} />
          </div>
        )}
        <PreApp>
          <FixedWrap
            id="thl-header-section"
            isFixed={this.isCurtained() && this.state.isCurtainOnScreen}
          >
            {this.isCurtained() || (
              <React.Fragment>
                <Banner />
              </React.Fragment>
            )}

            {isHeaderMinimal ? null : (
              <Navigation
                isCurtained={this.isCurtained()}
                isManuallyFixed={this.isCurtained() && this.state.isCurtainOnScreen}
              />
            )}
          </FixedWrap>
        </PreApp>
        <SlideoutPanel>
          <SideMenu />
        </SlideoutPanel>
        <FixedWrap
          as="main"
          role="main"
          tabIndex="-1"
          isFixed={this.isCurtained() && this.state.isCurtainOnScreen}
        >
          {isHeaderMinimal ? <Navigation /> : null}
          {children}
        </FixedWrap>
        {(!this.state.isCurtainOnScreen || !this.isCurtained()) && <Footer />}
        <VideoModal />
        {!this.isCurtained() && <Modal />}
      </Fragment>
    );
  }
}
