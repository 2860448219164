const SAME_TAB_THL_DOMAINS = ['thehumaneleague.org', 'secure.everyaction.com'];
const NEW_TAB_THL_DOMAINS = ['openwingalliance.org', 'animalpolicyalliance.org'];
const THL_DOMAINS = [...SAME_TAB_THL_DOMAINS, ...NEW_TAB_THL_DOMAINS];
const NO_PARAMS_THL_PATHS = ['/single-offer-career'];

const urlContains = (url, pathsOrDomains) =>
  pathsOrDomains.some(pathOrDomain => url.includes(pathOrDomain));

export const isInternalLink = url => url.startsWith('/') || url.startsWith(window.location.origin);

const isSameTabLink = url =>
  isInternalLink(url) ||
  (urlContains(url, SAME_TAB_THL_DOMAINS) && !urlContains(url, NEW_TAB_THL_DOMAINS));

const shouldAppendParams = url =>
  (isInternalLink(url) || urlContains(url, THL_DOMAINS)) && !urlContains(url, NO_PARAMS_THL_PATHS);

const EXTERNAL_LINK_PROPS = {
  target: '_blank',
  rel: 'noreferrer noopener',
};

// Gets props specifying that the link should open in a new tab for all new-tab-THL or external links
export const getExternalLinkProps = url => (isSameTabLink(url) ? {} : EXTERNAL_LINK_PROPS);

/* eslint-disable camelcase */
const HOME_PAGE_PARAMS = {
  utm_medium: 'web',
  utm_source: 'home',
  ms: 'c_THW-Home',
};
/* eslint-enable camelcase */

// Appends home page params and any additional user-defined params to THL and internal links
export const appendHomePageParams = (url, additionalParams = {}) => {
  if (shouldAppendParams(url)) {
    const urlObj = new URL(url, window.location.origin);
    Object.entries({ ...HOME_PAGE_PARAMS, ...additionalParams }).forEach(
      ([paramName, paramValue]) => {
        // Overrides previously set values for these params
        urlObj.searchParams.set(paramName, paramValue);
      },
    );
    // Strip internal URLs of their origin to format for React Router
    const formattedUrl = isInternalLink(url)
      ? urlObj.href.split(window.location.origin)[1]
      : urlObj.href;
    return formattedUrl;
  }
  return url;
};
