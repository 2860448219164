import { createSelector } from 'reselect';

const selectAuthorPageDomain = state => state.page.authorPage;

export const selectCurrentAuthorPage = createSelector(
  selectAuthorPageDomain,
  state => state.get('currentAuthor'),
);

export const selectCurrentAuthorPageData = createSelector(
  selectAuthorPageDomain,
  selectCurrentAuthorPage,
  (state, currentPage) => state.getIn(['authors', currentPage]) || new Map(),
);

// export const selectAuthorMeta = createSelector(
// selectAuthorPageDomain,
// state => state.getIn(['currentAuthor', 'sys']),
// );
