import UAParser from 'ua-parser-js';

const parser = new UAParser();

const ua = parser.getUA();

const { name: browser } = parser.getBrowser();

const { name: os } = parser.getOS();

export const isPrerenderBot = ua.includes('HeadlessChrome') || ua.includes('Netlify Prerender');

// unused
// export const isFirefox = browser === 'Firefox';

export const isEdge = browser === 'Edge';

export const isIE = browser === 'IE';

export const isIos = os === 'iOS';

