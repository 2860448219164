import React from 'react';

const LazyBadgesSection = React.lazy(() => import('./badgesSection.container'));

const SuspendedBadgesSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyBadgesSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedBadgesSection;
