/**
 * Sagas for handling data fetching for preview component pages
 * @module "componentPage.sagas"
 **/

import { createActions, createReducer } from 'reduxsauce';
import { Record, fromJS } from 'immutable';

export const { Types: ComponentPageTypes, Creators: ComponentPageActions } = createActions({
  // Kick off saga
  fetchContentfulComponent: ['sysid'],
  // Store returned data
  fetchContentfulComponentSuccess: ['component'],
}, { prefix: 'COMPONENT_PAGE_' });

const ComponentRecord = new Record({
  currentContentfulComponent: null,
});

export const INITIAL_STATE = new ComponentRecord({});

const fetchContentfulComponentSuccessHandler = (state, { component }) => {
  return state.set('currentContentfulComponent', fromJS(component));
};

export const reducer = createReducer(INITIAL_STATE, {
  [ComponentPageTypes.FETCH_CONTENTFUL_COMPONENT_SUCCESS]: fetchContentfulComponentSuccessHandler,
});
