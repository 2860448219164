import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Highlight, Word, HIGHLIGHT_COLOR_LABEL } from './textHighlight.styles';

const TextHighlight = ({
  children,
  highlightBackgroundColor,
  highlightTextColor,
  isActive,
  theme,
}) => {
  const [isReversed, setIsReversed] = useState(isActive);

  useEffect(() => {
    setIsReversed(prevState => !prevState);
  }, [isActive]);

  const wordsArray = children.split(' ');
  let elapsedTime = 0;

  return (
    <React.Fragment>
      <Highlight
        highlightBackgroundColor={highlightBackgroundColor}
        highlightTextColor={highlightTextColor}
        theme={theme}
      >
        {wordsArray.map((word, index) => {
          const wordLength = word.length > 3 ? (word.length - 3) * 0.1 : 0;
          const wordSpeed =
            !index || index === wordsArray.length - 1 ? wordLength + 0.2 : wordLength;
          const delayTime = elapsedTime;
          elapsedTime += wordSpeed;
          return (
            <Word
              isActive={isActive}
              delay={delayTime}
              isReversed={isReversed}
              duration={wordSpeed}
              key={index + word}
            >
              {word}
            </Word>
          );
        })}
      </Highlight>
    </React.Fragment>
  );
};

TextHighlight.propTypes = {
  children: PropTypes.string.isRequired,
  highlightBackgroundColor: PropTypes.string,
  highlightTextColor: PropTypes.string,
  isActive: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(HIGHLIGHT_COLOR_LABEL)),
};

TextHighlight.defaultProps = {
  isActive: false,
  theme: HIGHLIGHT_COLOR_LABEL.GREEN,
};

export default TextHighlight;
