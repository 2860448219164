import styled from 'styled-components';
import { COLORS, GUTTER_RESPONSIVE, H2 } from '../styles/homePageStyles';
import { text } from '../../../theme/typography';
import { media } from '../../../theme/media';

export const MissionBreakoutWrapper = styled.div`
  ${text}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: 40.45rem;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  a {
    display: inline-block;
    z-index: 1;
  }

  p {
    color: ${COLORS.WHITE};
  }
`;

export const Header = styled(H2)`
  z-index: 1;
  color: ${COLORS.WHITE};
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;

  span {
    svg {
      display: none;

      ${media.tabletLarge`
        display: inline;
        margin: 0 1.5rem -.2rem 1.7rem;
      `};
    }
  }

  em {
    font-weight: normal;
    margin-right: 0.7rem;

    ${media.tabletLarge`
      margin-right: auto;
    `};
  }

  ${media.tabletLarge`
    font-size: 42.5px;
  `};
`;

export const Text = styled.p`
  z-index: 1;
  color: ${COLORS.WHITE};
  max-width: 75ch;
`;

export const TextContainer = styled.div`
  padding-inline: ${GUTTER_RESPONSIVE};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;
  ${media.tabletLarge`
    gap: 23px;
  `};
`;

export const VideoEmbed = styled.div`
  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
