import React, { useEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import PropTypes from 'prop-types';
import * as Styled from './animatedStat.styles';
import { useOnScreen } from '../../utils/useOnScreen';

export const COUNT_UP_DURATION = 2;
const COUNT_UP_OFFSET = COUNT_UP_DURATION / 6;
const VALID_DECORATORS = ['+'];

const AnimatedStat = ({ stat }) => {
  const statRef = useRef();
  const isOnScreen = useOnScreen(statRef, '0px 0px -50px 0px', 0.5, 0, true);
  const [countUpDone, setCountUpDone] = useState(false);

  // Seperate count from prefix and suffix for animation
  const splitStat = stat.split(/[0-9]+[\.]*[0-9]*/);
  const count = stat.match(/[0-9]+[\.]*[0-9]*/)[0];
  let decimals = count.split('.')[1];
  decimals = decimals ? decimals.length : 0;
  const prefix = splitStat[0];
  let suffix = splitStat[splitStat.length - 1];

  // Separate decorator from rest of suffix to animate in later
  let postCountUpDecorator;
  VALID_DECORATORS.forEach(decorator => {
    if (!postCountUpDecorator && suffix.endsWith(decorator)) {
      suffix = suffix.split(decorator)[0];
      postCountUpDecorator = decorator;
    }
  });

  const { countUp, start } = useCountUp({
    // Dynamically set start count so that all stats animate at the same rate
    start: Math.max(count - 100 / 10 ** decimals, 0).toFixed(decimals),
    end: count,
    duration: COUNT_UP_DURATION,
    separator: ',',
    decimals,
    prefix,
    suffix,
  });

  useEffect(() => {
    if (isOnScreen) {
      start();
      setTimeout(() => {
        setCountUpDone(true);
      }, (COUNT_UP_DURATION - COUNT_UP_OFFSET) * 1000);
    }
  }, [isOnScreen]);

  // Note: PostCountUpDecorator wrapper is always rendered to prevent animation issues in Safari
  return (
    <Styled.Stat ref={statRef} isOnScreen={isOnScreen}>
      {countUp}
      <Styled.PostCountUpDecorator visible={countUpDone} aria-hidden={!postCountUpDecorator}>
        {postCountUpDecorator}
      </Styled.PostCountUpDecorator>
    </Styled.Stat>
  );
};

AnimatedStat.propTypes = {
  stat: PropTypes.string.isRequired,
};

export default AnimatedStat;
