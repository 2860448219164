import styled from 'styled-components';
import { colors, fonts } from '../../theme/styled';
import { media } from '../../theme/media';

export const Container = styled.figure`
  margin: 0 auto;
  font-family: ${fonts.smallFont};
`;

export const Image = styled.div`
  position: relative;
  font-size: 0;
  
  img {
    width: 100%;
  }
`;

export const Caption = styled.figcaption``;

export const Description = styled.span`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.63;
  display: block;
  color: ${colors.darkGrey};
  
  ${media.tablet`
    margin-top: 16px;
  `};
`;
export const Credits = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.83;
  color: ${colors.darkGrey};
`;
