import { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Wraps children element with a safe boundary.
 * When any error is thrown inside rendered elements,
 * it will just fallback to not-displaying the section instead of breaking the app.
 **/
export class SectionErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.log('Oops. Something went wrong and this section was not displayed correctly :(');
    // eslint-disable-next-line no-console
    console.log(error, info);
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}
