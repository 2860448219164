import React from 'react';

const LazyFeaturedInSection = React.lazy(() => import('./featuredInSection.container'));

const SuspendedFeaturedInSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyFeaturedInSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedFeaturedInSection;
