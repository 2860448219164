/**
 * Redux components for handling article manipulation. See also articlePage.sagas
 * @module "articlePage.redux"
 **/

import { createActions, createReducer } from 'reduxsauce';
import { Record, Map, fromJS } from 'immutable';

export const { Types: ArticlePageTypes, Creators: ArticlePageActions } = createActions({
  // Kicks off saga
  fetchArticle: ['slug', 'isPreview', 'opts'],
  // Saves data returned from contentful
  fetchArticleSuccess: ['items'],
  // Removes currently stored article
  resetArticle: [],
}, { prefix: 'ARTICLE_PAGE_' });

const ArticleRecord = new Record({
  currentArticle: Map(),
});

export const INITIAL_STATE = new ArticleRecord({});

const fetchArticleSuccessHandler = (state, { items }) => state.set('currentArticle', fromJS(items));

const handleArticleReset = state => state.set('currentArticle', new Map());

export const reducer = createReducer(INITIAL_STATE, {
  [ArticlePageTypes.FETCH_ARTICLE_SUCCESS]: fetchArticleSuccessHandler,
  [ArticlePageTypes.RESET_ARTICLE]: handleArticleReset,
});
