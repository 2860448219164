/**
 * Decorator providing utility functions for mediaItem: image content models.
 *
 * @module MediaItemImageDecorator
 **/

import Decorator from './decorator';
import ImageDecorator from './image.decorator';
import { getField } from '../utils/cmsData';

export default class MediaItemImageDecorator extends Decorator {
  /**
   * Returns the an image decorator decorating the image field.
   *
   * @public
   * @return {ImageDecorator} Decorated image data.
   */
  get image() {
    return new ImageDecorator(getField('image', this.data));
  }

  /**
   * Returns the an image decorator decorating the mobileimage field.
   *
   * @public
   * @return {ImageDecorator} Decorated image data.
   */
  get mobileImage() {
    if (getField('mobileImage', this.data)) {
      return new ImageDecorator(getField('mobileImage', this.data));
    }
    return this.image;
  }

  /**
   * Returns the type of the media item.
   *
   * @public
   * @return {type}
   */
  get type() {
    return getField('type', this.data);
  }

  /**
   * Returns the video id of the media item.
   *
   * @public
   * @return {videoId}
   */
  get videoId() {
    return getField('videoId', this.data);
  }
}
