import React from 'react';

const LazyFooter = React.lazy(() => import('./footer.container'));

const SuspendedFooter = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyFooter ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedFooter;
