import React from 'react';

const LazyWideImageSection = React.lazy(() => import('./wideImageSection.container'));

const SuspendedWideImageSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyWideImageSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedWideImageSection;
