/**
 * Decorator providing utility functions for external embed content models.
 *
 * @module AuthorDecorator
 **/

import Decorator from './decorator';
import { getField } from '../utils/cmsData';

export default class ExternalEmbedDecorator extends Decorator {
  /**
   * Returns the author name.
   *
   * @public
   * @return {string} The name, or an empty string if absent.
   */
  get name() {
    return getField('name', this.data) || '';
  }

  get scriptSrc() {
    return getField('scriptSrc', this.data) || '';
  }

  get content() {
    // Note, embeded mispelled in contentful!
    return getField('embededElement', this.data) || '';
  }
}
