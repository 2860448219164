/**
 * Sagas for handling ordinary page data fetching
 * @module "contentPage.sagas"
 **/

import { createActions, createReducer } from 'reduxsauce';
import { Record, Map, fromJS } from 'immutable';
import landingPage from '../../../data/landingPage';

export const { Types: ContentPageTypes, Creators: ContentPageActions } = createActions({
  // Kicks off saga
  fetchContentPage: ['slug', 'isPreview', 'opts'],
  // Saves data returned from contentful in a map, for easy restore.
  fetchContentPageSuccess: ['items', 'slug'],
  // Changes official current page.
  setCurrentContentPage: ['id'],
}, { prefix: 'CONTENT_PAGE_' });

const ContentRecord = new Record({
  currentPage: Map(),
  pages: Map({ '': fromJS(landingPage) }),
});

export const INITIAL_STATE = new ContentRecord({});

const fetchContentPageSuccessHandler = (state, { items, slug }) => {
  return state.setIn(['pages', slug], fromJS(items));
};
const handleContentPageChanged = (state, { id: pageId }) => state.set('currentPage', pageId);

export const reducer = createReducer(INITIAL_STATE, {
  [ContentPageTypes.FETCH_CONTENT_PAGE_SUCCESS]: fetchContentPageSuccessHandler,
  [ContentPageTypes.SET_CURRENT_CONTENT_PAGE]: handleContentPageChanged,
});
