import { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

/**
 * It adds a window event listener
 **/
export class WindowListener extends Component {
  /**
   * PropTypes
   * @param throttleValue {number} - throttle (in ms) for the event listener fucntion
   * @param handledFunction {function} - function to call then the _eventType_ event is invoked
   * @param handledFunction {function} - function to call then the _eventType_ event is invoked
   * @param eventType {string} - event type on window to listen to
   * @param options {object} - additional listener options
   **/
  static propTypes = {
    throttleValue: PropTypes.number,
    handledFunction: PropTypes.func,
    eventType: PropTypes.string,
    options: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.handler = this.getHandledFunction();
  }

  componentDidMount() {
    window.addEventListener(this.props.eventType, this.handler, this.props.options);
  }

  componentWillUnmount() {
    window.removeEventListener(this.props.eventType, this.handler, this.props.options);
  }

  getHandledFunction() {
    if (!this.props.throttleValue) {
      return this.props.handledFunction;
    }
    return throttle(this.props.handledFunction, this.props.throttleValue, { 'leading': true, 'trailing': true });
  }

  render() {
    return null;
  }
}
