/**
 * Utils for Netlify prerendering
 * We need to apply a specific logic for extracting styled-components styles to be visible by Netlify prerender bot.
 * @module
 **/

import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import join from 'ramda/es/join';
import pipe from 'ramda/es/pipe';
import path from 'ramda/es/path';

let _currentStyles = null;

const _createStyleElement = () => {
  const tag = document.createElement('style');
  tag.setAttribute('data-populated-styles', 'true');
  document.head.appendChild(tag);
  return tag;
};

const _extractTag = pipe(
  path(['sheet', 'cssRules']),
  map(prop('cssText')),
  join(' '),
);

const _extractAllTags = () => {
  const scTags = document.querySelectorAll('[data-styled]');
  return pipe(
    map(_extractTag),
    join(' ')
  )(scTags);
};

const _flushStylesIntoElement = target => {
  const newStyles = _extractAllTags();
  if (newStyles !== _currentStyles) {
    target.innerHTML = newStyles;
  }
  _currentStyles = newStyles;
};

export const syncStylesTags = () => {
  const targetTag = _createStyleElement();
  setInterval(() => _flushStylesIntoElement(targetTag), 200);
};
