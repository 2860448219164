import styled, { css } from 'styled-components';
import { media } from '../../../../theme/media';
import { GUTTER_RESPONSIVE, COLORS, FADE_IN_UP_DISTANCE } from '../../styles/homePageStyles';
import { ImageContainer } from '../storyBlock/storyBlock.styles';

export const StoryWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  visibility: ${props => (props.isOnScreen ? '' : 'hidden')};
  transition: opacity 0.3s;
  opacity: ${props => (props.isOnScreen ? 1 : 0)};

  > div {
    min-width: 100vw;
    padding: 0 ${GUTTER_RESPONSIVE};
  }

  a,
  ${ImageContainer} {
    pointer-events: ${props => (props.dragging ? 'none' : '')};
  }
`;

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  max-width: 100vw;
  // Prevents tally embellishments from clipping when they fade in/up
  padding-bottom: ${FADE_IN_UP_DISTANCE};
  margin-bottom: -${FADE_IN_UP_DISTANCE};

  &,
  ${ImageContainer} {
    pointer-events: ${props => (props.activelyScrolling ? 'none' : '')};
  }
`;

export const DotNavigation = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 38px;

  ${media.tabletLarge`
    display: none;
  `};
`;

const DOT_FLY_IN_DURATION = 0.3;

const dotFlyIn = delay =>
  css`
    --animation: ${DOT_FLY_IN_DURATION}s ${delay}s;
    transition: transform var(--animation), opacity var(--animation);
    transform: translateX(-50%);
    opacity: 0;

    ${props =>
    props.isOnScreen &&
      css`
        transform: translateX(0px);
        opacity: 1;
      `};
  `;

export const DotButton = styled.button`
  font-size: 0rem;
  width: 14px;
  max-width: 14px;
  height: 14px;
  max-height: 14px;
  margin: 0 11px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${props => (props.active ? COLORS.RED : '#c6c8ca')};

  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${props =>
    css`
      :nth-child(${props.dotNumber}) {
        ${dotFlyIn(DOT_FLY_IN_DURATION * (props.dotNumber / props.totalDots))}
      }
    `}
`;
