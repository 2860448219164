import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './tally.styles';
import { useOnScreen } from '../../utils/useOnScreen';

const DIGIT_WIDTHS_IN_REM = {
  0: 0.68,
  1: 0.393,
  2: 0.591,
  3: 0.593,
  4: 0.689,
  5: 0.596,
  6: 0.638,
  7: 0.62,
  8: 0.66,
  9: 0.638,
  comma: 0.263,
};

// Pre-calculate width of final count to ensure container does not resize during count up
const computeNumberWidth = number => {
  const digitArray = `${number}`.split('');
  const rawNumberWidth = digitArray.reduce(
    (totalWidth, digit) => totalWidth + DIGIT_WIDTHS_IN_REM[digit],
    0,
  );
  const commaCount = Math.floor((digitArray.length - 1) / 3);
  const numberWidth = rawNumberWidth + commaCount * DIGIT_WIDTHS_IN_REM.comma;
  return numberWidth;
};

const Tally = ({ petitionId, fallbackText }) => {
  const containerRef = useRef(null);
  const isOnScreen = useOnScreen(containerRef, 0, 0.5, 500, true);
  const [tally, setTally] = useState(null);
  const tallyWidth = computeNumberWidth(tally);
  const showTally = tally && tally >= 2000;

  useEffect(() => {
    if (!petitionId) {
      return;
    }
    (async function fetchAndSet() {
      try {
        const result = await fetch(
          `https://secure.everyaction.com/v2/forms/${petitionId}/progress`,
        );
        const json = await result.json();
        if (!json) {
          return;
        }
        setTally(json.totalSubmissionProgress);
      } catch (error) {
        return;
      }
    })();
  }, []);

  return (
    <Styled.TallyContainer
      ref={containerRef}
      isOnScreen={isOnScreen}
      minWidth={showTally ? tallyWidth : null}
    >
      {showTally && isOnScreen ? (
        <React.Fragment>
          <Styled.AnimatedTally
            start={tally - 100}
            end={tally}
            duration={2.5}
            minWidth={tallyWidth}
            separator={','}
          ></Styled.AnimatedTally>
          <span>signatures</span>
        </React.Fragment>
      ) : (
        fallbackText || 'Trending'
      )}
    </Styled.TallyContainer>
  );
};

Tally.propTypes = {
  petitionId: PropTypes.string,
  fallbackText: PropTypes.string,
};

export default Tally;
