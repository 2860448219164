import React from 'react';

const LazyCardListThreeColumns = React.lazy(() => import('./cardListThreeColumns.container'));

const SuspendedCardListThreeColumns = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyCardListThreeColumns ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedCardListThreeColumns;
