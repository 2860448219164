import { css } from 'styled-components';
import { colors } from './styled';
import { noTouchHover } from './media';
import {
  ENTERED_CLASS_NAME,
  ANIMATION_OFFSET,
} from '../components/animatedEnter/animatedEnter.component';

export const fillAbsolute = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const centerAbsolute = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const simpleHover = css`
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.75;
  }
`;

export const focusOutline = css`
  outline: 2px solid ${colors.yellow};
  outline-offset: 1px;
`;

export const visuallyHidden = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const linkStyle = color => css`
  display: inline;
  transition: background-color 0.2s ease-in-out;
  border-bottom: 2px solid ${color};
  word-wrap: break-word;

  ${noTouchHover`
    background-color: ${color};
  `}

  &:focus {
    outline: 0;
    background-color: ${color};
  }
`;

export const yellowLink = linkStyle(colors.yellow);
export const redLink = linkStyle(colors.red);

export const entryAnimation = css`
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(${ANIMATION_OFFSET}px);
  opacity: 0;

  &.${ENTERED_CLASS_NAME} {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const entryAnimationReversed = css`
  ${entryAnimation};
  transform: translateY(-${ANIMATION_OFFSET}px);
`;
