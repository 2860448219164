import { Map } from 'immutable';
import { createSelector } from 'reselect';

const selectContentPageDomain = state => state.page.contentPage;

export const selectCurrentContentPage = createSelector(
  selectContentPageDomain,
  state => state.get('currentPage'),
);

export const selectCurrentContentPageData = createSelector(
  selectContentPageDomain,
  selectCurrentContentPage,
  (state, currentPage) => state.getIn(['pages', currentPage]) || new Map(),
);
