import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Image, IMAGE_SIZES } from './badges.styles';
import { getField } from '../../utils/cmsData';
import { ConditionalWrapper } from '../../utils/conditionalWrapper';
import { Link } from '../link/link.component';

export class Badges extends PureComponent {
  static propTypes = {
    items: PropTypes.object,
    ImageWrapper: PropTypes.any,
  };

  render() {
    const { items, ImageWrapper } = this.props;

    if (!items) {
      return null;
    }

    return (
      <React.Fragment>
        {items.map(item => {
          const badgeLink = getField(['url', 'fields', 'url'], item);
          return (
            <ImageWrapper>
              <ConditionalWrapper
                condition={badgeLink}
                wrapper={children => <Link url={badgeLink}>{children}</Link>}
              >
                <Image
                  key={item.getIn(['sys', 'id'])}
                  src={getField(['image', 'fields', 'file', 'url'], item)}
                  sizes={IMAGE_SIZES}
                  alt={getField('title', item)}
                  imageWidth={getField('width', item)}
                />
              </ConditionalWrapper>
            </ImageWrapper>
          );
        })}
      </React.Fragment>
    );
  }
}
