import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import compose from 'ramda/es/compose';

import messages from './donateBox.messages';

import {
  Container,
  Form,
  TextContainer,
  Title,
  Description,
  InputContainer,
  Prefix,
  Input,
  Button,
  CheckboxContainer,
  Checkbox,
  Label,
} from './donateBox.styles';
import { BUTTON_RED } from '../button/button.component';
import { donateTracker, Status } from '../iframeForm/form/tracker';
import { renderWhenTrueOtherwise } from '../../utils/rendering';
import { AnimatedEnter } from '../animatedEnter';
import { getField } from '../../utils/cmsData';

export class DonateBoxComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string,
    backgroundColor: PropTypes.string,
    defaultAmount: PropTypes.number,
    isRecurring: PropTypes.bool,
    isCutCorner: PropTypes.bool,
    intl: PropTypes.object.isRequired,
  };

  state = {
    value: this.props.defaultAmount || this.props.intl.formatMessage(messages.placeholder),
    isRecurring: this.props.isRecurring || false,
  };

  getInputValue = () => {
    this.setState({ value: this.input.current.value }, () => {
      if (this.state.value) {
        this.startDonationFunnel();
      }
    });
  };

  getCheckboxValue = () => {
    this.setState({ isRecurring: this.checkbox.current.checked }, this.startDonationFunnel);
  };

  registerClick = () => {
    dataLayer.push({
      event: 'Click',
      elementType: 'Button',
      element: 'Donate Box',
      amount: this.state.value,
    });
  };

  startDonationFunnel = () => {
    donateTracker.updateStatus(Status.INITIALISED);
  };

  get buttonUrl() {
    const { value, isRecurring } = this.state;
    const { url: urlModel } = this.props;
    let url = '/donate';
    if (urlModel) {
      url = getField('url', urlModel);
    }
    const extraParams = '&utm_source=homepage&utm_content=hero+donate';
    const amountParameter = this.props.intl.formatMessage(messages.amountParameter);
    const recurParameter = this.props.intl.formatMessage(messages.recurParameter);
    const recurringValue = this.props.intl.formatMessage(
      isRecurring ? messages.recurTrue : messages.recurFalse,
    );

    return url.includes('?')
      ? `${url}&${amountParameter}=${value}&${recurParameter}=${recurringValue}${extraParams}`
      : `${url}?${amountParameter}=${value}&${recurParameter}=${recurringValue}${extraParams}`;
  }

  input = createRef();
  checkbox = createRef();
  formRef = createRef();
  renderForm = () => (
    <Form id="donateBox" ref={this.formRef}>
      <TextContainer>
        <Title>{this.props.title}</Title>
        <Description>{this.props.description}</Description>
      </TextContainer>
      <InputContainer>
        <Prefix>
          <FormattedMessage {...messages.prefix} />
        </Prefix>
        <FormattedMessage {...messages.placeholder}>
          {msg => (
            <Input
              ref={this.input}
              aria-label="Donate input form"
              type="number"
              step="1"
              placeholder={this.props.defaultAmount || msg}
              onChange={this.getInputValue}
            />
          )}
        </FormattedMessage>
        <Button
          type="submit"
          form="donateBox"
          buttonType={BUTTON_RED}
          url={this.buttonUrl}
          onClick={this.registerClick}
        >
          <FormattedMessage {...messages.buttonLabel} />
        </Button>
        <CheckboxContainer>
          <Checkbox
            ref={this.checkbox}
            type="checkbox"
            id="donateBoxCheckbox"
            onChange={this.getCheckboxValue}
            checked={this.state.isRecurring}
          />
          <Label htmlFor="donateBoxCheckbox" tabIndex={-1}>
            <FormattedMessage {...messages.checkboxLabel} />
          </Label>
        </CheckboxContainer>
      </InputContainer>
    </Form>
  );

  renderCutCorner = renderWhenTrueOtherwise(
    () => <Container backgroundColor={this.props.backgroundColor}>{this.renderForm()}</Container>,
    () => this.renderForm(),
  );

  render = () => (
    <AnimatedEnter markerRef={this.formRef} delay={100}>
      {this.renderCutCorner(this.props.isCutCorner)}
    </AnimatedEnter>
  );
}

export const DonateBox = compose(injectIntl)(DonateBoxComponent);
