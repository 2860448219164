import axios from 'axios';
import parseXml from 'xml-parser';
import qs from 'qs';
import path from 'ramda/es/path';

const PROXY_HOST = new String(window.location).includes('localhost') ?
  'http://localhost:9000/'
  : `${window.origin}/.netlify/functions/`;
const PROXY_URL = PROXY_HOST + 'peopleHrProxy';
const PATH_TO_JOBS = ['root', 'children', 0, 'children'];

export const getJobsFromUrl = async (url) => {
  const response = await axios.get(`${PROXY_URL}?${qs.stringify({ url: url })}`);
  const xml = parseXml(response.data);
  const jobs = path(PATH_TO_JOBS, xml)
    .filter(job => job.name === 'item')
    .map(job => job.children
      .reduce((accum, child) => Object.assign(accum, { [child.name]: child.content })));
  return jobs;
};
