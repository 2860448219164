import localesDataList from './localesData.json';
import './loadIntlLocales';
import { DEFAULT_LOCALE_ID } from './config';

const translations = require.context('../translations', false, /\.json$/);

const supportedLocaleIds = localesDataList.map(locale => locale.id);

export const formatTranslationMessages = (localeId, messages) => {
  const defaultFormattedMessages =
    localeId !== DEFAULT_LOCALE_ID
      ? formatTranslationMessages(DEFAULT_LOCALE_ID, translations(`./${DEFAULT_LOCALE_ID}.json`))
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && localeId !== DEFAULT_LOCALE_ID
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = supportedLocaleIds.reduce((accum, localeId) => {
  accum[localeId] = formatTranslationMessages(localeId, translations(`./${localeId}.json`));
  return accum;
}, {});
