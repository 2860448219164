import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SectionBackground } from './sectionBackground.component';


const mapStateToProps = createStructuredSelector({ });

export const mapDispatchToProps = (dispatch) => bindActionCreators({ }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SectionBackground);
