/**
 * Contains utils for Contentful CMS connection
 *
 * @module
 **/

import { createClient } from 'contentful';
import pick from 'ramda/es/pick';
import compose from 'ramda/es/compose';
import map from 'ramda/es/map';
import over from 'ramda/es/over';
import lensProp from 'ramda/es/lensProp';
import removeCycles from '../utils/removeCycles';
import expose from '../utils/expose';

const PAGE = 'page';

/**
 * @method
 * @param prop - config variable name
 * @param fallbackEnv - env variable name to return if the current
 * domain config doesn't contain the specified _prop_ variable
 *
 * @return CMS connection config variable based on domain
 **/

expose({ contentfulEnvironment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT });

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  host: process.env.REACT_APP_CONTENTFUL_URL,
});

const previewClient = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  host: process.env.REACT_APP_CONTENTFUL_PREVIEW_URL,
});

export const onlyCurrentLocaleFilter = { 'fields.disableForThisLocale[ne]': true };

const defaultOpts = {
  'content_type': PAGE,
  locale: 'en-US',
  include: 10,
  ...onlyCurrentLocaleFilter,
};

const pickItems = compose(
  over(lensProp('items'), map(removeCycles)),
  pick(['items', 'total']),
);

const pickIncludes = compose(
  pick(['includes', 'total'])
);
/**
 * @method
 * @return Data for Page item matching provided _options_
 **/
export const getPage = (_opts = {}) => {
  const opts = { ...defaultOpts, ..._opts };
  return client
    .getEntries({ ...opts })
    .then(pickItems)
    .catch();
};

export const getAuthor = (_opts = {}) => {
  const opts = { ..._opts };
  return client
    .getEntries({ 'content_type': 'mediaItem', 'fields.type': 'Author', ...opts })
    .then(pickItems)
    .catch();
};

export const storePage = (_opts = {}) => {
  const opts = { ...defaultOpts, ..._opts };
  const items = client
    .getEntries({ ...opts })
    .then(pickItems)
    .catch();
  const includes = client
    .getEntries({ ...opts })
    .then(pickIncludes)
    .catch();
  return [items, includes];
};

/**
 * @method
 * @return Data for Page preview item matching provided _options_
 **/
export const getPreviewPage = (_opts = {}) => {
  const opts = { ...defaultOpts, ..._opts };
  return previewClient
    .getEntries({ ...opts })
    .then(pickItems)
    .catch();
};

export const getPreviewComponent = (opts = {}) => {
  return previewClient
    .getEntries({ 'sys.id': opts['sys.id'], include: 10, ...opts })
    .then(pickItems)
    .catch();
};

const getEntries = (query) => client
  .getEntries(query)
  .then(pickItems);

export const getCollections = ({
  collection,
  include = 2,
  limit = 100,
  skip = 0,
  ...otherFilters
}) => getEntries({ 'content_type': collection, include, limit, skip, ...otherFilters });

export const getCollectionsIn = ({
  collections,
  include = 2,
  limit = 100,
  skip = 0,
  ...otherFilters
}) => getEntries({
  'sys.contentType.sys.id[in]': collections.join(','),
  include,
  limit,
  skip,
  ...otherFilters,
});

export const getItemByType = ({ type, model, locale, ...rest }) => getCollections({
  'fields.type': type,
  collection: model,
  locale,
  ...rest,
  ...onlyCurrentLocaleFilter,
});

export const getItemById = (id, options = {}) => client.
  getEntry(id, Object.assign(options, onlyCurrentLocaleFilter));

export const CONTENTFUL_MAX_IMAGE_WIDTH = 4000;
