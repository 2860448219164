import React, { PureComponent } from 'react';
import {
  Container,
  Title,
  Subtitle,
  Text,
  ImageContainer,
  Image,
  Content,
  ContentWrapper,
  Link,
  Author,
  Article,
  Header,
  IMAGE_SIZES,
} from './verticalCard.styles';
import PropTypes from '../../utils/propTypes.instance';
import { renderWhenTrue } from '../../utils/rendering';
import { Hoverable } from '../hoverable';


export class VerticalCard extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.string,
    text: PropTypes.string,
    authors: PropTypes.decorators.ListDecorator,
    date: PropTypes.string,
    history: PropTypes.object,
    imageAlt: PropTypes.string,
    sizes: PropTypes.object,
  };

  renderImage = renderWhenTrue(() => (
    <Image>
      <Hoverable>
        <ImageContainer
          src={this.props.imageUrl}
          alt={this.props.imageAlt}
          sizes={this.props.sizes ? this.props.sizes : IMAGE_SIZES}
        />
      </Hoverable>
    </Image>
  ));

  renderTitle = renderWhenTrue(() => (
    <Title hasImage={!!this.props.imageUrl}>
      {this.props.title}
    </Title>
  ));

  renderHeader = () => (
    <Header>
      {this.props.label && this.props.label !== 'Recipes' && <Subtitle>{this.props.label}</Subtitle>}
      {this.renderTitle(!!this.props.title)}
    </Header>
  );

  renderExcerpt = renderWhenTrue(() => (
    <section>
      <Text text={this.props.text} />
    </section>
  ));

  render() {
    const { className, url: targetUrl, imageUrl, text, authors, date } = this.props;
    const hasImage = !!imageUrl;

    return (
      <Article className={className}>
        <Link url={targetUrl}>
          <Container hasImage={hasImage} withTopLine noContentWrapper>
            {this.renderImage(hasImage)}
            <ContentWrapper hasImage={hasImage}>
              <Content>
                {this.renderHeader()}
                {this.renderExcerpt(!!text)}
              </Content>
            </ContentWrapper>
            { authors && (
              <Author
                authors={authors}
                dateCreated={this.props.label !== 'Recipes' && date}
              />
            ) }
          </Container>
        </Link>
      </Article>
    );
  }
}
