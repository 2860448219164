import React from 'react';

const LazyHeroSimple = React.lazy(() => import('./heroSimple.container'));

const SuspendedHeroSimple = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyHeroSimple ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedHeroSimple;
