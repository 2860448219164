import { createActions, createReducer } from 'reduxsauce';
import { Record, List, fromJS, Map } from 'immutable';
import { getId } from '../../utils/cmsData';
import categories from '../../data/newsCategoryDefinitions';

export const { Types: ArticleTypes, Creators: ArticleActions } = createActions({
  fetchByCategory: ['limit', 'skip'],
  fetchByCategorySuccess: ['items', 'category'],
  fetchByAuthor: ['authorId', 'limit', 'skip'],
  fetchByAuthorSuccess: ['items', 'authorId'],
  fetchError: ['payload'],
  setLimitReachedByCategory: ['limitState', 'category'],
  setActiveCategory: ['activeCategory'],
}, { prefix: 'ARTICLES_' });

const ArticlesRecord = new Record({
  // Stores articles nested under articles: author keys. Records if all included.
  articlesByAuthor: Map({ articles: null, limitReached: false }),
  // stores articles nested under articles: category keys. Records if all included.
  articlesByCategory: Map({ articles: null, limitReached: false }),
  // Currently activated category
  activeCategory: null,
  categories: fromJS(categories),
});

export const INITIAL_STATE = new ArticlesRecord({
});

const categoryStatePrefix = category => ['articlesByCategory', category || 'all'];
const fetchByCategorySuccessHandler = (state, { items, category }) => {
  const categoryPrefix = categoryStatePrefix(category);
  const categoryArticles = state.getIn([...categoryPrefix, 'articles']) || List();

  const isInCategoryArticles = item => !categoryArticles.find(a => getId(a) === getId(item));
  const uniqueList = fromJS(items).filter(isInCategoryArticles);

  return state.setIn([...categoryPrefix, 'articles'], categoryArticles.concat(uniqueList));
};

const authorStatePrefix = authorId => ['articlesByAuthor', authorId];
const fetchByAuthorSuccessHandler = (state, { items, authorId }) => {
  const authorPrefix = authorStatePrefix(authorId);
  const authorsArticles = state.getIn([...authorPrefix, 'articles']) || List();

  const isInAuthorsArticles = item => !authorsArticles.find(a => getId(a) === getId(item));
  const uniqueList = fromJS(items).filter(isInAuthorsArticles);

  return state.setIn([...authorPrefix, 'articles'], authorsArticles.concat(uniqueList));
};

const limitReachedHandler = (state, { limitState, category }) => {
  const categoryPrefix = categoryStatePrefix(category);
  return state.setIn([...categoryPrefix, 'limitReached'], limitState);
};

const handleCategoryChange = (state, { activeCategory }) => state.merge({ activeCategory });

export const reducer = createReducer(INITIAL_STATE, {
  [ArticleTypes.FETCH_BY_CATEGORY_SUCCESS]: fetchByCategorySuccessHandler,
  [ArticleTypes.FETCH_BY_AUTHOR_SUCCESS]: fetchByAuthorSuccessHandler,
  [ArticleTypes.SET_LIMIT_REACHED_BY_CATEGORY]: limitReachedHandler,
  [ArticleTypes.SET_ACTIVE_CATEGORY]: handleCategoryChange,
});
