import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ContentManager } from '../../components/contentManager';

/**
 * @summary Main component for rendering any generic component. For use in previewing CMS components.
 **/
export class ContentfulComponent extends PureComponent {
  /**
   * PropTypes
   * @property {object} data - CMS component data object
   * @property {function} fetchData - redux action that triggers the component fetch
   * @property {function} setCategoryMenuVisible - redux action that sets category menu visibility
   * @property {function} setHeaderStyle - redux action that sets the header style
   * @property {object} match - react-router property with current route data
   * @property {object} intl - react-intl translations object
   **/
  static propTypes = {
    data: PropTypes.object,
    fetchData: PropTypes.func.isRequired,
    sysid: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { fetchData, sysid } = this.props;
    fetchData(sysid);
  }

  componentDidUpdate(prevProps) {
    const { fetchData, sysid } = this.props;
    if (prevProps.sysid !== sysid) {
      fetchData(sysid);
    }
  }

  render() {
    const { data } = this.props;
    if (!data) { return null; }
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <ContentManager content={data} />
      </div>
    );
  }
}
