import styled from 'styled-components';
import { Button as ButtonComponent } from '../../button/button.styles';
import { COLORS } from '../../styles/homePageStyles';
import { media } from '../../../../theme/media';

const whiteTransparent = `${COLORS.WHITE}E6`;

export const LearnMoreBox = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 30px 30px 35px;
  margin-top: 96%;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, .8) 100%);


  ${media.mobile`
    margin-top: 100%;
  `}

  ${media.tablet`
    mask-image: none;
    margin-top: 0;
    background-color: ${whiteTransparent};
    padding: 40px 35px;
  `}
`;

export const Button = styled(ButtonComponent)`
  margin-top: 30px;
  font-size: 1.1rem;

  ${media.tablet`
    margin-top: 25px;
  `}
`;
