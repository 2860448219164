import { createSelector } from 'reselect';

export const selectLanguagesDomain = state => state.languages;

export const selectLanguagesData = createSelector(
  selectLanguagesDomain, state => state.get('data')
);

export const selectDetectedCountry = createSelector(
  selectLanguagesDomain,
  state => state.get('country'),
);
