/**
 * Helpers for accessibility
 * @module
 **/

import empty from 'ramda/es/empty';
import ifElse from 'ramda/es/ifElse';
import { isIos } from '../utils/userAgent';

/**
 * @method
 * @return {bool} **true** if the source event is a click or an **Enter** or **Space** keypress
 **/
const isClickEvent = event => {
  if (event.type === 'click') {
    return true;
  }

  if (event.type === 'keypress') {
    const code = event.charCode || event.keyCode;
    if ((code === 32) || (code === 13)) {
      return true;
    }
  }

  return false;
};

/**
 * @method
 * @return {bool} **true** if the *event* is **Enter** or **Space**
 **/
export const isSubmitKey = event => {
  if (event.type === 'keypress') {
    const code = event.charCode || event.keyCode;
    if ((code === 32) || (code === 13)) {
      return true;
    }
  }

  if (event.type === 'click') {
    if (event.detail === 0 || (event.screenX === 0 && event.screenY === 0)) {
      return true;
    }
  }

  return false;
};

/**
 * @method
 * @summary Invokes _handlerFn_ if the source event is an **Enter** or **Space** keypress or a click event.
 * Doesn't do anything otherwise.
 **/
export const accessibleClickHandlerFn = handlerFn => ifElse(
  isClickEvent,
  handlerFn,
  empty,
);

/**
 * @method
 * @param {string} eventType - mouse event type to handle
 * @return props with event handlers for both mouse & keyboard interactions
 **/
export const accessibleMouseEvent = (eventType, handler, role = 'button') => ({
  tabIndex: 0,
  role,
  [eventType]: accessibleClickHandlerFn(handler),
  onKeyPress: accessibleClickHandlerFn(handler),
});

/**
 * @method
 * @return props with event handlers for both mouse & keyboard interactions for click event
 **/
export const accessibleClickHandler = (handler, role = 'button') => accessibleMouseEvent(
  'onClick',
  handler,
  role,
);

/**
 * @method
 * @disable aria-live when detect iOS
 **/
export const ariaLiveHandler = () => {
  if (isIos) {
    return 'off';
  }

  return 'polite';
};
