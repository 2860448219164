import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { GenericArticleSection } from './genericArticleSection.component';
import { selectIsHeroPresent } from '../../../reducers/page/page.selectors';


const mapStateToProps = createStructuredSelector({
  isHeroPresent: selectIsHeroPresent,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GenericArticleSection);
