import { createSelector } from 'reselect';
import { HeaderStyle } from './menu.redux';
import { selectActiveCategory, selectActiveMainCategory } from '../news/news.selectors';
import { selectConfigDomain } from '../config/config.selectors';
import { getProp } from '../selectorHelpers';

export const selectMenuDomain = state => state.menu;

export const selectMainMenuLinks = createSelector(
  selectConfigDomain, getProp('mainMenuLinks')
);

export const selectMenuForm = createSelector(
  selectConfigDomain, getProp('menuForm')
);

export const selectMenuContentType = createSelector(
  selectConfigDomain, getProp('contentType')
);

export const selectMenuEntryId = createSelector(
  selectConfigDomain, getProp('entryId')
);

export const selectMenuLinks = createSelector(
  selectConfigDomain, state => state.get('menuLinks')
);

export const selectMenuSocialIcons = createSelector(
  selectConfigDomain,
  getProp('menuSocialIcons'),
);

export const selectMenuSocialIconsTitle = createSelector(
  selectConfigDomain,
  getProp('menuSocialIconsTitle'),
);

export const selectMenuTermsLinks = createSelector(
  selectConfigDomain,
  getProp('menuTerms'),
);

export const selectHeaderStyle = createSelector(
  selectMenuDomain,
  state => {
    const headerStyle = state.get('headerStyle');
    const isMenuHidden = state.get('isMenuHidden');

    return isMenuHidden ? HeaderStyle.MINIMAL : headerStyle;
  },
);

export const selectMenuOpened = createSelector(
  selectMenuDomain,
  state => state.get('isOpened')
);

export const selectIsCategoryMenuVisible = createSelector(
  selectMenuDomain,
  state => state.get('isCategoryMenuVisible')
);

export const selectCategoryNavLevel = createSelector(
  selectIsCategoryMenuVisible,
  selectActiveCategory,
  selectActiveMainCategory,
  (isVisible, activeCategory, activeMainCategory) => {
    if (!isVisible) {return 0;}
    if (isVisible && !(activeCategory && activeCategory !== activeMainCategory)) {return 1;}
    if (activeCategory && activeCategory !== activeMainCategory) {return 2;}
    return 0;
  }
);
