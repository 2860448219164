import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BoxContainer,
  DonationContainer,
  TabContainer,
  GiveOnceTab,
  MonthlyTab,
  TabsDescription,
  CheckboxContainer,
  Label,
  Checkbox,
  Button,
} from './donationBox.styles';
import { COLORS, TEXT_COLOR } from '../styles/homePageStyles';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';
import LockIconSvgWhite from '../../../images/icon-lock-plain.svg';
import LockIconSvgDarkGrey from '../../../images/icon-lock-plain-dark-grey.svg';
import TickIconWhite from '../../../images/icon-tick.svg';
import TickIconDarkGrey from '../../../images/icon-tick-dark-grey.svg';
import { ValueSelector } from './valueSelector/valueSelector.component';
import { appendHomePageParams } from '../utils/linkHelpers';

export const TYPES = {
  CHECK: 'check',
  TABS: 'tabs',
};

export const DonationBox = ({
  description,
  values,
  theme,
  type,
  preselectedMonthly,
  preselectedOneTime,
  handleActiveValue,
}) => {
  const themeBase = theme.split('_ACCESSIBLE')[0].toLowerCase();

  const [isMonthlyDonation, setIsMonthlyDonation] = useState(true);
  const [activeValue, setActiveValue] = useState({
    monthly: preselectedMonthly,
    oneTime: preselectedOneTime,
  });
  const [customValue, setCustomValue] = useState('');

  const toggleFrequency = () => {
    setIsMonthlyDonation(!isMonthlyDonation);
  };

  const handleValueChange = i => {
    if (isMonthlyDonation) {
      setActiveValue({ ...activeValue, monthly: i });
    } else {
      setActiveValue({ ...activeValue, oneTime: i });
    }
  };

  const getActiveValue = () => {
    if (
      (isMonthlyDonation && activeValue.monthly === 3) ||
      (!isMonthlyDonation && activeValue.oneTime === 3)
    ) {
      return customValue;
    } else if (isMonthlyDonation) {
      return values.monthlyValues[activeValue.monthly];
    }
    return values.oneTimeValues[activeValue.oneTime];
  };

  const buttonUrl = () => {
    let url = 'https://donate.thehumaneleague.org/donate';
    const value = getActiveValue();
    const donateParams = {
      am: value,
      r: isMonthlyDonation,
    };
    return appendHomePageParams(url, donateParams);
  };

  const getTickImage = () => {
    return (
      <img
        src={TEXT_COLOR[themeBase] === COLORS.WHITE ? TickIconWhite : TickIconDarkGrey}
        alt="Lock Icon"
      />
    );
  };

  useEffect(() => {
    handleActiveValue(getActiveValue());
  }, [activeValue, customValue]);

  return (
    <PlatformDetector>
      {({ isMobile }) => (
        <BoxContainer theme={theme} themeBase={themeBase} type={type}>
          {type === TYPES.TABS && (
            <TabContainer>
              <GiveOnceTab active={!isMonthlyDonation} onClick={() => toggleFrequency()}>
                {!isMonthlyDonation && getTickImage()}GIVE ONCE
              </GiveOnceTab>
              <MonthlyTab active={isMonthlyDonation} onClick={() => toggleFrequency()}>
                {isMonthlyDonation && getTickImage()}MONTHLY
              </MonthlyTab>
            </TabContainer>
          )}
          <DonationContainer type={type}>
            {type === TYPES.CHECK && !!description && <div>{description}</div>}
            <ValueSelector
              values={values}
              theme={theme}
              isMonthly={isMonthlyDonation}
              activeValue={activeValue}
              customValue={customValue}
              handleValueChange={handleValueChange}
              setCustomValue={setCustomValue}
            />
            {type === TYPES.TABS && <TabsDescription>{description}</TabsDescription>}
            {type === TYPES.CHECK && (
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="repeatMonthlyCheckbox"
                  onChange={toggleFrequency}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      toggleFrequency();
                    }
                  }}
                  checked={isMonthlyDonation}
                />
                <Label htmlFor="repeatMonthlyCheckbox">
                  {isMobile
                    ? 'Give monthly'
                    : 'Repeat this gift monthly so I can help animals every day.'}
                </Label>
              </CheckboxContainer>
            )}
            <Button href={buttonUrl()} color={themeBase} fullWidth>
              <img
                src={
                  TEXT_COLOR[themeBase] === COLORS.WHITE ? LockIconSvgWhite : LockIconSvgDarkGrey
                }
                alt="Lock Icon"
              />
              {isMonthlyDonation ? 'Donate Monthly' : 'Give Once'}
            </Button>
          </DonationContainer>
        </BoxContainer>
      )}
    </PlatformDetector>
  );
};

DonationBox.propTypes = {
  description: PropTypes.string,
  values: PropTypes.shape({
    monthlyValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    oneTimeValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  theme: PropTypes.string,
  type: PropTypes.oneOf([TYPES.CHECK, TYPES.TABS]),
  preselectedMonthly: PropTypes.oneOf([0, 1, 2]).isRequired,
  preselectedOneTime: PropTypes.oneOf([0, 1, 2]).isRequired,
  handleActiveValue: PropTypes.func,
};

DonationBox.defaultProps = {
  monthlyDescription: '',
  theme: 'blueAccessible',
  type: TYPES.CHECK,
  handleActiveValue: () => {},
};

export default DonationBox;
