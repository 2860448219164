import React from 'react';

const LazySectionDecoration = React.lazy(() => import('./sectionDecoration.container'));

const SuspendedSectionDecoration = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazySectionDecoration ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedSectionDecoration;
