/**
 * Sagas for handling home page data fetching
 * @module "homePage.sagas"
 **/

import { createActions, createReducer } from 'reduxsauce';
import { Record, Map, fromJS } from 'immutable';
import landingPage from '../../../data/landingPage';

export const { Types: HomePageTypes, Creators: HomePageActions } = createActions({
  // Kicks off saga
  fetchHomePage: ['slug', 'isPreview', 'opts'],
  // Saves data returned from contentful in a map, for easy restore.
  fetchHomePageSuccess: ['items', 'slug'],
  // Changes official current page.
  setCurrentHomePage: ['id'],
}, { prefix: 'HOME_PAGE_' });

const HomeRecord = new Record({
  currentHomePage: Map(),
  pages: Map({ '': fromJS(landingPage) }),
});

export const INITIAL_STATE = new HomeRecord({});

const fetchHomePageSuccessHandler = (state, { items, slug }) => {
  return state.setIn(['pages', slug], fromJS(items));
};
const handleHomePageChanged = (state, { id: pageId }) => state.set('currentHomePage', pageId);

export const reducer = createReducer(INITIAL_STATE, {
  [HomePageTypes.FETCH_HOME_PAGE_SUCCESS]: fetchHomePageSuccessHandler,
  [HomePageTypes.SET_CURRENT_HOME_PAGE]: handleHomePageChanged,
});
