import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import find from 'ramda/es/find';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import map from 'ramda/es/map';

import { Container, ListItem, ActiveOption, COLLAPSE_TIME } from './languageList.styles';
import { List } from '../../../theme/layout';
import { getField, getFieldIn } from '../../../utils/cmsData';
import { Language } from './language/language.component';
import { Collapse } from '../../../components/collapse/collapse.component';
import { accessibleClickHandler } from '../../../utils/accessibility';


const getLanguageFields = (language) => {
  return {
    url: getField('url', language),
    label: getField('label', language),
    icon: getField(['icon', 'fields', 'file', 'url'], language),
  };
};

export class LanguageList extends PureComponent {
  static propTypes = {
    languages: PropTypes.object,
    fetchLanguages: PropTypes.func,
    currentLanguage: PropTypes.string,
  };

  componentDidMount() {
    if (!this.props.languages.size) {
      this.props.fetchLanguages();
    }
  }

  getLanguageFields = (language) => {
    return {
      url: getFieldIn(['url', 'url'], language),
      label: getField('label', language),
      icon: getField(['icon', 'fields', 'file', 'url'], language),
    };
  };

  get currentLanguage() {
    return find((language) => getField('id', language) === this.props.currentLanguage)(this.props.languages);
  }

  listRef = React.createRef();

  render() {
    const { languages } = this.props;

    if (!languages.size) {
      return null;
    }

    return (
      <Collapse component={this.listRef} visibilityDuration={COLLAPSE_TIME}>
        {
          ({ collapsed, collapse, height, itemsAccessible }) => (
            <Container ref={this.listRef} collapsed={collapsed} componentHeight={height}>
              <ActiveOption {...accessibleClickHandler(collapse)}>
                <Language active {...getLanguageFields(this.currentLanguage)} />
              </ActiveOption>
              <List>
                {
                  pipe(
                    filter(language => getField('id', language) !== this.props.currentLanguage),
                    map(language => (
                      <ListItem key={getField('id', language)} isVisible={itemsAccessible}>
                        <Language {...getLanguageFields(language)} />
                      </ListItem>
                    ))
                  )(languages.toArray())
                }
              </List>
            </Container>
          )
        }
      </Collapse>
    );
  }
}
