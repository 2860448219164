import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const HOVER_CLASS = 'hovered';
const HAS_HOVERED_CLASS = 'has-hovered';

export class Hoverable extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    isHovered: false,
  };

  enableHover = () => {
    this.setState({ isHovered: true });
  };

  disableHover = () => {
    this.setState({ isHovered: false });
  };

  hoverClass = () => (`${this.state.isHovered && HOVER_CLASS} ${this.hasHovered && HAS_HOVERED_CLASS}`);

  get hoverProps() {
    return {
      onMouseEnter: this.enableHover,
      onMouseLeave: this.disableHover,
      onTouchStart: this.enableHover,
      onTouchEnd: this.disableHover,
      onTouchCancel: this.disableHover,
    };
  }

  render() {
    this.hasHovered = this.hasHovered || this.state.isHovered;

    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        ...this.hoverProps,
        className: [child.props.className || '', this.hoverClass()].join(' '),
      })
    );
  }
}
