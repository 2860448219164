export const isPreview = path => path && path.includes('/preview/');

export const isUrlExternal = url => {
  const urlPattern = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(urlPattern);

  return url && url.match(regex);
};

export const isHashPath = url => {
  return url && url.startsWith('#');
};
