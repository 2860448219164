import { put, takeLatest, all } from 'redux-saga/effects';
import { MenuTypes, MenuActions } from './menu.redux';
import { getCollections } from '../../services/contentful';
import { selectLocalesLanguage } from '../locales/locales.selectors';


const COLLECTION_NAME = 'menu';

export function* fetchMenuSaga() {
  try {
    const locale = yield select(selectLocalesLanguage);
    const { items } = yield getCollections({ collection: COLLECTION_NAME, include: 4, locale });

    const menuItem = items[0];
    yield put(MenuActions.fetchSuccess({
      ...(menuItem.fields),
      contentType: menuItem.sys.contentType.sys.id,
      entryId: menuItem.sys.id,
    }));
  } catch (e) {
    yield put(MenuActions.fetchError(e));
  }
}

export default function* MenuSaga() {
  yield all([
    takeLatest(MenuTypes.FETCH, fetchMenuSaga),
  ]);
}
