import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './buttonArray.styles';
import { Pad } from '../styles/homePageStyles';
import { useOnScreen } from '../utils/useOnScreen';
import Button from './button/button.component';

const ButtonArray = ({ color, buttons }) => {
  const containerRef = React.useRef(null);
  const isOnScreen = useOnScreen(containerRef, 0, 0.5, 0, true);

  return (
    <Styled.Wrapper>
      <Pad>
        <Styled.ButtonContainer
          ref={containerRef}
          isOnScreen={isOnScreen}
          buttonCount={buttons.length}
        >
          {buttons.map(({ hasEmbellishment: withEmbellishment, url, name: text }) => {
            return <Button {...{ withEmbellishment, url, text }} color={color} key={url} />;
          })}
        </Styled.ButtonContainer>
      </Pad>
    </Styled.Wrapper>
  );
};

ButtonArray.propTypes = {
  color: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      hasEmbellishment: PropTypes.bool.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ButtonArray;
