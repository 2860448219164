import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, HIDE_TIME } from './pageLoader.styles';

export const PAGE_LOADER_OPEN_CLASS = 'PageLoader--open';


/*
 * This displays a beating mended heart while pages are loading.
 * It displays if it receives a 'isOpen' prop of true or if it has previously displayed and has
 * not had time to animate out.
 * It also prevents the body from overflowing the screen while it is displayed.
 */
export class PageLoader extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    openPageLoader: PropTypes.func.isRequired,
    closePageLoader: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = { isAnimatedOut: false };
    if (!props.isOpen) {
      setTimeout(() => {
        this.setState({ isAnimatedOut: true });
      }, HIDE_TIME * 1000);
    }
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      this.setState({ isAnimatedOut: false });
      // Hides overflow
      this.bodyElement.classList.add(PAGE_LOADER_OPEN_CLASS);
    } else {
      this.bodyElement.classList.remove(PAGE_LOADER_OPEN_CLASS);
      if (!this.elementRef) {
        return;
      }
      this.elementRef.classList.add('hide');
      setTimeout(() => {
        if (!this.elementRef) {
          return;
        }
        this.elementRef.classList.remove('hide');
        this.setState({ isAnimatedOut: true });
      }, HIDE_TIME * 1000);
    }
  }

  componentWillUnmount() {
    this.bodyElement.classList.remove(PAGE_LOADER_OPEN_CLASS);
  }

  bodyElement = document.getElementsByTagName('body')[0];
  elementRef = React.createRef();

  render() {
    const { isAnimatedOut } = this.state;
    const { isOpen } = this.props;

    if (!isAnimatedOut || isOpen) {
      return (
        <Container
          ref={ref => {
            this.elementRef = ref;
          }}
        />
      );
    }
    return null;
  }
}
