import React from 'react';

const LazyBigText = React.lazy(
  () => import('./bigText.container'),
);

const SuspendedBigText = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyBigText ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedBigText;
