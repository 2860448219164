import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import messages from './pageMetaTags.messages';
import { getSrc } from '../../utils/cmsData';
import googleTagManager from '../googleTagManager';

const IMAGE_WIDTH = 1200;
const IMAGE_HEIGHT = 630;

const withProtocol = url => (url.indexOf('http') === 0 ? url : `https:${url}`);

export class PageMetaTags extends PureComponent {
  static propTypes = {
    defaultTitle: PropTypes.string,
    defaultImage: PropTypes.object,
    defaultTwitterSite: PropTypes.string,
    language: PropTypes.string,
    page: PropTypes.object,
    intl: PropTypes.object,
    googleTagManagerId: PropTypes.string,
  };

  render() {
    const {
      defaultTitle,
      defaultImage,
      defaultTwitterSite,
      language,
      page,
      googleTagManagerId,
    } = this.props;
    const pageTitle = page.get('metaTitle') || page.get('title') || defaultTitle;
    const pageDescription = page.get('metaDescription');
    const pageImage = withProtocol(
      `${getSrc(
        page.get('metaImage') || defaultImage,
      )}?w=${IMAGE_WIDTH}&h=${IMAGE_HEIGHT}&fit=fill`,
    );
    const pageRobots = page.get('metaRobots') || 'index, follow, max-image-preview: large';
    const url = `${location.origin}${location.pathname}`;
    return (
      <Helmet htmlAttributes={{ lang: language }}>
        <title>{pageTitle}</title>
        {pageDescription && <meta name="description" content={pageDescription} />}
        <link rel="canonical" href={url} />

        <meta property="og:locale" content={language} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        {pageDescription && <meta property="og:description" content={pageDescription} />}
        <meta property="og:site_name" content={this.props.intl.formatMessage(messages.sitename)} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:image:secure_url" content={pageImage} />
        <meta property="og:url" content={url} />

        <meta name="twitter:card" content="summary_large_image" />
        {pageDescription && <meta name="twitter:description" content={pageDescription} />}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:image" content={pageImage} />
        <meta property="og:image:width" content={IMAGE_WIDTH} />
        <meta property="og:image:height" content={IMAGE_HEIGHT} />
        <meta name="twitter:site" content={defaultTwitterSite || '@thehumaneleague'} />

        <meta name="robots" content={pageRobots} />
        {googleTagManager({ googleTagManagerId: googleTagManagerId })}
      </Helmet>
    );
  }
}
