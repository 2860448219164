import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Bars, InnerBar, OuterBar, Line, Column, Filler } from './triangleClip.styles';
import { colors } from '../../theme/styled';

export const ArrowSide = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const ArrowDirection = {
  UP: 'up',
  DOWN: 'down',
};

export class TriangleClip extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    widths: PropTypes.array,
    color: PropTypes.string,
    arrowSide: PropTypes.oneOf([ArrowSide.LEFT, ArrowSide.RIGHT]),
    arrowDirection: PropTypes.oneOf([ArrowDirection.DOWN, ArrowDirection.UP]),
    className: PropTypes.string,
    withLine: PropTypes.bool,
    fillOuter: PropTypes.bool,
  };

  static defaultProps = {
    color: colors.white,
    height: 170,
    arrowSide: ArrowSide.RIGHT,
    arrowDirection: ArrowDirection.DOWN,
  };

  get widths() {
    const { arrowSide, widths } = this.props;

    if (widths) {
      return arrowSide === ArrowSide.LEFT ? [widths[0], widths[1]] : [widths[1], widths[0]];
    }

    return arrowSide === ArrowSide.LEFT ? [25, 75] : [75, 25];
  }

  renderOuter({ barProps, isPixelWidth, arrayWidths, heights }) {
    return (
      <Fragment>
        <OuterBar {...barProps} width={arrayWidths[0]} height={heights[0]} isPixelWidth={isPixelWidth} />
        <OuterBar right {...barProps} width={arrayWidths[1]} height={heights[1]} isPixelWidth={isPixelWidth} />
      </Fragment>
    );
  }

  renderInner({ barProps, isPixelWidth, arrayWidths, heights }) {
    return (
      <Fragment>
        <Column>
          <Filler {...barProps} />
          <InnerBar {...barProps} width={arrayWidths[0]} height={heights[0]} isPixelWidth={isPixelWidth} />
        </Column>
        <Column>
          <Filler {...barProps} />
          <InnerBar right {...barProps} width={arrayWidths[1]} height={heights[1]} isPixelWidth={isPixelWidth} />
        </Column>
      </Fragment>
    );
  }

  render() {
    const { arrowSide, widths, color, className, height, arrowDirection, withLine } = this.props;
    const barProps = { color };

    const arrayWidths = this.widths;
    const isPixelWidth = widths;
    const heightScales = arrowSide === ArrowSide.LEFT ? [.4, 1] : [1, .4];
    const heights = heightScales.map(scale => scale * height);

    const pointUp = arrowDirection === ArrowDirection.UP;

    const renderBars = this.props.fillOuter ? this.renderOuter : this.renderInner;

    return (
      <Container className={className}>
        <Bars pointUp={pointUp}>
          {renderBars({
            arrayWidths,
            isPixelWidth,
            heights,
            barProps,
          })}
        </Bars>

        {withLine && <Line pointUp={pointUp} right={ArrowSide === ArrowSide.RIGHT} />}
      </Container>
    );
  }
}
