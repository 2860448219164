/**
 * This contains site-specific proptype matchers.
 **/

import PropTypes from 'prop-types';
import { MediaItemAuthorDecorator, ListDecorator } from '../decorators';

PropTypes.decorators = {};

PropTypes.decorators.AuthorDecorator = PropTypes.instanceOf(MediaItemAuthorDecorator);
PropTypes.decorators.ListDecorator = PropTypes.instanceOf(ListDecorator);

export default PropTypes;
