import { createActions, createReducer } from 'reduxsauce';
import { Record } from 'immutable';

export const { Types: VideoTypes, Creators: VideoActions } = createActions(
  {
    openVideo: ['videoId', 'videoHost'],
    closeVideo: [],
  },
  { prefix: 'VIDEO_' },
);

const VideoRecord = new Record({
  activeVideo: null,
  activeVideoHost: null,
});

export const INITIAL_STATE = new VideoRecord({});

const handleVideoOpened = (state, { videoId, videoHost }) => {
  return state.set('activeVideo', videoId).set('activeVideoHost', videoHost);
};

const handleVideoClosed = state => {
  return state.set('activeVideo', null).set('activeVideoHost', null);
};

export const reducer = createReducer(INITIAL_STATE, {
  [VideoTypes.OPEN_VIDEO]: handleVideoOpened,
  [VideoTypes.CLOSE_VIDEO]: handleVideoClosed,
});
