import styled from 'styled-components';
import { COLORS } from '../styles/homePageStyles';

const darkGrayTransparent = `${COLORS.DARK_GRAY}59`;
const whiteTransparent = `${COLORS.WHITE}40`;

const BLUR_RADIUS = 20;

export const PseudoFuzzyBackground = styled.div`
  position: relative;
  background-color: ${props => (props.theme === 'light' ? whiteTransparent : darkGrayTransparent)};
  box-shadow: 0 0 ${props => props.blur || BLUR_RADIUS}px ${props => props.blur || BLUR_RADIUS}px
    ${props => (props.theme === 'light' ? whiteTransparent : darkGrayTransparent)};
`;
