import styled from 'styled-components';
import { Container as ContainerStyle } from '../../theme/layout';
import { media } from '../../theme/media';
import { text } from '../../theme/typography';

export const Container = styled(ContainerStyle)`
  padding-top: 40px;
  align-items: flex-start;
  
  ${media.tablet`
    padding-top: 0;
    max-width: 198px;
    display: flex;
    align-items: center;
  `};
`;

export const Image = styled.img`
  width: 210px;
  height: 210px;
  object-fit: contain;
  border-radius: 100%;
  
  ${media.tablet`
    width: 160px;
    height: 160px;
  `};
`;

export const Description = styled.p`
  ${text};
  margin-top: 24px;
  
  ${media.tablet`
    max-width: 198px;
  `};
`;
