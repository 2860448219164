import styled from 'styled-components';
import { FONTS, COLORS, TEXT_COLOR, BUTTON_COLOR } from '../../styles/homePageStyles';
import { media } from '../../../../theme/media';

const VALUE_ITEM_TRANSITION_DURATION = '0.2s';

export const ValuesSection = styled.div`
  --theme-color: ${props => props.theme ? COLORS[props.theme] : COLORS.BLUE_ACCESSIBLE};
  --theme-font-color: ${props => props.themeBase ? TEXT_COLOR[props.themeBase] : COLORS.WHITE};
  --theme-hover-font-color: ${props =>
    props.themeBase && props.themeBase !== 'yellow' ? BUTTON_COLOR[props.themeBase] : COLORS.BLACK};

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
`;

const ValueItemBase = styled.div`
  font-family: ${FONTS.TITLE_FONT};
  font-weight: 700;
  cursor: pointer;
  background: ${props => props.active ? 'var(--theme-color)' : COLORS.LIGHT_GRAY};
  width: 35%;
  height: 45px;
  border: none;
  line-height: 2.5rem;
  text-align: center;
  justify-content: center;
  padding-top: 2px;
  color: ${props => props.active ? 'var(--theme-font-color)' : COLORS.MEDIUM_GRAY};
  pointer-events: auto;
  clip-path: polygon(0 0, 0 100%, calc(100% - 0.5rem) 100%, 100% calc(100% - 0.5rem), 100% 0);
  display: flex;
  flex-grow: .25;

  ${media.mobile`
    width: 78px;

    &:hover, &:focus-visible {
      cursor: pointer;
      color: ${props => props.active ? 'var(--theme-font-color)' : 'var(--theme-hover-font-color)'};
      background-color: ${props => props.active ? 'var(--theme-color)' : COLORS.LIGHTEST_GRAY};
    }
  `}

  ${media.tablet`
    height: 48px;
    padding-top: 3.5px;
  `}

  :focus-visible {
    clip-path: none;
  }

  :focus:not(:focus-visible) {
    box-shadow: none;
  }

  sup {
    font-size: 0.815rem;
    top: 1.375em;
    padding-right: 1px;
    font-weight: 700;
  }

  span {
    font-weight: 500;
    font-size: 0.815rem;
    padding-top: 2px;
  }
`;

export const ValueItemDefault = styled(ValueItemBase)`
  font-size: ${props => props.inputActive && props.isMonthly ? '4vw' : '5vw'};
  transition-property: color, background, font-size, width;
  transition-duration: ${VALUE_ITEM_TRANSITION_DURATION};

  ${media.mobileSmall`
    font-size: 1.375rem;
  `}

  span {
    font-size: ${props => props.inputActive && props.isMonthly ? '.75rem' : '.815rem'};
  }
`;

export const ValueItemInput = styled(ValueItemBase)`
  display: inline-flex;
  font-size: ${props => props.length < 3 ? '1.375rem' : 4 / props.length + 'rem'};
  width: 35%;
  transition-property: font-size, width;
  transition-duration: ${VALUE_ITEM_TRANSITION_DURATION};

  span {
    font-size: 0.935rem;
    font-weight: 700;
    display: ${props => props.active ? 'none' : 'block'};
  }

  input {
    display: ${props => props.active ? 'inline-flex' : 'none'};
    padding-right: ${props => props.length && !props.isMonthly ? 35 - 10 * props.length + 'px' : '0px'};
    padding-top: 2px;
    background: transparent;
    text-align: right;
    flex: 1;
    max-width: 0px;
    color: var(--theme-font-color);
    font-weight: 700;
    border: none;
    transition: max-width 0.2s;
    -webkit-transition: max-width 0.2s;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:is(.active) {
    max-width: 50px;
  }

  input:not(:is(.active)) {
    max-width: 0px;
  }

  &:has(.active) {
    width: 200px;
  }

  &:not(:has(.active)) {
    width: 35%;
  }

  sup {
    margin-left: 10px;
    width: 3px;
    top: ${props => props.length < 4 ? '1.3em' : '1.35em'};
    left: ${props => props.length && props.isMonthly ? Math.floor(2 / props.length) + 'em' : '0em'};
    display: ${props => props.active ? 'inline-flex' : 'none'};
  }

  sub {
    width: 31px;
    display: ${props => props.active ? 'inline-flex' : 'none'};
    font-size: ${props => props.length < 4 ? '0.815rem' : .815 - (props.length * .04) + 'rem'};
    padding-top: ${props => props.length < 4 ? '22px' : 15 + props.length + 'px'};
    padding-right: 0px;
  }

  ${media.mobile`
    transition-property: color, background, font-size, width;
    &:has(.active) {
      width: 20%;
      transition: font-size ${VALUE_ITEM_TRANSITION_DURATION};
    }
    &:not(:has(.active)) {
      width: 20%;
    }
  `}
`;

export const InputContainer = styled.div`
  display: inline-flex;
  justify-content: space-around;
  height: 100%;
`;
