import styled from 'styled-components';
import { colors } from '../../../theme/styled';
import { centerAbsolute, fillAbsolute } from '../../../theme/mixins';

export const Icon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 80px;
  height: 80px;
  background-color: ${colors.yellow};
  transition: background-color 0.2s ease-in-out;
  ${centerAbsolute};

  &:after {
    content: '';
    position: absolute;
    border: 16px solid transparent;
    border-top: 24px solid ${colors.darkGrey};
    top: 50%;
    left: 54%;
    margin-left: 7px;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
`;

export const IconHoverWrapper = styled.div`
  ${fillAbsolute};
  cursor: pointer;

  &:hover {
    ${Icon} {
      background-color: ${colors.accessibilityYellow};
    }
  }

  .youtube-iframe-container {
    height: 100%;
    max-width: 100%;
  }
`;
