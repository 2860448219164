import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  isSrcSetSupported,
  getImageSource,
  getImageCustomSource,
  getLimitedWidth,
} from '../../../utils/image';
import { getDevicePixelRatio, sizes } from '../../../theme/media';
import { getField } from '../../../utils/cmsData';
import { ImageContainer } from './image.styles';
import { ImageCredit } from '../styles/homePageStyles';

export const Image = memo(function Image({
  src,
  image,
  srcSet,
  alt,
  className,
  sizesOverride,
  role,
  loading,
  fetchpriority,
  onLoad,
  includeCredit,
  credit,
}) {
  const [fallbackSrc, setFallBackSrc] = useState('');

  const getFallbackSrc = src => {
    if (window.innerWidth <= sizes.mobile) {
      return getImageSource(src, sizes.mobile);
    } else if (window.innerWidth <= sizes.tablet) {
      return getImageSource(src, sizes.tablet);
    } else if (window.innerWidth <= sizes.desktop) {
      return getImageSource(src, sizes.desktop);
    }

    return getImageSource(src, sizes.desktopFull);
  };

  const getFileSrc = () => {
    return image && getField(['image', 'fields', 'file', 'url'], image);
  };

  const getFinalSrc = () => {
    return src || getFileSrc();
  };

  const onResize = () => {
    if (this.src) {
      setFallBackSrc(getFallbackSrc(getFinalSrc()));
    }
  };

  const getCustomSrcSet = () => {
    return srcSet && srcSet.map(image => image.getIn(['fields', 'file']));
  };

  const getSrcSet = () => {
    const customSrcSet = getCustomSrcSet();
    if (customSrcSet && customSrcSet.size) {
      return customSrcSet.toArray().map(getImageCustomSource).join();
    }

    const imageSizes = {};

    Object.values(sizes).map(size => {
      if (sizesOverride && !sizesOverride[size]) {
        Object.keys(sizesOverride).map(key => {
          if (key < size) {
            imageSizes[size] = sizesOverride[key];
          }
        });
      } else {
        imageSizes[size] = sizesOverride && sizesOverride[size] ? sizesOverride[size] : size;
      }
    });

    return Object.values(sizes)
      .map(
        size =>
          `${getImageSource(getFinalSrc(), imageSizes[size] * getDevicePixelRatio())}
      ${getLimitedWidth(Math.floor(size * getDevicePixelRatio()))}w`,
      )
      .join();
  };

  const finalSrc = isSrcSetSupported() ? getFinalSrc() + '?q=75' : fallbackSrc + '?q=75';

  useEffect(() => {
    if (!isSrcSetSupported()) {
      setFallBackSrc(getFallbackSrc(getFinalSrc()));

      window.addEventListener('resize', onResize);
    }
    return () => {
      if (!isSrcSetSupported()) {
        window.removeEventListener('resize', onResize);
      }
    };
  }, []);

  return (
    <div>
      <ImageContainer>
        <img
          className={className}
          src={finalSrc}
          srcSet={getSrcSet()}
          alt={alt}
          role={role}
          loading={loading || 'lazy'}
          fetchpriority={fetchpriority}
          onLoad={onLoad}
        />
        {includeCredit && <ImageCredit>{credit}</ImageCredit>}
      </ImageContainer>
    </div>
  );
});

/**
 * PropTypes
 * @property src {string} - source of the image, should be URL string
 * @property image {object} - CMS image data object (can be used instead of src string)
 * @property srcSet {array} - list of image sources for different breakpoints
 * @property alt {string} - Alt text for the image
 * @property sizesOverride {array} - list with what src to use for different browser sizes, if not using those defined in themes/media
 * @property includeCredit {boolean} - whether to display Photo Credit or not
 * @property credit {string} - photo credit for the image
 * The rest of the props are default <img> properties
 **/

Image.propTypes = {
  src: PropTypes.string,
  image: PropTypes.object,
  srcSet: PropTypes.object,
  alt: PropTypes.string,
  className: PropTypes.string,
  role: PropTypes.string,
  loading: PropTypes.string,
  fetchpriority: PropTypes.string,
  onLoad: PropTypes.func,
  includeCredit: PropTypes.bool,
  credit: PropTypes.string,
  sizesOverride: PropTypes.object,
};

Image.defaultProps = {
  alt: '',
};
