import styled, { css } from 'styled-components';
import { COLORS } from '../styles/homePageStyles';

const PSUEDO_SPACE_WIDTH = '0.15em';

export const HIGHLIGHT_COLOR_LABEL = {
  BLUE: 'blue',
  BLUE_ACCESSIBLE: 'blueAccessible',
  GREEN: 'green',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
};

const HIGHLIGHT_COLOR = {
  [HIGHLIGHT_COLOR_LABEL.BLUE]: COLORS.BLUE,
  [HIGHLIGHT_COLOR_LABEL.BLUE_ACCESSIBLE]: COLORS.BLUE_ACCESSIBLE,
  [HIGHLIGHT_COLOR_LABEL.GREEN]: COLORS.LIGHT_GREEN,
  [HIGHLIGHT_COLOR_LABEL.RED]: COLORS.RED,
  [HIGHLIGHT_COLOR_LABEL.TEAL]: COLORS.TEAL,
  [HIGHLIGHT_COLOR_LABEL.YELLOW]: COLORS.YELLOW,
};

export const Highlight = styled.mark`
  --highlight-background: ${props => (props.theme ? HIGHLIGHT_COLOR[props.theme] : transparent)};
  --highlight-color: ${props => props.highlightTextColor || 'inherit'};

  background: transparent;
  color: inherit;
  margin-right: ${PSUEDO_SPACE_WIDTH};
`;

export const Word = styled.span`
  display: inline-block;
  padding: 0 ${PSUEDO_SPACE_WIDTH};
  margin-left: -${PSUEDO_SPACE_WIDTH};
  background-image: linear-gradient(var(--highlight-background), var(--highlight-background));
  background-position: ${props => (props.isReversed ? '100%' : '0')} 0.0375em;
  background-repeat: no-repeat;
  background-size: ${props => (props.isActive ? '100%' : '0')} calc(100% - 0.075em);
  color: ${props => (props.isActive ? 'var(--highlight-color)' : 'inherit')};
  transition: all ${props => props.duration}s linear;
  transition-property: color, background-size, text-shadow;
  transition-delay: ${props => props.delay}s;
  text-rendering: optimizeLegibility;

  ${({ isActive }) =>
    isActive &&
    css`
      text-shadow: none;
    `}

  &:first-child {
    transition-timing-function: ease-in;
  }

  &:last-child {
    transition-timing-function: ease-out;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
