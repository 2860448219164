import styled from 'styled-components';

import { COLORS, FONTS } from '../../styles/homePageStyles';
import { media } from '../../../../theme/media';

export const Counter = styled.div`
  display: inline-block;
  margin: 0 0.3rem;

  ${media.mobile`
    margin: 0 0.5rem;
  `};

  font-family: ${FONTS.TITLE_FONT};
`;

export const CounterLabel = styled.div`
  margin-bottom: 0.25rem;

  font-family: ${FONTS.MAIN_FONT};
  font-style: italic;
  font-size: clamp(0.8125rem, 3vw, 1rem);
  font-weight: 700;
  text-align: ${props => props.labelAlign ? props.labelAlign : 'left'};

  letter-spacing: -0.025rem;

  color: ${COLORS.WHITE};

  user-select: none;
`;

export const CounterDigitsContainer = styled.div`
  line-height: 0;
`;
