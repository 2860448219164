import React from 'react';

const LazyIframeForm = React.lazy(() => import('./iframeForm.container'));

// Key forces iframe to remount when search params change to prevent browser navigation issues
const SuspendedIframeForm = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyIframeForm key={`iframe-${props.entryId}-${location.search}`} ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedIframeForm;
