/**
 * Decorator providing utility functions for image media types.
 *
 * @module ImageDecorator
 **/

import Decorator from './decorator';
import FileDecorator from './file.decorator';
import { getField } from '../utils/cmsData';

export default class ImageDecorator extends Decorator {
  /**
   * Returns the url of the file of the image.
   *
   * @public
   * @return {string} The file url.
   */
  get url() {
    return this.file.url;
  }

  get alt() {
    return getField('alt', this.data);
  }

  dimensions() {
    return this.file.imageDetails();
  }

  widthToHeightRatio() {
    const { height, width } = this.dimensions();
    return Math.round(width / height * 100) / 100.0;
  }

  /**
   * Returns the decorated File of the image.
   *
   * @public
   * @return {FileDecorator} The decorated file data structure.
   */
  get file() {
    return new FileDecorator(getField('file', this.data));
  }
}
