import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { GlobalStyle } from './globalStyle.component';
import {
  selectCategoryNavLevel, selectHeaderStyle, selectMenuOpened,
} from '../../reducers/menu/menu.selectors';

const mapStateToProps = createStructuredSelector({
  headerStyle: selectHeaderStyle,
  isMenuOpened: selectMenuOpened,
  categoryNavLevel: selectCategoryNavLevel,
});

export default connect(mapStateToProps, null)(GlobalStyle);
