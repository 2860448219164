import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { H2, List, Container } from '../../theme/layout';
import { Link } from '../link/link.component';
import { getField } from '../../utils/cmsData';

export class LinkList extends PureComponent {
  static propTypes = {
    links: PropTypes.object,
    title: PropTypes.string,
    className: PropTypes.string,
    row: PropTypes.bool,
  };

  render() {
    const {
      className,
      row,
      links,
      title,
    } = this.props;


    return (
      <Container as="section" className={className}>
        {title && <H2>{title}</H2>}
        <nav role="navigation">
          <List row={row}>
            {
              links && links.toArray().map((item, index) => {
                const url = getField(['url', 'fields', 'url'], item);
                const label = getField('label', item);

                const registerLinkClick = () => {
                  dataLayer.push({
                    event: 'Click',
                    elementType: 'Link',
                    element: label || url,
                  });
                };

                return (
                  <li key={index}>
                    <Link onClick={registerLinkClick} url={url} label={label} />
                  </li>
                );
              })
            }
          </List>
        </nav>
      </Container>
    );
  }
}
