import React from 'react';

const LazyArticleStatsBlock = React.lazy(() => import('./articleStatsBlock.container'));

const SuspendedArticleStatsBlock = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyArticleStatsBlock ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedArticleStatsBlock;
