import { put, takeLatest, all } from 'redux-saga/effects';
import { FooterTypes, FooterActions } from './footer.redux';
import { getCollections } from '../../services/contentful';
import { selectLocalesLanguage } from '../locales/locales.selectors';


const COLLECTION_NAME = 'footer';

export function* fetchFooterSaga() {
  try {
    const locale = yield select(selectLocalesLanguage);
    const { items } = yield getCollections({ collection: COLLECTION_NAME, include: 4, locale });

    yield put(FooterActions.fetchSuccess(items[0].fields));
  } catch (e) {
    yield put(FooterActions.fetchError(e));
  }
}

export default function* MenuSaga() {
  yield all([
    takeLatest(FooterTypes.FETCH, fetchFooterSaga),
  ]);
}
