/*
 * Renders authors with name and date. Stacks multiple author avatars on top of each other.
 */

import React, { PureComponent } from 'react';
import {
  Container,
  AvatarContainer,
  Image,
  Text,
  Separator,
  Date,
  IMAGE_SIZES,
} from '../authorsList.styles';
import { formatDate } from '../../../utils/rendering';
import PropTypes from '../../../utils/propTypes.instance';

const MAX_AVATAR_COUNT = 3;

export class CondensedAuthorsList extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    photo: PropTypes.string,
    authors: PropTypes.decorators.ListDecorator.isRequired,
    name: PropTypes.string,
    dateCreated: PropTypes.string,
    forceWrap: PropTypes.bool,
  };

  render() {
    const { className, authors, dateCreated, forceWrap } = this.props;

    if (!authors || authors.isEmpty()) {
      return null;
    }
    const nameLabel = `${authors.first().name}${authors.hasMultiple() ? ' et al.' : ''}`;
    return (
      <Container className={className}>
        <AvatarContainer count={Math.min(authors.size, MAX_AVATAR_COUNT)}>
          {authors.first(MAX_AVATAR_COUNT).map(author => (
            <Image
              stacked
              src={author.image.url}
              alt={author.name}
              sizes={IMAGE_SIZES}
              key={`author-${author.name}`}
            />
          ))}
        </AvatarContainer>
        <Text length={nameLabel.length} forceWrap={forceWrap}>
          {nameLabel}
        </Text>

        {dateCreated && (
          <React.Fragment>
            <Separator />
            <Date dateTime={dateCreated}>{formatDate(dateCreated)}</Date>
          </React.Fragment>
        )}
      </Container>
    );
  }
}
