import connect from 'react-redux/es/connect/connect';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { ContentManagerComponent } from './contentManager.component';
import { MenuActions } from '../../reducers/menu/menu.redux';
import { PageActions } from '../../reducers/page/page.redux';
import { selectHeaderStyle } from '../../reducers/menu/menu.selectors';

const mapStateToProps = createStructuredSelector({
  headerStyle: selectHeaderStyle,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  setHeaderStyle: MenuActions.setHeaderStyle,
  setHeroPresent: PageActions.setHeroPresent,
  setHeroWithTitlePresent: PageActions.setHeroWithTitlePresent,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentManagerComponent);
