import { all, fork } from 'redux-saga/effects';
import pageSaga from './page/page.sagas';
import navigationSaga from './menu/menu.sagas';
import footerSaga from './footer/footer.sagas';
import newsSaga from './news/news.sagas';
import videoSaga from './video/video.sagas';
import accessoriesSaga from './accessories/accessories.sagas';
import pageLoaderSaga from './pageLoader/pageLoader.sagas';
import peopleHrSaga from './peopleHr/peopleHr.sagas';

/*
 * This kicks off a saga that creates all other sagas.
 */

export default function* rootSaga() {
  yield all([
    fork(pageSaga),
    fork(navigationSaga),
    fork(footerSaga),
    fork(newsSaga),
    fork(videoSaga),
    fork(accessoriesSaga),
    fork(pageLoaderSaga),
    fork(peopleHrSaga),
  ]);
}
