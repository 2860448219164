import styled from 'styled-components';
import { Section as SectionStyle } from '../../components/section/section.component';
import { Container as ContainerStyle, H2, List } from '../../theme/layout';
import { media } from '../../theme/media';
import { fontWeights } from '../../theme/styled';
import {
  Article, Author, Image, Container as ArticleContainer, Title as ArticleTitle,
} from '../verticalCard/verticalCard.styles';

export const Container = styled(ContainerStyle)``;

export const Section = styled(SectionStyle)`
  padding-top: 60px;
  padding-bottom: 60px;
  
  ${media.tablet`
    padding-top: 88px;
    padding-bottom: 30px;
  `};
  
  ${media.tabletLarge`
    padding-top: 80px;
    padding-bottom: 50px;
  `};
  
  ${media.desktop`
    padding-top: 100px;
    padding-bottom: 100px;
  `};
`;

export const Title = styled(H2)`
  font-weight: ${fontWeights.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  line-height: 1.17;
  
  ${media.desktop`
    font-size: 48px;
    line-height: 1.08;
  `};
`;

export const AuthorContainer = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  max-width: 418px;
  width: 100%;
  
  ${media.tablet`
    max-width: 48%;
    margin-right: 8px;
  `};
  
  ${media.tabletLarge`
    max-width: 460px;
    margin-right: 9px;
  `};
  
  ${media.desktop`
    max-width: 308px;
    margin-right: 22px;
  `};
`;

export const LinkContainer = styled.div`
  margin-top: 32px;
  text-align: left;
  
  ${media.tablet`
    margin-top: 40px;
  `};
`;

export const ArticleList = styled(List)`
  align-items: center;
  
  ${media.tablet`
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
  `};
  
  ${media.tablet`
    align-items: stretch;
  `};
  
  ${media.tabletLarge`
    margin-top: 54px;
  `};
  
  ${media.desktop`
    margin-top: 60px;
  `};
`;

export const ArticleItem = styled.li`
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
    
    ${media.tablet`
      margin-right: 0;
      margin-bottom: 30px;
    `};
    
    ${media.desktop`
      margin-bottom: 25.5px;
    `};
  };
  
  &:nth-child(2n) {
    ${media.tablet`
      margin-left: 8px;
    `};

    ${media.tabletLarge`
      margin-left: 9px;
    `};

    ${media.desktop`
      margin-left: 0;
    `};
  }
  
  &:nth-child(2n + 1) {
    ${media.tablet`
      margin-right: 8px;
    `};

    ${media.tabletLarge`
      margin-right: 9px;
    `};

    ${media.desktop`
      margin-right: 22px;
    `};
  }
  
  ${media.tablet`
    max-width: 48%;
    margin-bottom: 30px;
  `};
  
  ${media.tabletLarge`
    max-width: 460px;
  `};
  
  ${media.desktop`
    max-width: 308px;
    margin-right: 22px;
    margin-bottom: 25.5px;
  `};
  
  ${Article} {
    max-width: 100%;
    height: 100%;
  };
  
  ${ArticleContainer} {
    ${media.desktop`
      min-height: 440px;
      padding-bottom: 29px;
    `};
  }
  
  ${Author} {
    ${media.desktop`
      display: none;
    `};
  }
  
  ${Image} {
    ${media.tablet`
      height: 188px;
    `};

    ${media.tabletLarge`
      height: 300px;
    `};

    ${media.desktop`
      height: 175px;
    `};
  };
  
  ${ArticleTitle} {
    ${media.desktop`
      font-size: ${props => props.hasImage ? '22px' : '28px'};
    `};
  };
`;
