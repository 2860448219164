import React from 'react';

const useIsFirstMountState = () => {
  const isFirstMount = React.useRef(true);

  if (isFirstMount.current) {
    isFirstMount.current = false;

    return true;
  }

  return isFirstMount.current;
};

const usePreviousDistinctValue = value => {
  const prevRef = React.useRef(value);
  const curRef = React.useRef(value);
  const isFirstMount = useIsFirstMountState();

  if (!isFirstMount && curRef.current !== value) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
};

export default usePreviousDistinctValue;
