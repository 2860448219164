export const colors = {
  white: '#ffffff',
  black: '#000000',
  red: '#fe3b1f',
  accessibilityRed: '#de3412',
  blue: '#00A9BE',
  yellow: '#ffb600',
  accessibilityYellow: '#F29616',
  grey: '#28333d',
  charcoalGrey: '#313e4a',
  darkGrey: '#1D252C',
  mediumDarkGrey: '#626569',
  mediumGrey: '#F2F4F5',
  lightGrey: '#FAFAFA',
  limeGreen: '#B4D234',
  tealGreen: '#0F8972',
  copyright: '#6a6e70',
  footerLinks: '#a9acae',
  buttonDisabledPrimary: '#dee0df',
  buttonDisabledSecondary: '#cacbcc',
  hoverRed: '#bd0b02',
  hoverYellow: '#F29616',
  buttonDisabledFont: 'rgba(29, 37, 44, 0.6)',
  placeholder: '#666B70',
  borderGrey: 'rgba(29, 37, 44, 0.2)',
  borderYellow: 'rgba(255, 182, 0, 0.5)',
  borderLightGrey: 'rgba(29, 37, 44, 0.1)',
  optionGrey: 'rgba(29, 37, 44, 0.68)',
  borderMenuItem: 'rgba(255, 255, 255, 0.1)',
  languageOptionHover: '#394551',
  testimonialText: '#293338',
  neutral325: '#d5d6d8',
  fadedGrey: '#7d858b',
};

export const positions = {
  center: 'center',
  top: 'top',
  topThird: 'center 15%',
};

export const fonts = {
  titleFont: 'Montserrat, sans-serif',
  mainFont: 'Merriweather, serif',
  secondFont: 'Noto Serif, serif',
  smallFont: 'Open Sans, sans-serif',
};

export const fontWeights = {
  thin: '200',
  light: '300',
  normal: '400',
  semiBold: '600',
  bold: '700',
};

export const headerSize = {
  mobile: 60,
  desktop: 76,
};
