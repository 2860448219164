import min from 'ramda/es/min';
import '../vendors/modernizr-custom';
import {
  DESKTOP,
  MOBILE,
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
  DESKTOP_FULL,
} from '../components/platformDetector/platformDetector.component';
import { sizes, isMobile } from '../theme/media';
import { getField } from './cmsData';
import { SOCIAL_ICONS_SIZE } from '../constants';
import { CONTENTFUL_MAX_IMAGE_WIDTH } from '../services/contentful';

const RESOLUTION_MAP = {
  [MOBILE]: sizes.mobile,
  [TABLET_PORTRAIT]: sizes.tablet,
  [TABLET_LANDSCAPE]: sizes.tabletLarge,
  [DESKTOP]: sizes.desktop,
  [DESKTOP_FULL]: sizes.desktopFull,
};

const socialIcons = require.context('../images/socials', false, /\.svg$/);

export const isWebPSupported = () => {
  return Modernizr.webp;
};

export const isSvg = url => {
  return url.indexOf('.svg') !== -1;
};

export const isSrcSetSupported = () => {
  return Modernizr.srcset;
};

export const getLimitedWidth = min(CONTENTFUL_MAX_IMAGE_WIDTH);

export const getImageSource = (url, size) => {
  if (url) {
    const webp = isWebPSupported() && !isSvg(url) ? 'fm=webp&' : '';
    return `${url}?${webp}w=${getLimitedWidth(Math.ceil(size))}&q=75`;
  }

  return '';
};

export const getImageCustomSource = file => {
  const fileUrl = file.get('url');
  const fileSize = file.getIn(['details', 'image', 'width']);

  return `${getImageSource(fileUrl, fileSize)} ${fileSize}w`;
};

export const getResponsiveImage = ({ activeResolution, image, multiplier = 1 }) => {
  const url = getField(['file', 'url'], image);
  const resolution = RESOLUTION_MAP[activeResolution];

  return getImageSource(url, resolution * multiplier);
};

export const getResponsiveImageSrc = ({ activeResolution, src, multiplier = 1 }) => {
  const resolution = RESOLUTION_MAP[activeResolution];

  return getImageSource(src, resolution * multiplier);
};

export const getHeroImage = (activeResolution, image, mobileImage, multiplier = 1) => {
  if (mobileImage && isMobile()) {
    return getImageSource(mobileImage.getIn(['fields', 'file', 'url']), sizes.mobile * multiplier);
  }

  return getResponsiveImage({ activeResolution, image, multiplier });
};

export const getDynamicHeroImage = (activeResolution, image, mobileImage, multiplier = 1) => {
  if (mobileImage && isMobile()) {
    return {
      src: getImageSource(mobileImage.getIn(['fields', 'file', 'url']), sizes.mobile * multiplier),
      alt: mobileImage.getIn(['fields', 'title']),
    };
  }
  return {
    src: getResponsiveImage({ activeResolution, image, multiplier }),
    alt: image.getIn(['fields', 'title']),
  };
};

export const getSocialIcon = (provider, type, size = SOCIAL_ICONS_SIZE.REGULAR) => {
  return socialIcons(`./${provider}-${size}-${type}.svg`);
};
