import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import compose from 'ramda/es/compose';
import { LanguageList } from './languageList.component';
import { LanguagesActions } from '../../../reducers/languages/languages.redux';
import { selectLanguagesData } from '../../../reducers/languages/languages.selectors';
import { selectLocalesLanguage } from '../../../reducers/locales/locales.selectors';

const mapStateToProps = createStructuredSelector({
  languages: selectLanguagesData,
  currentLanguage: selectLocalesLanguage,
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchLanguages: LanguagesActions.fetch,
}, dispatch);

const Connected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(LanguageList);

export default props => {
  const routerLocation = useLocation();
  return <Connected {...props} location={routerLocation} />;
};
