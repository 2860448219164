/**
 * Redux components for handling author manipulation. See also authorPage.sagas
 * @module "authorPage.redux"
 **/

import { createActions, createReducer } from 'reduxsauce';
import { Record, Map, fromJS } from 'immutable';
import { decorate } from '../../../decorators';

export const { Types: AuthorPageTypes, Creators: AuthorPageActions } = createActions({
  // Kicks off saga
  fetchAuthor: ['slug', 'isPreview', 'opts'],
  // Saves data returned from contentful
  fetchAuthorSuccess: ['items', 'slug'],
  setCurrentAuthorPage: ['authorId'],
}, { prefix: 'AUTHOR_PAGE_' });

const AuthorRecord = new Record({
  currentAuthor: '',
  authors: Map(),
});

export const INITIAL_STATE = new AuthorRecord({});

const fetchAuthorSuccessHandler = (state, { items, slug }) => state.setIn(['authors', slug], decorate(fromJS(items)));

const handleAuthorPageChanged = (state, { authorId }) => state.set('currentAuthor', authorId);

export const reducer = createReducer(INITIAL_STATE, {
  [AuthorPageTypes.FETCH_AUTHOR_SUCCESS]: fetchAuthorSuccessHandler,
  [AuthorPageTypes.SET_CURRENT_AUTHOR_PAGE]: handleAuthorPageChanged,
});
