import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Link, Icon, Label, ActiveLanguage, ArrowDown } from './language.styles';
import { renderWhenTrueOtherwise } from '../../../../utils/rendering';
import { Hoverable } from '../../../../components/hoverable';

export class Language extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    icon: PropTypes.string.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func,
  };

  render() {
    const { label, url, icon, active, onClick } = this.props;

    return (
      renderWhenTrueOtherwise(
        () => (
          <ActiveLanguage onClick={onClick}>
            <Icon image={icon} />
            <Label>{label}</Label>
            <ArrowDown />
          </ActiveLanguage>
        ),
        () => (
          <Hoverable>
            <Link url={url} newTab={false}>
              <Icon image={icon} />
              <Label>{label}</Label>
            </Link>
          </Hoverable>
        ),
      )(active)
    );
  }
}
