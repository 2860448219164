import styled from 'styled-components';

import { media } from '../../../theme/media';
import {
  Section as SectionComponent,
  H2 as H2Component,
  OUTER_GRID_WIDTH,
  PADDING_HORIZONTAL_SMALL,
  PADDING_HORIZONTAL_LARGE,
  GUTTER_RESPONSIVE,
  COLORS,
} from '../styles/homePageStyles';
export { Button } from '../button/button.styles';

/** On wide screens, the left gap between where the `OuterContainer` would be and the viewport */
const REMAINDER = `calc((100vw - ${OUTER_GRID_WIDTH}) / 2)`;
/** On wide screens, the distance from the left to the 1/3 mark of where the `OuterContainer` would be */
const DIVIDING_LINE = `calc((${OUTER_GRID_WIDTH} / 3) + ${REMAINDER})`;

export const Section = styled(SectionComponent)`
  background-color: ${COLORS.BLACK};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    flex-direction: row;
    max-height: 600px;
  `};
`;

export const TextSection = styled.div`
  order: 2;
  padding: ${GUTTER_RESPONSIVE};
  // Ensure bottom padding is equal to top padding plus line-height difference
  padding-bottom: clamp(${PADDING_HORIZONTAL_SMALL} + 12.5px, 6.16vw + 12.5px, ${PADDING_HORIZONTAL_LARGE} + 12.5px);

  ${media.tablet`
    order: 1;
    width: calc(100vw / 3);
    // Ensure that text always has some left padding,
    // and that on wide screens, it starts at outer content boundary
    padding-inline-start: max(${GUTTER_RESPONSIVE}, ${REMAINDER});
    padding-bottom: ${GUTTER_RESPONSIVE};
  `};

  @media screen and (min-width: ${OUTER_GRID_WIDTH}) {
    width: ${DIVIDING_LINE};
  }
`;

export const InnerText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  color: ${COLORS.WHITE};
  word-wrap: break-word;
`;

export const H2 = styled(H2Component)`
  line-height: 1.25;
  font-weight: 700;

  ${media.tablet`
    line-height: 1.1;
    font-size: clamp(2rem, 4.25vw, 2.5rem);
  `}
`;

export const ImageSection = styled.div`
  order: 1;
  line-height: 0;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.tablet`
    order: 2;
    width: calc(100% * 2 / 3);
    position: absolute;
    left: calc(100% / 3);
  `};

  // Ensure that, on wide screens, the image starts at the dividing line
  // And extends to the right edge of the viewport
  @media screen and (min-width: ${OUTER_GRID_WIDTH}) {
    width: calc(100% - ${DIVIDING_LINE});
    left: ${DIVIDING_LINE};
  }
`;
