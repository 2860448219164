import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './desktopCarousel.styles';
import { INITIAL_ANIMATION_DURATION } from './desktopCarousel.styles';
import { OuterContainer, Pad } from '../../styles/homePageStyles';
import Arrow from './arrowIconSvg';
import { useDragScroll } from '../useDragScroll';
import StoryBlock from '../storyBlock/storyBlock.component';

const DesktopCarousel = ({ storyBlocks, storyOrder, isOnScreen }) => {
  const containerRef = useRef(null);
  const [initialAnimationDone, setInitialAnimationDone] = useState(false);
  const storyCount = storyBlocks.length;
  const enableDesktopCarousel = storyCount > 3;
  const [scrolledStory, setScrolledStory] = useState(null);

  const displayBackArrow = enableDesktopCarousel && isOnScreen && scrolledStory !== storyOrder[0];
  const displayForwardArrow =
    enableDesktopCarousel && isOnScreen && scrolledStory !== storyOrder[storyCount - 3];

  // Compute tallest block height on screen to determine block divider height
  const [blockHeights, setBlockHeights] = useState([]);
  const updateBlockHeights = () => {
    if (containerRef.current) {
      setBlockHeights(
        [...containerRef.current.children].map(storyBlock => storyBlock.clientHeight),
      );
    }
  };
  const scrolledStoryIndex = storyOrder.indexOf(scrolledStory);
  const onScreenBlockHeights = blockHeights.slice(scrolledStoryIndex, scrolledStoryIndex + 3);
  const tallestOnScreenBlockHeight = Math.max(...onScreenBlockHeights);

  // Ensure right arrow only animates initially
  useEffect(() => {
    if (isOnScreen) {
      containerRef.current.scrollLeft = 0;
      setTimeout(() => {
        setInitialAnimationDone(true);
      }, INITIAL_ANIMATION_DURATION * 1000);
    }
  }, [isOnScreen]);

  // Set initially scrolled-into-view story
  useEffect(() => {
    setScrolledStory(storyOrder[0]);
  }, [storyCount]);

  // Reset scrolled story to first story on container resize to prevent buggy behavior
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      updateBlockHeights();
      if (enableDesktopCarousel) {
        setScrolledStory(storyOrder[0]);
      }
    });
    resizeObserver.observe(containerRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  const handleScroll = (back = false) => {
    const direction = back ? -1 : 1;
    const increment = 3;
    let nextStoryIndex = storyOrder.indexOf(scrolledStory) + increment * direction;
    if (nextStoryIndex < 0 || nextStoryIndex > storyCount - 3) {
      nextStoryIndex = storyOrder.indexOf(scrolledStory) + (storyCount % increment) * direction;
    }
    const nextStory = storyOrder[nextStoryIndex];
    if (nextStory) {
      updateBlockHeights();
      setScrolledStory(nextStory);
    }
  };

  // Ensures carousel scrolls properly when accessed via keyboard navigation
  const handleKeyUp = focusedStoryIndex => {
    if (focusedStoryIndex < scrolledStoryIndex) {
      handleScroll(true);
    } else if (focusedStoryIndex >= scrolledStoryIndex + 3) {
      handleScroll();
    }
  };

  // Drag gesture logic
  const [scrollOffset, setScrollOffset] = useState(null);
  const [dragDirection, setDragDirection] = useState(null);
  const [dragging, setDragging] = useState(false);

  // Add drag event listeners for mouse and touch
  useDragScroll(containerRef.current, setDragDirection, setDragging, enableDesktopCarousel);

  // Update scrolled story based on drag direction
  useEffect(() => {
    if (dragDirection && enableDesktopCarousel) {
      const isBack = dragDirection === 'left';
      if ((displayForwardArrow && !isBack) || (displayBackArrow && isBack)) {
        handleScroll(isBack);
      }
    }
    setDragDirection(null);
  }, [dragDirection]);

  // Scroll to location of currently scrolled story when it updates its offset
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: scrollOffset,
        behavior: 'smooth',
      });
    }
  }, [scrollOffset]);

  return (
    <Pad>
      <OuterContainer>
        {displayBackArrow && (
          <Styled.ArrowButton back onClick={() => handleScroll(true)}>
            <Arrow />
          </Styled.ArrowButton>
        )}
        <Styled.ScrollingContainer ref={containerRef} dragging={dragging}>
          {storyBlocks.map((storyBlock, i) => (
            <Styled.StoryWrapper
              key={storyBlock.id}
              isOnScreen={isOnScreen}
              dragging={dragging}
              dividerHeight={tallestOnScreenBlockHeight}
            >
              <StoryBlock
                scrolledStory={scrolledStory}
                setScrollOffset={setScrollOffset}
                handleKeyUp={() => handleKeyUp(i)}
                {...storyBlock}
              />
            </Styled.StoryWrapper>
          ))}
        </Styled.ScrollingContainer>
        {displayForwardArrow && (
          <Styled.ArrowButton animate={!initialAnimationDone} onClick={() => handleScroll(false)}>
            <Arrow />
          </Styled.ArrowButton>
        )}
      </OuterContainer>
    </Pad>
  );
};

DesktopCarousel.propTypes = {
  storyBlocks: PropTypes.arrayOf(PropTypes.object),
  storyOrder: PropTypes.arrayOf(PropTypes.string),
  isOnScreen: PropTypes.bool,
};

export default DesktopCarousel;
