/**
 * @module
 * This removes cycles in an object, so that it can be put into immutable.
 * It recursively checks each of an object's props to see if the value has been included previously.
 * Removes all repetitions. Ignores 'sys' keys.
 **/

// Symbol used to ensure uniqueness.
const noChange = Symbol('noChange');
// Sys keys skipped to cut computation time, these never introduce cycles for contentful objects.
const isSys = key => key === 'sys';

//This detects how many of the given element have already been seen, returns true if more than one have.
const includesMoreThanOne = (array, element) => array.includes(element);

/**
 * @method
 * @param {object} objToDecycle - Object to remove cycles from
 * @param {array} [alreadySeen=[]] - used for recursive calls only!
 * @param {boolean} [isTopObj=true] - used for recursive calls only!
 *
 * @return partial copy of object without cycles.
 **/

const removeCycles = (objToDecycle, alreadySeen = [], isTopObj = true) => {
  if (includesMoreThanOne(alreadySeen, objToDecycle)) { return '[removed]'; }
  if (typeof objToDecycle !== 'object') { return noChange; }
  const keys = Object.keys(objToDecycle);
  const newAlreadySeen = alreadySeen.concat([objToDecycle]);
  const subValues = keys.map(key => isSys(key) ? noChange : removeCycles(objToDecycle[key], newAlreadySeen, false));
  if (subValues.every(val => val === noChange) && !isTopObj) { return noChange; }
  const newObj = new objToDecycle.constructor();
  for (let i = 0; i < keys.length; i++) {
    const newValue = subValues[i] === noChange ? objToDecycle[keys[i]] : subValues[i];
    Object.assign(newObj, { [keys[i]]: newValue });
  }
  return newObj;
};

export default removeCycles;
