import React from 'react';

const LazyThreeColumnStatisticsSection = React.lazy(() =>
  import('./threeColumnStatisticsSection.container'),
);
const SuspendedThreeColumnStatisticsSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyThreeColumnStatisticsSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedThreeColumnStatisticsSection;
