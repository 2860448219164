import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './button.styles';
import { BUTTON_ARRAY_COLOR } from './button.styles';
import MendedHeart from '../../assets/images/mendedHeartSvg';
import { BUTTON_COLOR_LABEL, TEXT_COLOR } from '../../styles/homePageStyles';

const Button = ({ text, withEmbellishment = false, color = BUTTON_COLOR_LABEL.BLUE, url }) => {
  return (
    <Styled.LinkContainer href={url} backgroundColor={BUTTON_ARRAY_COLOR[color]}>
      <Styled.StyledButton withEmbellishment={withEmbellishment} textColor={TEXT_COLOR[color]}>
        {withEmbellishment && (
          <Styled.Embellishment>
            <MendedHeart colorHex={TEXT_COLOR[color]} />
          </Styled.Embellishment>
        )}
        {text.toUpperCase()}
      </Styled.StyledButton>
    </Styled.LinkContainer>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  withEmbellishment: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(Object.keys(BUTTON_ARRAY_COLOR)).isRequired,
  url: PropTypes.string.isRequired,
};

export default Button;
