import React from 'react';

const LazyPageLinkSection = React.lazy(() => import('./pageLinkSection.container'));

const SuspendedPageLinkSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyPageLinkSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedPageLinkSection;
