/**
 * Decorator providing utility functions for lists of content models.
 *
 * @module ListDecorator
 **/

import Decorator from './decorator';

export default class ListDecorator extends Decorator {
  /**
   * Returns the first item in the list.
   *
   * @public
   * @return {Object} The first item of the list.
   */
  first(num) {
    if (num) {
      return this.toArray().slice(0, num).
        map(element => ListDecorator.decorate(element));
    }
    return ListDecorator.decorate(this.at(0));
  }

  /**
   * Returns an item in the list.
   *
   * @public
   * @param {number} index - the index of the item to return;
   * @return {Object} The indexed item of the list.
   */
  at(index) {
    return ListDecorator.decorate(this.data.get(index));
  }

  /**
   * Returns a boolean representing whether the list contains more than one member.
   *
   * @public
   * @return {boolean} Whether the list contains two or more members.
   */
  hasMultiple() {
    return this.data.size > 1;
  }

  isEmpty() {
    return this.data.size === 0;
  }

  get size() {
    return this.data.toArray().length;
  }

  map(...args) {
    return this.data.map(x => ListDecorator.decorate(x)).map(...args);
  }

  forEach(...args) {
    return this.data.map(x => ListDecorator.decorate(x)).forEach(...args);
  }

  filter(...args) {
    return this.data.filter(...args);
  }

  toArray(...args) {
    return this.data.toArray(...args);
  }

  slice(...args) {
    return this.data.slice(...args);
  }
}
