import React from 'react';

const LazyArticleCtaBlock = React.lazy(() => import('./articleCtaBlock.container'));

const SuspendedArticleCtaBlock = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyArticleCtaBlock ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedArticleCtaBlock;
