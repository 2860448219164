import React from 'react';

const LazyArticle = React.lazy(() => import('./article.container'));

const SuspendedArticle = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyArticle ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedArticle;
