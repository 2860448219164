import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { SharedLayout } from './sharedLayout.component';
import { selectInitialLoaded } from '../../reducers/pageLoader/pageLoader.selectors';
import { selectHeaderStyle } from '../../reducers/menu/menu.selectors';
import { AccessoryActions } from '../../reducers/accessories/accessories.redux';
import { selectCurtainContent } from '../../reducers/accessories/accessories.selectors';

const mapStateToProps = createStructuredSelector({
  isInitialLoaded: selectInitialLoaded,
  headerStyle: selectHeaderStyle,
  curtain: selectCurtainContent,
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccessories: AccessoryActions.fetchAccessories,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SharedLayout);
