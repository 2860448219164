import React from 'react';
import PropTypes from 'prop-types';
import { VideoPlayIconWrapper } from './videoPlayIcon.styles';
import videoPlayIconSvg from './video-play-icon.svg';

const VideoPlayIcon = ({ className }) => {
  return (
    <VideoPlayIconWrapper className={className}>
      <img src={videoPlayIconSvg} alt="Play video" draggable={false} />
    </VideoPlayIconWrapper>
  );
};

VideoPlayIcon.propTypes = {
  className: PropTypes.string,
};

export default VideoPlayIcon;
