import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LinkList, Nav, CollapsedTitle, COLLAPSE_TIME } from './collapsableLinkList.styles';
import { Collapse } from '../collapse';
import { ariaLiveHandler } from '../../utils/accessibility';
import { PlatformDetector } from '../platformDetector/platformDetector.component';
import { isPrerenderBot } from '../../utils/userAgent';

export class CollapsableLinkList extends PureComponent {
  static propTypes = {
    links: PropTypes.object,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.nav = React.createRef();
  }

  render() {
    const { links, title } = this.props;

    return (
      <PlatformDetector>
        {
          ({ isMobile, isTabletPortrait, isTabletLandscape }) => (
            <Collapse component={this.nav} visibilityDuration={COLLAPSE_TIME}>
              {
                ({ collapsed, collapse, itemsAccessible }) => (
                  <Nav
                    isSmallDevice={isMobile || isTabletPortrait || isTabletLandscape}
                    collapse={collapsed}
                    ref={this.nav}
                    linksLength={links.size}
                  >
                    <CollapsedTitle
                      onClick={collapse}
                      collapse={collapsed}
                      aria-expanded={!collapsed}
                      aria-label={title}
                      aria-live={ariaLiveHandler()}
                    >
                      {title}
                    </CollapsedTitle>
                    <LinkList
                      links={links}
                      title={title}
                      isSmallDevice={isMobile || isTabletPortrait || isTabletLandscape}
                      isVisible={itemsAccessible || isPrerenderBot}
                    />
                  </Nav>
                )
              }
            </Collapse>
          )
        }
      </PlatformDetector>
    );
  }
}
