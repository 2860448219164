import { combineReducers } from 'redux';
import { reducer as localesReducer, LocalesRecord } from './locales/locales.redux';
import { reducer as pageReducer } from './page/page.redux';
import { reducer as menuReducer } from './menu/menu.redux';
import { reducer as footerReducer } from './footer/footer.redux';
import { reducer as newsReducer } from './news/news.redux';
import { reducer as videoReducer } from './video/video.redux';
import { reducer as languagesReducer } from './languages/languages.redux';
import { reducer as accessoriesReducer } from './accessories/accessories.redux';
import { reducer as pageLoaderReducer } from './pageLoader/pageLoader.redux';
import { reducer as configReducer } from './config/config.redux';
import { reducer as peopleHrReducer } from './peopleHr/peopleHr.redux';


export const records = [
  LocalesRecord,
];

export default function createReducer() {
  return combineReducers({
    locales: localesReducer,
    page: pageReducer,
    menu: menuReducer,
    footer: footerReducer,
    articles: newsReducer,
    video: videoReducer,
    languages: languagesReducer,
    accessories: accessoriesReducer,
    pageLoader: pageLoaderReducer,
    peopleHr: peopleHrReducer,
    config: configReducer,
  });
}
