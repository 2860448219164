import React, { useEffect, useState, Fragment } from 'react';
import { useLocation } from 'react-router';

export const RouterScrollToTop = () => {
  const { pathname } = useLocation();
  const [prevPath, setPrevPath] = useState(pathname);

  // Scroll to top of page if main is not in the viewport
  const scrollToTopIfNeeded = () => {
    const main = document.getElementsByTagName('main')[0];
    if (main) {
      const rect = main.getBoundingClientRect();
      if (rect.height <= Math.abs(rect.top)) {
        window.scrollTo(0, 0);
      }
    }
  };

  const detectScrolledToBottom = () => {
    scrollToTopIfNeeded();
    let timesChecked = 0;
    const interval = setInterval(() => {
      scrollToTopIfNeeded();
      if (timesChecked >= 10) {
        clearInterval(interval);
      }
      timesChecked++;
    }, 100);
  };

  // Scroll to top whenever a page loads scrolled to the bottom (including on navigating back)
  useEffect(() => {
    window.addEventListener('load', detectScrolledToBottom);
    window.addEventListener('popstate', detectScrolledToBottom);
    return () => {
      window.removeEventListener('load', detectScrolledToBottom);
      window.removeEventListener('popstate', detectScrolledToBottom);
    };
  }, []);

  // Scroll to top on routing to a new page (excluding on navigating back)
  useEffect(() => {
    if (prevPath !== pathname) {
      window.scrollTo(0, 0);
    }
    setPrevPath(pathname);
  }, [pathname]);

  return <Fragment />;
};
