import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../theme/styled';

import { Line } from './lineClip.styles';

export const Direction = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const Orientation = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export class LineClip extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    orientation: PropTypes.string,
    direction: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    height: 250,
    orientation: Orientation.DOWN,
    direction: Direction.LEFT,
    color: colors.white,
  };

  render() {
    const { height, color, orientation, direction, className } = this.props;

    return (
      <Line
        className={className}
        height={height}
        color={color}
        top={orientation === Orientation.TOP}
        right={direction === Direction.RIGHT}
      />
    );
  }
}
