import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List as ImmutableList } from 'immutable';
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';
import compose from 'ramda/es/compose';
import sortBy from 'ramda/es/sortBy';
import { SectionViewportContext } from '../../components/contentManager/pageSection/pageSection.component';
import { WindowListener } from '../../components/windowListener/windowListener.component';

import {
  Container,
  FeaturedLink,
  FormContainer,
  LinkList,
  SocialIcons,
  TermsList,
  TRANSITION_DURATION,
} from './sideMenu.styles';
import { ArrowLinkItem } from './arrowLinkItem/arrowLinkItem.component';
import { renderWhenTrueOtherwise, isBelowBurgerResolution } from '../../utils/rendering';
import LanguageList from './languageList/languageList.container';
import { getField, getFieldIn } from '../../utils/cmsData';
import { ContentManager } from '../../components/contentManager';

const mapIndexed = addIndex(map);

const preAppEl = document.getElementById('preApp');

export class SideMenu extends PureComponent {
  static propTypes = {
    menu: PropTypes.object,
    form: PropTypes.object,
    isOpened: PropTypes.bool,
    linksList: PropTypes.object,
    socialIcons: PropTypes.object,
    termsLinkList: PropTypes.object,
    setMenuOpened: PropTypes.func,
    location: PropTypes.object,
    socialIconsTitle: PropTypes.string,
    headerStyle: PropTypes.string,
  };

  state = {
    shouldDisplay: false,
    initialised: false,
    offset: preAppEl.offsetHeight,
    isBelowBurgerResolution: isBelowBurgerResolution(),
  };

  componentDidMount() {
    // Monitor preApp div for height changes.
    this.preAppObserver = new MutationObserver(() => {
      const elements = Array.from(document.querySelectorAll('[data-offset-sidemenu]'));
      const offset = elements.reduce((a, e) => a + e.offsetHeight, 0);
      this.setState({ offset });
    });

    this.preAppObserver.observe(preAppEl, {
      childList: true,
      subtree: true,
    });
  }

  componentDidUpdate(prevProps) {
    const { location, setMenuOpened, isOpened } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      setMenuOpened(false);
    }

    if (isOpened) {
      this.setState({ shouldDisplay: true, initialised: true });
      if (this.sideMenu && this.sideMenu.current) {
        this.sideMenu.current.scrollTop = 0;
      }
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({ shouldDisplay: false });
      }, TRANSITION_DURATION * 1000);
    }
  }

  componentWillUnmount() {
    if (this.preAppObserver) {
      this.preAppObserver.disconnect();
    }
    clearTimeout(this.timer);
  }

  get menu() {
    return this.props.menu || new ImmutableList();
  }

  sideMenu = React.createRef();
  timer = null;

  handleWindowResize = () => {
    const belowBurgerResolution = isBelowBurgerResolution();
    if (belowBurgerResolution !== this.state.isBelowBurgerResolution) {
      this.setState({ isBelowBurgerResolution: belowBurgerResolution });
    }
  };

  render() {
    const {
      form,
      linksList,
      isOpened,
      socialIcons,
      socialIconsTitle,
      termsLinkList,
    } = this.props;

    if (!this.menu.size || !this.state.isBelowBurgerResolution) {
      return (
        <WindowListener
          handledFunction={this.handleWindowResize}
          throttleValue={250}
          eventType="resize"
        />
      );
    }

    return (
      <SectionViewportContext.Provider value={{ isActive: this.state.initialised }}>
        <Container
          isOpened={isOpened}
          ref={this.sideMenu}
          tabindex="-1"
          shouldDisplay={this.state.shouldDisplay}
          offset={this.state.offset}
        >
          <WindowListener
            handledFunction={this.handleWindowResize}
            throttleValue={250}
            eventType="resize"
          />
          {compose(
            mapIndexed((item, index) =>
              renderWhenTrueOtherwise(
                () => (
                  <FeaturedLink
                    key={index}
                    url={getFieldIn(['url', 'url'], item)}
                    label={getField('title', item)}
                    useSecondaryColor={getField('useSecondaryColor', item)}
                  />
                ),
                () => <ArrowLinkItem key={getField('title', item)} item={item} />,
              )(getField('featured', item)),
            ),
            sortBy(item => !!getField('featured', item)),
          )(this.menu.toArray())}
          <LanguageList />
          <FormContainer shouldDisplay={this.state.shouldDisplay}>
            <ContentManager content={form} />
          </FormContainer>
          <LinkList links={getField('items', linksList)} />
          <SocialIcons title={socialIconsTitle} icons={socialIcons} />
          <TermsList links={getField('items', termsLinkList)} />
        </Container>
      </SectionViewportContext.Provider>
    );
  }
}
