import React from 'react';

const LazyListWithImageSection = React.lazy(() => import('./listWithImageSection.container'));

const SuspendedListWithImageSection = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyListWithImageSection ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedListWithImageSection;
