import React from 'react';

const LazyProgressBar = React.lazy(() => import('./progressBar.container'));

const SuspendedProgressBar = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyProgressBar ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedProgressBar;
