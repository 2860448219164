import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getField } from '../../utils/cmsData';

const categoryGetterPrefix = category => ['articlesByCategory', category || 'all'];
const authorGetterPrefix = author => ['articlesByAuthor', author];

export const selectArticlesDomain = state => state.articles;

export const selectActiveCategory = createSelector(
  selectArticlesDomain,
  state => {
    const category = state.get('activeCategory');
    return category ? getField('name', category) : null;
  },
);

export const selectSubcategoriesActiveCategory = createSelector(
  selectArticlesDomain,
  state => {
    const category = state.get('activeCategory');
    return category ? getField('subcategories', category) : List();
  },
);

export const selectAllCategories = createSelector(
  selectArticlesDomain,
  state => state.get('categories'),
);

export const selectCategoriesHierarchy = createSelector(
  selectAllCategories,
  categories => categories.filter(getField('isMain')),
);

export const selectActiveMainCategory = createSelector(
  selectCategoriesHierarchy,
  selectActiveCategory,
  (allMainCategories, activeCategory) => {
    const category = allMainCategories.find(
      mainCategory => {
        const isActive = category => getField('name', category) === activeCategory;
        const isMainActive = isActive(mainCategory);
        const subcategories = getField('subcategories', mainCategory) || List();
        const isSubcategoryActive = subcategories.findIndex(isActive) > -1;

        return isMainActive || isSubcategoryActive;
      }
    );
    return category ? getField('name', category) : null;
  },
);

export const selectLimitReached = createSelector(
  selectArticlesDomain,
  selectActiveCategory,
  (state, activeCategory) => {
    return state.getIn([...categoryGetterPrefix(activeCategory), 'limitReached']) || false;
  }
);

export const selectAllArticles = createSelector(
  selectArticlesDomain,
  selectActiveCategory,
  (state, activeCategory) => {
    return state.getIn([...categoryGetterPrefix(activeCategory), 'articles']) || List();
  },
);

export const selectArticlesByAuthor = createSelector(
  selectArticlesDomain,
  (state) => {
    return authorId => {
      return state.getIn([...authorGetterPrefix(authorId), 'articles']) || List();
    };
  },
);
