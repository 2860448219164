import styled from 'styled-components';

import { COLORS, Pad, InnerContainer as StyledInnerContainer } from '../styles/homePageStyles';
import { media } from '../../../theme/media';
export { PseudoFuzzyBackground } from '../pseudoFuzzyBackground/pseudoFuzzyBackground.styles';

export const Carousel = styled.div`
  background: ${COLORS.MEDIUM_DARK_GRAY};
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 800px;

  ${media.mobileSmall`
    height: clamp(800px, 90svh, 950px);
  `};

  ${media.desktop`
    height: clamp(800px, 90svh, ${props => (props.isPaginated ? '1220' : '1075')}px);
  `};
`;

export const NavigationPad = styled(Pad)`
  position: absolute;
  left: 0;
  width: 100%;

  ${media.tablet`
    bottom: max(6rem, calc(50% - 16rem));
  `};

  ${media.desktop`
    bottom: max(6rem, calc(50% - 17.5rem));
  `};
`;

export const NavigationContainer = styled(StyledInnerContainer)`
  width: 100%;
`;

export const Navigation = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
  gap: 20px;
  height: 15px;
`;

export const NavigationButton = styled.button`
  border: none;
  width: 15px;
  height: 15px;
  background: ${COLORS.WHITE};
  font-size: 1px;
  line-height: 0;
  overflow: hidden;
  border-radius: 50%;
  opacity: ${props => (props.isActive ? 1 : 0.2)};
  cursor: pointer;
  transition: 0.1s all ease-out;
  transition-property: opacity, transform;
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'unset')};

  @media (prefers-reduced-motion) {
    transition: none;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.3);
    opacity: 0.6;
  }
`;
