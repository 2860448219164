import styled from 'styled-components';
import { colors, fontWeights } from '../../theme/styled';
import { media } from '../../theme/media';
import { TriangleClip } from '../triangleClip';
import { title, featuredTitle, text } from '../../theme/typography';
import { Section as SectionStyled } from '../section/section.component';
import { VideoIcon } from '../video/videoIcon';
import { Text } from '../text';
import { LineDecoration as LineDecorationComponent } from '../lineDecoration';
import { Line as LineComponent } from '../lineDecoration/lineDecoration.styles';
import { BoxShadow as BoxShadowComponent } from '../boxShadow/boxShadow.component';
import { Content as ContentBoxShadow } from '../boxShadow/boxShadow.styles';

export const Section = styled(SectionStyled)`
  position: ${props => props.form ? 'absolute' : 'relative'};
  padding-top: 0;
  padding-bottom: 0;

  ${media.desktop`
    padding-left: 70px;
    padding-right: 70px;
  `};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;
  min-height: ${props => props.minHeight}px;
  overflow: hidden;

  ${media.tabletLarge`
    min-height: ${props => props.minHeight}px;
  `};

  ${media.desktop`
    min-height: ${props => props.minHeight}px;
  `};
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: ${props => props.maxHeight}px;

  ${media.tabletLarge`
    max-height: ${props => props.maxHeight}px;
  `}

  ${media.desktop`
    max-height: ${props => props.maxHeight}px;
  `}
`;

export const GradientContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    ${colors.black} ${props => props.subtle ? '185%' : '115%'}
  );
  opacity: ${props => props.opacity / 100};
`;

export const FormContainer = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none'};
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 1300px;
  z-index: 1;
  margin-bottom: 93px;
  margin-top: ${props => props.marginTop}px;

  ${media.tablet `
    margin-bottom: 0;
    padding-bottom: ${props => props.isPaddingBottom ? 107 : 0}px;
  `};

  ${media.desktop `
    padding-right: 0;
    align-items: ${props => props.isPreForm ? 'center' : 'flex-end'};
  `};

  ${media.desktopFull `
    max-width: 1920px;
  `};
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: left;
  margin-top: 60px;
  padding-top: 40px;
  padding-bottom: 60px;

  ${media.tablet`
    max-width: ${props => props.wideHeroText ? '632px' : '312px'};
  `}

  ${media.desktop`
    padding-top: 96px;
    max-width: ${props => props.wideHeroText ? '628px' : '308px'};
  `};

  ${media.desktopFull`
    padding-top: 196px;
  `};
`;

export const Title = styled.h1`
  ${title};
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: ${props => props.isLarge ? 48 : 36}px;
  line-height: ${props => props.isLarge ? 1.08 : 1.17};
  max-width: 264px;

  ${media.tablet `
    margin-bottom: 24px;
    max-width: 100%;
  `}

  ${media.desktop `
    font-size: ${props => props.isLarge ? 60 : 48}px;
    line-height: ${props => props.isLarge ? 'normal' : 1.08};
  `};
`;

export const Description = styled(Text)`
  ${text};
  color: ${colors.white};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  h1, h2, h3, h4, h5, h6 {
    ${title};
    color: ${colors.white};
    font-size: 28px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
  }

  ${media.tablet`
    max-width: ${props => props.wideHeroText ? '62%' : '100%'};
  `};
`;

export const Label = styled.span`
  ${featuredTitle};
  font-size: 28px;
  line-height: 1.43;
  letter-spacing: 1px;
  margin-bottom: 12px;
  color: ${colors.white};
`;

export const SocialsTitle = styled.p`
  color: ${colors.white};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.33;
  font-weight: ${fontWeights.bold};
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const ArrowBar = styled(TriangleClip)`
  position: absolute;
  bottom: 0;
`;

export const VideoCta = styled(VideoIcon)`
  cursor: pointer;
  width: 64px;
  height: 64px;

  ${media.tabletLarge`
    width: 88px;
    height: 88px;
  `};
`;

export const VideoCtaContainerMobile = styled.div`
  position: relative;
  height: 64px;
  width: 100%;
  margin-top: 2rem;

  &.hovered {
    ${VideoCta} {
      background-color: ${colors.accessibilityYellow};
    }
  };
`;

export const VideoCtaContainer = styled.div`
  position: absolute;
  z-index: 2;
  display: block;
  top: ${props => props.isForm ? 51.1 : 39.8}%;
  right: 31.7%;

  &.hovered {
    ${VideoCta} {
      background-color: ${colors.accessibilityYellow};
    }
  };
`;

export const LineDecoration = styled(LineDecorationComponent)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 83px;

  ${LineComponent} {
    height: 75px;

    &:after {
      top: 75px;
    }
  }
`;

export const BoxShadow = styled(BoxShadowComponent)`
  max-width: 375px;

  ${media.tablet`
    max-width: 859px;
  `};

  ${media.desktopFull`
    max-width: 1059px;
  `};

  ${ContentBoxShadow} {
    width: 100%;
    padding: 24px 24px 100px;

    ${media.tablet`
      padding: 56px;
    `};
  };
`;
