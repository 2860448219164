import React from 'react';

const LazyModal = React.lazy(() => import('./modal.container'));

const SuspendedModal = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyModal ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedModal;
