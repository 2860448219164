import React from 'react';

const LazyArticleCardsList = React.lazy(() => import('./articleCardsList.container'));

const SuspendedArticleCardsList = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyArticleCardsList ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedArticleCardsList;
