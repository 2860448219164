import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Link, Label, Arrow } from './arrowLink.styles';

export class ArrowLink extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
  };

  render() {
    const { label, url } = this.props;

    return (
      <Link url={url}>
        <Label>{label}</Label>
        <Arrow />
      </Link>
    );
  }
}
