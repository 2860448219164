import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Header } from './hiddenHeader.styles';


export class HiddenHeader extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    headerLevel: PropTypes.number.isRequired,
  };

  render() {
    const { title, headerLevel } = this.props;

    return (
      <Header as={`h${headerLevel}`}>{title}</Header>
    );
  }
}
