/**
 * Attach an object to the window.
 *
 * @module
 */

/**
 * @method
 *
 * @param object - the object to merge into the thl key.
 *
 * @example
 * expose({ triggerPop })
 **/
export default function expose(object) {
  window.thl = window.thl || {};
  Object.assign(window.thl, object);
}
