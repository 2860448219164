import styled from 'styled-components';
import { H3, COLORS, FONTS } from '../../styles/homePageStyles';
import { media } from '../../../../theme/media';
import { VideoPlayIcon } from '../../videoPlayIcon';
import { STORY_BLOCK_IMAGE_HEIGHT, PROGRESS_TAG_OVERHANG } from '../threeUpScrollingBlock.styles';
import { Button } from '../../button/button.styles';
import { Image } from '../../../image';
import { FormattedA } from '../../link';

export const StoryBlockContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  ${H3} {
    margin: 23px 0 12px;

    ${media.mobile`
      margin: 27px 0 18px;
    `};
  }
`;

export const LinkContainer = styled(FormattedA)`
  display: block;
  max-width: 402px;
  outline: none;

  * {
    pointer-events: none;
    user-select: none;
  }
`;

export const StoryImage = styled(Image)`
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 185px;
  width: 100%;
  pointer-events: auto;

  ${StoryImage} {
    transform: none;
    transition: transform 0.2s ease-in-out;
  }

  &:hover,
  &:active {
    ${StoryImage} {
      transform: scale(1.1);
    }
  }

  &:active {
    pointer-events: none;
  }

  ${media.mobile`
    height: ${STORY_BLOCK_IMAGE_HEIGHT};
  `};
`;

// Adding another wrapper because overflow: hidden on ImageContainer would cut off the progress tag
export const ScaledImageWrapper = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const StoryText = styled.p`
  :not(:last-child) {
    margin-bottom: 20px;

    ${media.mobile`
      margin-bottom: 25px;
    `};
  }
`;

export const VideoEmbellishment = styled(VideoPlayIcon)`
  img {
    bottom: 5%;
    left: 3.5%;
    width: 78px;
    height: auto;
    pointer-events: auto;
  }
`;

export const ProgressTag = styled.div`
  --decoration-width: 8.097px;
  --decoration-height: 6.096px;
  background: ${COLORS.RED};
  color: ${COLORS.WHITE};
  position: absolute;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10%;
  right: calc(var(--decoration-width) * -1);
  padding: 0 12px 3px;
  font-family: ${FONTS.SECOND_FONT};
  font-style: italic;
  font-weight: bold;
  font-size: 1rem;

  &:after {
    content: '';
    position: absolute;
    width: var(--decoration-width);
    height: var(--decoration-height);
    background-color: #871418;
    clip-path: polygon(100% 0, 0 100%, 0 0);
    bottom: calc(var(--decoration-height) * -1);
    right: 0;
  }

  ${media.mobile`
  --decoration-width: ${PROGRESS_TAG_OVERHANG};
  --decoration-height: 7.36px;
    height: 35.15px;
    font-size: 1.188rem;
    padding: 0 10px 3px 13px;
  `};
`;

export const CTAButton = styled(Button).attrs({
  as: 'button',
})`
  pointer-events: auto;
`;
