import React from 'react';

const LazyVerticalStatisticsList = React.lazy(() => import('./verticalStatisticsList.container'));

const SuspendedVerticalStatisticsList = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyVerticalStatisticsList ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedVerticalStatisticsList;
