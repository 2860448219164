import styled from 'styled-components';
import { Link as LinkComponent } from '../../../components/link/link.component';
import { H2 } from '../../../theme/layout';
import { title } from '../../../theme/typography';
import { colors } from '../../../theme/styled';
import ArrowDownImage from '../../../images/arrow-expand-down.svg';


export const Link = styled(LinkComponent)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  border-top: 1px solid ${colors.borderMenuItem};
  -webkit-tap-highlight-color: transparent;
`;

export const Label = styled(H2)`
  ${title};
  color: ${colors.white};
  font-size: 14px;
  letter-spacing: 2px;
`;

export const Arrow = styled.div`
  width: 44px;
  height: 44px;
  background-image: url(${ArrowDownImage});
  background-size: cover;
  transform: rotate(-90deg);
`;
