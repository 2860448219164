import { bindActionCreators } from 'redux';
import identity from 'ramda/es/identity';
import compose from 'ramda/es/compose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ArticleFigureBlock } from './articleFigureBlock.component';
import { VideoActions } from '../../../reducers/video/video.redux';

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  openVideo: VideoActions.openVideo,
}, dispatch);

export default compose(
  injectIntl,
  connect(identity, mapDispatchToProps),
)(ArticleFigureBlock);
