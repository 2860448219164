import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';

import {
  CurtainContainer,
  CurtainImage,
  MendedHeart,
  CurtainTitle,
  CurtainText,
} from './curtain.styles';
import messages from './curtain.messages';
import { Image } from '../image/image.component';
import { Button } from '../button/button.component';
import { TextShadow } from '../textShadow/textShadow.component';
import { PlatformDetector } from '../platformDetector/platformDetector.component';
import { identifiers } from '../../utils/rendering';
import { getField, getImgProps, getImageCredit } from '../../utils/cmsData';
import MendedHeartSvg from '../../images/icon-heart.svg';

import * as Styles from './curtain.styles';

export class Curtain extends PureComponent {
  static propTypes = {
    contentEntry: PropTypes.object,
    isOnScreen: PropTypes.bool,
    rollUpCurtain: PropTypes.func.isRequired,
    closeCurtain: PropTypes.func,
    location: PropTypes.string,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { imageHeight: 0 };
  }

  componentWillReceiveProps(newProps) {
    // Remove curtain following any path change.
    if (this.props.location !== newProps.location) {
      this.props.closeCurtain();
    }
  }

  handleOnDownArrowClick = () => {
    dataLayer.push({
      event: 'Click',
      elementType: 'Button',
      element: `Curtain arrow (${this.props.contentEntry.getIn(['sys', 'id'])})`,
    });
    this.props.rollUpCurtain();
  };

  render() {
    const { contentEntry, intl } = this.props;

    const title = contentEntry.getIn(['fields', 'title']);
    const text = contentEntry.getIn(['fields', 'text']);
    const buttonUrl = contentEntry.getIn(['fields', 'buttonUrl', 'fields', 'url']);
    const buttonLabel = contentEntry.getIn(['fields', 'buttonLabel']);

    if (!contentEntry || contentEntry.isEmpty()) {
      return null;
    }

    const image = getImgProps(getField('image', contentEntry));
    const mobileImage = getField('mobileImage', contentEntry)
      ? getImgProps(getField('mobileImage', contentEntry))
      : null;
    const imageCredit = getImageCredit(contentEntry);

    return (
      <CurtainContainer
        {...identifiers(
          'Curtain',
          contentEntry.getIn(['sys', 'contentType', 'sys', 'id']),
          contentEntry.getIn(['sys', 'id']),
        )}
        isOnScreen={this.props.isOnScreen}
      >
        <MendedHeart>
          <img src={MendedHeartSvg} alt="" loading="lazy" />
        </MendedHeart>
        <CurtainImage imageHeight={this.state.imageHeight}>
          <PlatformDetector>
            {({ isMobile }) => (
              <Image
                {...(isMobile && mobileImage ? mobileImage : image)}
                loading="eager"
                fetchpriority="high"
                alt={image.alt || imageCredit || ''}
                onLoad={({ target: img }) => this.setState({ imageHeight: img.offsetHeight })}
                preload={true}
              />
            )}
          </PlatformDetector>
        </CurtainImage>
        {imageCredit && (
          <Styles.ImageCredit>
            <TextShadow blurRadius="12.5px">{imageCredit}</TextShadow>
          </Styles.ImageCredit>
        )}
        <CurtainTitle>
          <TextShadow>{`${title ? `**${title}**` : ''}`}</TextShadow>
        </CurtainTitle>
        {text && (
          <CurtainText>
            {text
              .split('\n')
              .filter(content => content)
              .map(content => (
                <TextShadow>{content}</TextShadow>
              ))}
          </CurtainText>
        )}
        {buttonUrl && buttonLabel && (
          <Button
            buttonType="red"
            onClick={() => {
              dataLayer.push({
                event: 'Click',
                elementType: 'Button',
                element: 'Curtain',
              });
            }}
            url={buttonUrl}
          >
            {buttonLabel}
          </Button>
        )}
        <Styles.CurtainArrow
          onClick={this.handleOnDownArrowClick}
          aria-label={intl.formatMessage(messages.buttonLabel)}
        >
          <BsChevronDown />
        </Styles.CurtainArrow>
      </CurtainContainer>
    );
  }
}
