import styled from 'styled-components';
import { media } from '../../../theme/media';

export const Wrapper = styled.div`
  width: 100%;
`;

const generateButtonAnimations = (buttonCount, isOnScreen) =>
  [...Array(buttonCount).keys()]
    .map(
      i => `
      :nth-child(${i + 1}) {
        transform: ${isOnScreen ? 'none' : 'translateY(10px)'};
        opacity: ${isOnScreen ? '1' : '0'};
        transition: transform 0.275s ${0.125 * i}s ease-out, opacity 0.725s ${0.125 * i}s ease-out;
      }
    `,
    )
    .join(' ');

export const ButtonContainer = styled.div`
  width: fit-content;
  margin: 0 0;

  > * {
    display: block;
    margin-bottom: 19px;

    ${({ buttonCount, isOnScreen }) => generateButtonAnimations(buttonCount, isOnScreen)}
  }

  a {
    font-weight: 700;
  }

  ${media.mobile`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 5px;
    margin: 0 auto;
    justify-content: center;
  `};
`;
