import React from 'react';
import PropTypes from 'prop-types';

import { ArrowLink } from '../arrowLink';
import { CollapsableLinkList } from '../../../components/collapsableLinkList/collapsableLinkList.component';
import { getField, getFieldIn } from '../../../utils/cmsData';

export const ArrowLinkItem = ({ item }) => {
  if (getField('type', item) === 'Link List') {
    return <CollapsableLinkList title={getField('title', item)} links={getField('items', item)} />;
  } else if (getField('type', item) === 'Link') {
    return (
      <ArrowLink
        key={getField('title', item)}
        label={getField('title', item)}
        url={getFieldIn(['url', 'url'], item)}
      />
    );
  }
  return false;
};

ArrowLinkItem.propTypes = {
  item: PropTypes.object,
};

