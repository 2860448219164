import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SectionContainer } from './section.styles';
import { identifiers } from '../../utils/rendering';

export class Section extends PureComponent {
  static propTypes = {
    contentType: PropTypes.string,
    entryId: PropTypes.string,
    componentName: PropTypes.string.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    maxWidth: PropTypes.number,
  };

  static defaultProps = {
    maxWidth: 1300,
  };

  render() {
    const { componentName, contentType, entryId, className, children, maxWidth } = this.props;

    return (
      <SectionContainer
        {...identifiers(`Section - ${componentName}`, contentType, entryId)}
        className={className}
        maxWidth={maxWidth}
      >
        {children}
      </SectionContainer>
    );
  }
}
