/**
 * Sagas for handling content page data fetching
 * @module "page.sagas"
 **/

import { put, takeLatest, select } from 'redux-saga/effects';
import { PageActions } from '../page.redux';
import { ContentPageTypes, ContentPageActions } from '../contentPage/contentPage.redux';
import { getPage, getPreviewPage } from '../../../services/contentful';
import { selectLocalesLanguage } from '../../locales/locales.selectors';
import { selectCurrentContentPageData } from './contentPage.selectors';
import { PageLoaderActions } from '.././../pageLoader/pageLoader.redux';

/**
 * @method
 * @summary It loads a page with provided url, and sets proper data to redux store
 * @param slug - page slug/url to load
 * @param isPreview {bool} - should load preview version of the page
 * @param _opts {object} - additional options for the request
 **/
function* fetchPageSaga({ slug, isPreview, opts: _opts = {} }) {
  try {
    yield put(PageLoaderActions.openPageLoader());
    yield put(ContentPageActions.setCurrentContentPage(slug));
    const pageData = yield select(selectCurrentContentPageData);

    if (pageData.size) {
      yield put(PageLoaderActions.closePageLoader());
      return;
    }

    const locale = yield select(selectLocalesLanguage);
    const url = `/${slug}`;
    const pageConfig = {
      'fields.url.sys.contentType.sys.id': 'url',
      'fields.url.fields.url': url,
    };
    const pagePreviewConfig = {
      'sys.id': slug,
    };
    const opts = {
      ..._opts,
      locale,
      include: 4,
      ...(isPreview ? pagePreviewConfig : pageConfig),
    };
    const { items } = isPreview ? yield getPreviewPage(opts) : yield getPage(opts);

    if (items.length === 0) {
      yield put(PageActions.fetchError());
    } else {
      yield put(ContentPageActions.fetchContentPageSuccess({
        ...items[0].fields,
        contentType: items[0].sys.contentType.sys.id,
        entryId: items[0].sys.id,
      }, slug));
    }
  } catch (e) {
    yield put(PageActions.fetchError(e));
  }
  yield put(PageLoaderActions.closePageLoader());
}

export default function* contentPageSaga() {
  yield takeLatest(ContentPageTypes.FETCH_CONTENT_PAGE, fetchPageSaga);
}

