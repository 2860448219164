import React from 'react';

const LazyPreForm = React.lazy(() => import('./preForm.container'));

const SuspendedPreForm = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyPreForm ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedPreForm;
