import { createSelector } from 'reselect';

const selectPageDomain = state => state.page.generic;

export const selectIsHeroPresent = createSelector(
  selectPageDomain,
  state => state.get('isHeroPresent'),
);

export const selectIsHeroWithTitlePresent = createSelector(
  selectPageDomain,
  state => state.get('isHeroWithTitlePresent'),
);

export const selectIsNotFound = createSelector(
  selectPageDomain,
  state => state.get('isNotFound'),
);
