import React from 'react';

const LazyText = React.lazy(
  () => import('./text.container'),
);

const SuspendedText = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyText ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedText;
