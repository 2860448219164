import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SectionMarker } from '../contentManager.styles';
import { SectionErrorBoundary } from '../../sectionErrorBoundary';
import { WindowListener } from '../../windowListener/windowListener.component';
import { isPrerenderBot } from '../../../utils/userAgent';

export const SectionViewportContext = React.createContext(false);

/**
 * @summary Wraps every section on the page.
 * Provides a marker to measure section positions on the screen and a context that
 * indicates when the section scroll position.
 *
 * Context {isActive} value is __true__ when the section is close to the viewport.
 * It should be used to lazy load any heavy content inside the section
 **/
export class PageSection extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInViewport: props.index <= 1,
    };
  }

  /**
   * References helper span element that marks start position of the section
   **/
  markerRef = React.createRef();

  /**
   * Checks current section position on the screen.
   * If it is close to entering the viewport, it marks the `isInViewport` property to true.
   **/
  handleScroll = () => {
    if (!this.markerRef.current) { return; }
    const { offsetTop: sectionStartPos } = this.markerRef.current;
    const scrollPos = window.scrollY || document.documentElement.scrollTop;

    const threshold = window.innerHeight * 2;

    const screenPosition = sectionStartPos - scrollPos;

    const isCloseAboveViewport = screenPosition < 0 && screenPosition > - threshold;
    const isCloseBelowViewport = screenPosition > 0 && screenPosition < threshold + window.innerHeight;

    if (isCloseAboveViewport || isCloseBelowViewport) {
      this.setState({ isInViewport: true });
    }
  };

  /**
   * It renders the children elements wrapped with SectionViewportContext provider.
   * Provider value matches `this.state.isInViewport`, so it means it marks sections already in viewport,
   * close to entering the viewport and sections that have already been in viewport as active.
   * The value can be used on other components to implement lazy loading.
   *
   * Note: window listener must come within the error boundary or else on error it will add handler with no markerRef.
   **/
  render() {
    const { children, index } = this.props;
    return (
      <SectionViewportContext.Provider value={{ isActive: this.state.isInViewport || isPrerenderBot }}>
        <SectionErrorBoundary>
          <SectionMarker data-page-section-start={index} ref={this.markerRef} />
          {children}
          { !this.state.isInViewport && (
            <WindowListener throttleValue={250} eventType="scroll" handledFunction={this.handleScroll} />
          )}
        </SectionErrorBoundary>
      </SectionViewportContext.Provider>
    );
  }
}
