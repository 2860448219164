// Not sure exactly how this is still bein used 9/22

import { createActions, createReducer } from 'reduxsauce';
import { Record, fromJS } from 'immutable';
import languages from '../../data/languages';

export const { Types: LanguagesTypes, Creators: LanguagesActions } = createActions({
  setLocation: ['country'],
}, { prefix: 'LANGUAGES_' });

const LanguagesRecord = new Record({
  data: fromJS(languages),
  country: null,
});

export const INITIAL_STATE = new LanguagesRecord({});

const handleSetLocation = (state, { country }) => state.set('country', country);

export const reducer = createReducer(INITIAL_STATE, {
  [LanguagesTypes.SET_LOCATION]: handleSetLocation,
});
