import React from 'react';
import PropTypes from 'prop-types';

import { CounterDigit } from '../counterDigit';

import * as Styles from './counter.styles';

export const Counter = React.memo(({ label, digitOne, digitTwo, labelAlign }) => {
  const parsedNumber = React.useMemo(
    () => Number.parseInt(`${digitOne}${digitTwo}`, 10),
    [digitOne, digitTwo],
  );

  const accessibleLabel = React.useMemo(() => {
    if (parsedNumber === 1 && label.length > 1 && label.endsWith('s')) {
      return label.substring(0, label.length - 1);
    }

    return label;
  }, [parsedNumber, label]);

  return (
    <Styles.Counter>
      <span className="visually-hidden">{`${parsedNumber} ${accessibleLabel}`}</span>

      <Styles.CounterLabel role="presentation" aria-hidden="true" labelAlign={labelAlign}>
        {label}
      </Styles.CounterLabel>

      <Styles.CounterDigitsContainer role="presentation" aria-hidden="true">
        <CounterDigit digit={digitOne} />
        <CounterDigit digit={digitTwo} />
      </Styles.CounterDigitsContainer>
    </Styles.Counter>
  );
});

Counter.propTypes = {
  label: PropTypes.string.isRequired,
  digitOne: PropTypes.string.isRequired,
  digitTwo: PropTypes.string.isRequired,
  labelAlign: PropTypes.string.isRequired,
};
