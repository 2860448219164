import pipe from 'ramda/es/pipe';
import propOr from 'ramda/es/propOr';
import { isIos } from '../utils/userAgent';
import { getRelevantLocaleData } from '../locales/config';

export const PlatformId = {
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINK: 'link',
  LINKEDIN: 'linkedin',
};

export const addUtmString = platform => url => {
  const { utmParams } = getRelevantLocaleData();
  const utmString = propOr('', platform, utmParams);
  const joinSymbol = url.indexOf('?') > -1 ? '&' : '?';
  return utmString ? [url, utmString].join(joinSymbol) : url;
};

const openShareDialog = providerUrl => url => {
  if (!url) {
    throw new Error('Missing url');
  }
  const encodedUrl = encodeURIComponent(url);
  const newWindow = window.open(`${providerUrl}${encodedUrl}`, '_blank');
  newWindow.opener = null;
};

export const shareOnFacebook = pipe(
  addUtmString(PlatformId.FACEBOOK),
  openShareDialog('https://www.facebook.com/sharer/sharer.php?u='),
);

export const shareOnTwitter = (url, text) => {
  const textStr = text ? `text=${encodeURIComponent(text)}&` : '';
  return pipe(
    addUtmString(PlatformId.TWITTER),
    openShareDialog(`https://twitter.com/intent/tweet?${textStr}url=`),
  )(url);
};

export const shareOnLinkedin = pipe(
  addUtmString(PlatformId.LINKEDIN),
  openShareDialog('https://www.linkedin.com/shareArticle?url='),
);

export const shareOnEmail = (_url, text, subject) => {
  const url = addUtmString(PlatformId.EMAIL)(_url);
  const bodyText = text ? `${text} ${url}` : url;
  window.open(`mailto:?subject=${subject}&body=${encodeURIComponent(bodyText)}`);
};

export const isClipboardSupported = () => {
  return (
    (window.clipboardData && window.clipboardData.setData) ||
    (document.queryCommandSupported && document.queryCommandSupported('copy'))
  );
};

const selectInputDefault = input => {
  input.select();
};

const selectInputIos = input => {
  input.style.position = 'absolute';
  input.style.top = '-99999px';
  input.readonly = 'true';

  const range = document.createRange();
  range.selectNodeContents(input);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  input.setSelectionRange(0, 999999);
};

export const copyToClipboard = (_url, _text) => {
  let url = addUtmString(PlatformId.LINK)(_url);
  if (_text) {
    url = `${_text}\n${url}`;
  }

  if (window.clipboardData && window.clipboardData.setData) {
    return clipboardData.setData('Text', url);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const input = document.createElement('textarea');
    input.textContent = url;
    input.style.position = 'fixed';
    document.body.appendChild(input);

    if (isIos) {
      selectInputIos(input);
    } else {
      selectInputDefault(input);
    }

    try {
      return document.execCommand('copy');
    } catch (ex) {
      return false;
    } finally {
      document.body.removeChild(input);
    }
  }
  return true;
};
