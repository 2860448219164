import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Bottom,
  Rotate,
  RotateOverlay,
  Left,
  Right,
  TopOverlay,
  BottomOverlay,
  Background,
  Content,
} from './boxShadow.styles';

/**
 * Component that renders a generic box element with cut bottom-right edge and with box shadow around
 **/
export class BoxShadow extends PureComponent {
  /**
   * PropTypes
   * @param children - Elements to render inside the box
   * @param className - className to apply to the container
   * @param backgroundColor - custom background color for the container box. Is white by default
   * @param withTopLine {bool} - **true** if the box should have top line decoration
   * @param noContentWrapper {bool} - **true** if content should be rendered at the same level
   * as the decoration elements. Otherwise content is applied inside additional `<div>` element
   * @param hideShadow {bool} - **true** if no shadow should be rendered around the box
   * @param hideCorner {bool} - **true** if the bottom-right corner should not be cut
   **/
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    backgroundColor: PropTypes.string,
    withTopLine: PropTypes.bool,
    noContentWrapper: PropTypes.bool,
    hideShadow: PropTypes.bool,
    hideCorner: PropTypes.bool,
  };

  render() {
    const { children, className, backgroundColor, withTopLine, hideShadow, noContentWrapper, hideCorner } = this.props;

    return (
      <Container className={className} withTopLine={withTopLine}>
        <Background hideShadow={hideShadow}>
          <TopOverlay backgroundColor={backgroundColor} />
          <BottomOverlay backgroundColor={backgroundColor} />
          <Right backgroundColor={backgroundColor} />
          <Left backgroundColor={backgroundColor} />
          <Bottom backgroundColor={backgroundColor} hideCorner={hideCorner} />
          <Rotate backgroundColor={backgroundColor} />
          <RotateOverlay backgroundColor={backgroundColor} />
        </Background>
        {noContentWrapper ? children : <Content>{children}</Content>}
      </Container>
    );
  }
}
