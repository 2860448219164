import React from 'react';

const LazyCardListTwoColumns = React.lazy(() => import('./cardListTwoColumns.container'));

const SuspendedCardListTwoColumns = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyCardListTwoColumns ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedCardListTwoColumns;
