import React from 'react';
import PropTypes from 'prop-types';

import * as Styles from './countdown.styles';
import { Counter } from './counter';

/**
 * Splits digits into an array of their representative string chars
 *
 * @param {number} digit (Will pad with `'0'` if `digit < 10`)
 * @returns {string[]}
 */
const splitDigits = digit => {
  if (digit < 10) {
    return ['0', `${digit}`];
  }

  if (digit > 99) {
    return ['9', '9'];
  }

  return `${digit}`.split('');
};

const CountdownComponent = ({ date, labelAlign }) => {
  const [currentDate, setCurrentDate] = React.useState(() => new Date());
  const [countdownValid, setCountdownValid] = React.useState(true);

  const dateDifferenceInSeconds = React.useMemo(() => {
    return Math.floor(Math.max(date - currentDate, 0) / 1000);
  }, [currentDate, date]);

  const [secondsDigitOne, secondsDigitTwo] = React.useMemo(() => {
    return splitDigits(dateDifferenceInSeconds % 60);
  }, [dateDifferenceInSeconds]);

  const [minutesDigitOne, minutesDigitTwo] = React.useMemo(() => {
    return splitDigits(Math.floor(dateDifferenceInSeconds / 60) % 60);
  }, [dateDifferenceInSeconds]);

  const [hoursDigitOne, hoursDigitTwo] = React.useMemo(() => {
    return splitDigits(Math.floor(dateDifferenceInSeconds / 3600) % 24);
  }, [dateDifferenceInSeconds]);

  const [daysDigitOne, daysDigitTwo] = React.useMemo(() => {
    return splitDigits(Math.floor(dateDifferenceInSeconds / 86400));
  }, [dateDifferenceInSeconds]);

  React.useEffect(() => {
    const timerUpdateID = setInterval(() => {
      setCurrentDate(prevCurrentDate => {
        const newCurrentDate = new Date();

        if (newCurrentDate > date) {
          setCountdownValid(false);
        }

        if (prevCurrentDate.getSeconds() !== newCurrentDate.getSeconds()) {
          return newCurrentDate;
        }

        return prevCurrentDate;
      });
    }, 250);

    return () => clearInterval(timerUpdateID);
  }, []);

  return (
    <div>
      {countdownValid &&
      <Styles.CountdownDate role="timer" aria-atomic="true">
        <Counter
          label={'days'}
          labelAlign={labelAlign}
          digitOne={daysDigitOne}
          digitTwo={daysDigitTwo}
        />
        <Counter
          label={'hours'}
          labelAlign={labelAlign}
          digitOne={hoursDigitOne}
          digitTwo={hoursDigitTwo}
        />
        <Counter
          label={'minutes'}
          labelAlign={labelAlign}
          digitOne={minutesDigitOne}
          digitTwo={minutesDigitTwo}
        />
        <Counter
          label={'seconds'}
          labelAlign={labelAlign}
          digitOne={secondsDigitOne}
          digitTwo={secondsDigitTwo}
        />
      </Styles.CountdownDate>
      }
    </div>
  );
};

CountdownComponent.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  labelAlign: PropTypes.string,
};

CountdownComponent.defaultProps = {
  labelAlign: 'left',
};

export const Countdown = CountdownComponent;
