/**
 * Decorator providing utility functions for contentful files.
 *
 * @module FileDecorator
 **/

import Decorator from './decorator';

export default class FileDecorator extends Decorator {
  /**
   * Returns the url of the file.
   *
   * @public
   * @return {string} The file url.
   */
  get url() {
    return this.data.get('url');
  }

  imageDetails() {
    return this.data.get('details').get('image').toJS();
  }
}
